import React, { useEffect, useContext, useState } from 'react';
import { Table, Card, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import get from 'lodash.get';
import clone from 'lodash.clone';
import Pagination, {
  PaginationContent,
  usePagination,
} from '@availity/pagination';
import  { FaLock, FaPhone, FaCircle, FaUserFriends } from 'react-icons/fa'
import dayjs from "dayjs";
import API from '../../api';
import StoreContext from '../../../stores';
import CursorControls from './CursorControls';
import TableControls from './TableControls';
import {TransferControls, LeadControls} from './Controls';
import {TransferSummary, LeadSummary} from './Summary';

const getIcon = (icon,color) => {
  switch(icon) {
    case 'phone':
      return <FaPhone color={color} />
    case 'lock':
      return <FaLock color={color} />
    case 'people':
      return <FaUserFriends color={color}/>
    default:
      return <FaCircle color={color} />
  }

}
const getColor = type => {
  switch(type) {
    case 'reject':
      return 'red';
    case 'block':
      return 'yellow';
    case 'pass':
      return 'green';
    default:
      return 'green';
  }
}
const TableRow = ({ columns, sortKey, descending, ...rest }) => (
  <tr>
    <td />
    {columns.map((column, key) => {

      if(column.dataField === 'result') {
        const { icon = '', result = ''} = rest[column.dataField] || {};
        
        return (
          <td key={`${key}-${column.dataField}`}>
            {getIcon(icon,getColor(result))}
          </td>
        );
      }
      return (<td key={`${key}-${column.dataField}`}>
        {column.formatter !== undefined
          ? column.formatter(rest[column.dataField])
          : rest[column.dataField]}
      </td>);
    })}
  </tr>
);

const NoItemsMessage = props => {
  const { page } = usePagination();

  return !page || (!page.length && <span {...props} />);
};

TableRow.propTypes = {
  columns: PropTypes.array,
  sortKey: PropTypes.string,
  descending: PropTypes.bool
};

const SonicTable = observer(
  ({ columns, rowKey, endpoint, uniqueKeyProperty, totals, mobxTableName, fields }) => {
    const {
      agentStore,
      agentStore: {
        agents = []
      },
      campaignStore,
      campaignStore: {
        list: campaigns = []
      },
      tableStore: {
        table: { loading, scrollID, total, pageData, search, aggregations, searchPayload },
        onSearch,
        clearSearch,
        updateTableItems,
        updateTableLoading,
      },
    } = useContext(StoreContext);
    const [ showExportModal, setShowExportModal ] = useState(false)



    const exportItems = async () => {
      console.log(searchPayload)
      setShowExportModal(true)
      const filePath = `${mobxTableName}/exports/${dayjs().format()}/searchPayload.json`

      Storage.put(filePath, JSON.stringify(searchPayload), {
        level: 'private',
        contentType: 'application/json'
    })
    .then (result => console.log(result))
    .catch(error => console.log(error));
    }

    const fetchItems = async currentPage => {
      const { startDate, endDate, searchText, sortKey, descending = true, size, ...rest } = search;
      updateTableLoading({loading:true})
      if (pageData.length <= currentPage * size) {

        const body = {
          startDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          endDate: endDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          searchText,
          ...rest,
          size,
          count: 0,
          filters: [],
          scrollID,
          sortOrder: descending ? 'desc' : 'asc',
          sortKey,
          totals,
          fields: columns.map(f => {return f.dataField})
        };

        const response = await API.post(process.env.REACT_APP_API, endpoint, {
          body,
        });

        console.log(response)

        let currentPageData = clone(pageData);

        const currentPageKeys = new Set(
          pageData.map(p => p[uniqueKeyProperty])
        );
        const responseArr = get(response, 'data.data.hits.hits', []).map(
          item => item._source
        );

   

        currentPageData = currentPageData.concat(
          responseArr.filter(r => !currentPageKeys.has(r[uniqueKeyProperty]))
        )

    

        updateTableItems({
          total: get(response, 'data.data.hits.total.value'),
          pageData: currentPageData,
          scrollID: get(response, 'data.data._scroll_id'),
          aggregations: get(response, 'data.data.aggregations'),
          searchPayload: get(response, 'data.requestPayload'),
        });
      }
    };

    useEffect(() => {
      clearSearch()
    }, []);

    useEffect(() => {
      fetchItems(0);
      if (agentStore.loading) {
        agentStore.init();
      }
      if(campaignStore.ready){
        campaignStore.load()
      }
    }, [search]);

    const getControls = () => {
      if(endpoint === '/transfers'){

        return <TransferControls
        {...search}
        fetchItems={fetchItems}
        onSearch={onSearch}
        leftSelectOptions={agents.map(item => {
            const { agentID, firstName, lastName } = item
            return {value: agentID, label: `${firstName} ${lastName}`}
        })}
      />
      }else{

      return <LeadControls
      {...search}
      fetchItems={fetchItems}
      onSearch={onSearch}
      leftSelectOptions={campaigns.map(item => {
          const { campaignName, campaignID } = item
          return {value: campaignID, label: campaignName}
      })}
    />
      }
    }

    const getSummary = () => {
      if(endpoint === '/transfers'){

        return <TransferSummary
        loading={loading}
        total={total}
        totals={totals}
        aggregations={aggregations}
      />
      }else{
        return <LeadSummary
        loading={loading}
        total={total}
        totals={totals}
        aggregations={aggregations}
      />
      }
    }

    

    return (
      <Card body>
        <Pagination
          items={pageData}
          onPageChange={fetchItems}
          itemsPerPage={search.size}
        >
        {getControls()}
        {getSummary()}

          <Table striped size="sm" hover>
            <thead>
              <tr>
                <th />
                {columns.filter(c => {
                  return c.visible
                }).map(column => (
                  <th key={column.displayName}>{column.displayName}</th>
                ))}
              </tr>
            </thead>
            <PaginationContent
              itemKey={rowKey}
              component={TableRow}
              containerTag="tbody"
              columns={columns.filter(c => {
                return c.visible
              })}
            />
          </Table>
          <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2">
            There are no items left to view
          </NoItemsMessage>
          <CursorControls
          totalItems={total}
          itemsPerPage={search.size}
          className="d-flex justify-content-center flex-fill border-top pt-3"
        />

          <TableControls
          size={search.size}
          onSearch={onSearch}
          exportItems={exportItems}
          />

        </Pagination>
       
        <Modal isOpen={showExportModal} toggle={() => setShowExportModal(!showExportModal)}>
            <ModalHeader toggle={() => setShowExportModal(!showExportModal)}>Download Processing!</ModalHeader>
              <ModalBody>
              Your download is processing. You have probably already received the email with the link by the time you finish reading this.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowExportModal(!showExportModal)}>Ok</Button>
        </ModalFooter>
      </Modal>
     
      </Card>
    );
  }
);

SonicTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      displayName: PropTypes.string,
    })
  ),
  endpoint: PropTypes.string,
  uniqueKeyProperty: PropTypes.string.isRequired,
  sortKey: PropTypes.string,
  descending: PropTypes.bool,
};


export default SonicTable;
