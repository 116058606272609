import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

import { formatCurrencyForDisplay } from '../../areas/MyAgents/Utils';

const PriceBadge = ({
  price,
  ...otherProps,
}) => {
  const isPositive = price >= 0;
  const style = Object.assign({
    fontSize: '13px',
    paddingTop: '5px',
  }, otherProps.style || {});

  delete otherProps.style;

  return (
    <Badge
      color={isPositive ? 'success' : 'danger'}
      pill
      style={style}
      {...otherProps}
    >
      {isPositive ? '+' : '-'}{formatCurrencyForDisplay(price)}
    </Badge>
  );
};

PriceBadge.propTypes = {
  price: PropTypes.number.isRequired,
};

export default PriceBadge;