import { observer } from 'mobx-react-lite';
import * as Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Progress from 'react-progressbar';
import {
    Button,
    ButtonGroup,
} from 'reactstrap';

import ButtonIcon from '../../../shared/components/ButtonIcon';
import Toggle from '../../../shared/components/Toggle';

import {
    getSpendingPeriodsString,
    sumSpendingPeriods,
} from '../Utils';
import { formatCurrencyForDisplay } from '../../MyAgents/Utils';
import { localizeSpendingPeriod } from '../../../stores/campaignStore';

export const getProgressColor = progress => {
    if (progress === 100) {
        return '#004085';
    } else if (progress > 60) {
        return '#ffc107';
    }
    return '#28a745';
};

export const getProgress = (initialPeriod, now) => {
    const period = localizeSpendingPeriod(initialPeriod, -Moment().utcOffset());
    const startOfToday = Moment.utc().startOf('day');
    const startMoment = startOfToday.clone().add(period.startTime);
    const endMoment = startOfToday.clone().add(period.endTime);
    const twoDays = period.endTime <= period.startTime;
    let scheduledDOW;

    // The spending period cuts across two days
    if (twoDays) {
        // It's currently the second of the two days
        if (now.isBefore(endMoment, 'minutes')) {
            startMoment.subtract(1, 'day');

            // The start time steps back to the last day of the week
            if (startOfToday.day() === 0) {
                scheduledDOW = 6;
            // The start time steps back to the previous day of the week
            } else {
                scheduledDOW = startOfToday.day() - 1;
            }
        // It's currently the first of the two days
        } else {
            endMoment.add(1, 'day');
            scheduledDOW = startOfToday.day();
        }
    // The spending period is constrained within a single day
    } else {
        scheduledDOW = startOfToday.day();
    }
    const lastPurchaseMoment = Moment(period.lastPurchaseEpoch);

    const isCurrentDay = [
        period.dowSun,
        period.dowMon,
        period.dowTue,
        period.dowWed,
        period.dowThu,
        period.dowFri,
        period.dowSat
    ][scheduledDOW] === true;

    if (
        now.isSameOrAfter(startMoment, 'minutes') &&
        now.isBefore(endMoment.endOf('day'), 'minutes') &&
        isCurrentDay &&
        lastPurchaseMoment.isSameOrAfter(startMoment, 'minutes') &&
        lastPurchaseMoment.isBefore(endMoment, 'minutes')
    ) {
        return [period.lastPurchasePeriodSpend, period.dailySpendLimit];
    }
    return [0, isCurrentDay ? period.dailySpendLimit : 0];
};

const CampaignTableRow = observer(({
    id,
    name,
    navigateToEditCampaignPage,
    openModal,
    paused,
    spendingPeriods,
    togglePaused,
    togglePending,
}) => {
    const hasSpendingPeriods = spendingPeriods.length > 0;
    const currentMoment = Moment();

    let currentDailySpend = 0;
    let maxDailySpend = 0;

    const progress = spendingPeriods.map(
        period => getProgress(period, currentMoment),
    );

    progress.forEach(
        ([dailySpend, maxSpend]) => {
            currentDailySpend += dailySpend;
            maxDailySpend += maxSpend;
        },
    );

    return (
        <tr>
            <td>
                {name}
            </td>
            <td className="text-center">
                <Toggle
                    active={!paused}
                    loading={togglePending}
                    onToggle={togglePaused}
                />
            </td>
            <td className="text-left">
                {!paused && progress.map(
                    ([dailySpend, maxSpend], idx) => {
                        const completed = dailySpend / maxSpend * 100;

                        return (
                            <div
                                key={idx}
                                style={{
                                    backgroundColor: 'lightgray',
                                    marginBottom: '5px',
                                }}
                                title={`${formatCurrencyForDisplay(dailySpend)} / ${formatCurrencyForDisplay(maxSpend)}`}
                            >
                                <Progress
                                    color={getProgressColor(completed)}
                                    completed={completed}
                                />
                            </div>
                        );
                    }
                )}
                {hasSpendingPeriods ?
                    <> 
                        {getSpendingPeriodsString(spendingPeriods)}{currentDailySpend ? (
                            ` (${formatCurrencyForDisplay(maxDailySpend - currentDailySpend)} remaining today)`
                        ) :
                        ''}
                    </> :
                    'Can\'t receive leads.'
                }
            </td>
            <td>
                <Button
                    color={hasSpendingPeriods ? 'secondary' : 'success'}
                    onClick={() => openModal(`edit_spending_period:${id}`)}
                    style={{ marginRight: '15px' }}
                >
                    {hasSpendingPeriods ? 'Edit spending periods' : 'Add spending periods'}
                </Button>
            </td>
            <td>{formatCurrencyForDisplay(sumSpendingPeriods(spendingPeriods))}/wk</td>
            <td>
                <ButtonGroup>
                    <ButtonIcon
                        iconClassName="far fa-edit"
                        onClick={navigateToEditCampaignPage}
                        title="Edit campaign"
                    />
                   {/* <ButtonIcon
                        color="danger"
                        disabled={!paused}
                        iconClassName="far fa-trash-alt"
                        onClick={() => openModal(`delete_campaign:${id}`)}
                        title={paused ? 'Delete campaign' : 'You must first pause the campaign before it can be deleted'}
                   />*/}
                </ButtonGroup>
            </td>
        </tr>
    );
});

CampaignTableRow.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    navigateToEditCampaignPage: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    paused: PropTypes.bool.isRequired,
    spendingPeriods: PropTypes.arrayOf(PropTypes.shape({
        dowSun: PropTypes.bool,
        dowMon: PropTypes.bool,
        dowTue: PropTypes.bool,
        dowWed: PropTypes.bool,
        dowThu: PropTypes.bool,
        dowFri: PropTypes.bool,
        dowSat: PropTypes.bool,
        endTime: PropTypes.number,
        id: PropTypes.string,
        startTime: PropTypes.number,
    })).isRequired,
    togglePaused: PropTypes.func.isRequired,
    togglePending: PropTypes.bool.isRequired,
};

export default CampaignTableRow;