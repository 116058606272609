import PropTypes from 'prop-types';
import React from 'react';
import {
  ButtonGroup,
} from 'reactstrap';

import ButtonIcon from '../../../../shared/components/ButtonIcon';
import PriceBadge from '../../../../shared/components/PriceBadge';

const VendorCampaignRow = ({
  layerID,
  vendorID,
  ppl,
  setOpenSubmenu,
  vendorCampaignName,
  vendorName,
}) => (
  <tr>
    <td>{vendorName}</td>
    <td>{vendorCampaignName}</td>
   {/*<td><a href="/support">View Instructions</a></td>*/} {/* TODO: Add instructions */}
    <td><PriceBadge price={ppl} /></td>{/* TODO: Add real price */}
    <td>
      <ButtonGroup>
      {/*  <ButtonIcon
          href="/support"
          iconClassName="far fa-question-circle"
      />*/}
        <ButtonIcon
          iconClassName="far fa-edit"
          onClick={() => setOpenSubmenu(`vendorCampaignEdit:${layerID}`)}
        />
        <ButtonIcon
          iconClassName="fas fa-share-alt"
          onClick={() => setOpenSubmenu(`vcEmail:${layerID}`)}
        />
      </ButtonGroup>
    </td>
  </tr>
);

VendorCampaignRow.defaultProps = {
  vendorCampaignName: '',
};

VendorCampaignRow.propTypes = {
  layerID: PropTypes.string.isRequired,
  vendorID: PropTypes.string.isRequired,
  ppl: PropTypes.number.isRequired,
  vendorCampaignName: PropTypes.string,
  vendorName: PropTypes.string.isRequired,
  setOpenSubmenu: PropTypes.func.isRequired,
};

export default VendorCampaignRow;