import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  Modal,
  Row
} from 'reactstrap';
import classNames from 'classnames';
import Pagination, {
  PaginationContent,
  PaginationControls,
} from '@availity/pagination';
import deepEquals from 'deep-equal';
import { AvForm } from 'availity-reactstrap-validation';
import AvSelect from '@availity/reactstrap-validation-select';
import { observer } from 'mobx-react-lite';
import matchSorter from 'match-sorter';
import PropTypes from 'prop-types';
import {
  FaSortAmountDown,
  FaSortAmountUp,
  FaSync,
  FaCheckCircle,
} from 'react-icons/fa';
import orderBy from 'lodash.orderby';
import { formatCurrencyForDisplay } from './Utils';

import { Page404 } from '..';
import AgentTable from './Table/AgentTable';
import AgentTableRow from './Table/AgentTableRow';
import ConfirmModal from './Modals/Confirm';
// import ScheduleModal from './Modals/Schedule';
import {
  CenterComponent,
  LoadingComponent,
} from '../../shared/helpers/helpers';

import StoreContext from '../../stores';
import { date } from 'yup';
import { set } from 'mobx';

const sortOptions = [
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Total Credit',
    value: 'totalCredit',
  },
  {
    label: 'Contributed Credit',
    value: 'contributedCredit',
  },
];

const MyAgentsPage = observer(({
  assumeRole,
  isLoadingUserCredit,
  isLoadingAgents,
  loggedInUserCredit,
  creditSyncing,
  navigate,
  userRole,
  agentStore,
  agentStore: {
    agents = [],
    init,
    initialAgents,
    isTransferringFunds,
    reset: resetAgentChanges,
    transferFunds,
    setSearchTerm,
    searchTerm,
    setFilterStatus,
    filterStatus,
    setStatusTransfer,
    statusTransfer
  },
}) => {


  window.agents = agents;
  const [modalType, openModal] = useState(null);
  const [search, setSearch] = useState(searchTerm);
  const [statusFilter, setStatusFilter] = useState(filterStatus);
  const [transferStatus, setTransferStatus] = useState(statusTransfer);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [descending, setDescending] = useState(true);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [time,setTime] = useState(new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))
  const [showSpinner,setShowSpinner] = useState(false)

  

  useEffect(() => {
    setDebouncedSearch(searchTerm);
  },[]);

  useEffect(() => {
    setFilterStatus(statusFilter);
  },[statusFilter]);

  useEffect(() => {
    setStatusTransfer(statusFilter);
  },[transferStatus])
  // Don't apply the search immediately. Rendering 400 items. Big oof
  useEffect(() => {
    const timeoutFn = setTimeout(() => {
      if (debouncedSearch !== search) {
        setSearchTerm(debouncedSearch)
        setSearch(debouncedSearch);
      }
    }, 1000);
    return () => clearTimeout(timeoutFn);
  }, [debouncedSearch]);

  const resetChanges = () => resetAgentChanges();

  const setModal = nextModalType => {
    if (nextModalType !== modalType) {
      openModal(nextModalType);
    }
  };

  const spinnerColor = () => {
    
    switch(statusFilter) {
      case null:
        return 'primary';
      case 'false':
        return 'warning';
      case 'true':
        return 'success'
      default:
        return 'primary'

    }
  }
  // const setScheduleProp = (propName, nextPropValue) => {
  //   if (!agents[selectedAgentIndex].schedule) {
  //     agents[selectedAgentIndex].schedule = {
  //       mode: 'none',
  //     };
  //   }

  //   if (agents[selectedAgentIndex].schedule[propName] !== nextPropValue) {
  //     agents[selectedAgentIndex].schedule[propName] = nextPropValue;

  //     schedule(agents);
  //   }
  // };

  
  
  const save = () => transferFunds();

  const withdrawAll = () => {
    agents
      .filter(a => a.contributedCredit + (agentStore.agentTransfers[a.agentID] || 0) > 0)
      .forEach(a => {
        agentStore.updateTransfer(a.agentID, -a.contributedCredit);
      });
  };

  const setAmount = (nextAmount, id) => {
    if (nextAmount !== agentStore.agentTransfers[id]) {
      agentStore.updateTransfer(id, nextAmount);
    }
  };

  const getItems = () =>
    // console.log('re-rendering');
    orderBy(
      matchSorter(agents, search, {
        keys: [
          item => `${item.firstName} ${item.lastName}`,
          item => `${item.email}`
          // 'phone',
          // 'customerId'
        ],
      }),
      sort,
      descending ? 'desc' : 'asc'
    ).filter(agent => {
      if (
        (statusFilter === 'true' &&
          ![true, 'true', undefined].includes(agent.agentApproved)) ||
        (statusFilter === 'false' &&
          [true, 'true', undefined].includes(agent.agentApproved))
      ) {
        return false;
      }

      if (transferStatus) {
        if (transferStatus === 'contributed') {
          return agent.contributedCredit > 0;
        }
        if (transferStatus === 'pending') {
          const amount = agentStore.agentTransfers[agent.agentID];
          return amount !== undefined && amount !== 0;
        }
      }
      return true;
    });

  const isLoading =
    isLoadingAgents || isLoadingUserCredit || isTransferringFunds;

  if (isLoading) {
    return <LoadingComponent />;
  }

  const disableReset =
    agentStore.totalTransferAmount === 0 && deepEquals(agents, initialAgents);
  const finalUserCreditIsNegative = agentStore.totalTransferAmount > loggedInUserCredit;
  const invalidAgentCount = agents.filter(
    a => a.contributedCredit + (agentStore.agentTransfers[a.agentID] || 0) < 0
  ).length;

  const agentsWithFinalNegativeBalances = agents.filter(agent => {
      const { agentID, totalCredit } = agent
     return (agentStore.agentTransfers[agentID] || 0) + totalCredit < 0
  })

  const refreshTransfers = async () => {
        setShowSpinner(true);
        await init();
        setTime(new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }));
        setShowSpinner(false);
  }

  const refreshText = showSpinner ? 
        <div style={{marginLeft:'90px'}}>
          <FaSync className={`ml-1 text-${spinnerColor()} loading-sync`} title="Searching Transfers..." size={20} />
        </div> :`Last refreshed ${time}`;

  const refreshButton = () => {

    return (
      <div style={{width:'250px'}}>
        <span style={{width:'180px', display:'inline-block'}}>{refreshText}</span >
   
        <span style={{float:'right'}}>
            <Button 
              size="md"
              onClick={() => refreshTransfers()}
            >
              <i className="fas fa-sync-alt"/>
            </Button>
        </span>
      </div>
    );
  }
  const disableSave =
    disableReset || finalUserCreditIsNegative || invalidAgentCount > 0 || agentsWithFinalNegativeBalances.length > 0;

  // const selectedAgent = agents[selectedAgentIndex];
  let saveText;

  if (finalUserCreditIsNegative) {
    saveText = 'Cannot save until your remaining credit is positive.';
  } else if (invalidAgentCount > 0) {
    saveText = `Cannot save until ${invalidAgentCount} agent configuration${
      invalidAgentCount > 1 ? 's are' : ' is'
    } fixed below.`;
  } else if (!disableReset) {
    saveText = 'Not yet saved.';
  } else {
    saveText = '';
  }
  return (
    <div className="mt-3">
      <ConfirmModal
        closeModal={() => setModal(null)}
        confirm={save}
        isOpen={modalType === 'confirm_transfer_funds'}
        text="Are you sure you wish to transfer all indicated funds and save all schedule and auto-pay settings?"
      />
      {/* <ScheduleModal
        closeModal={() => setModal(null)}
        firstName={selectedAgent.firstName}
        fundAtThreshold={(selectedAgent.schedule || {}).fundAtThreshold || 0}
        fundWithAmount={(selectedAgent.schedule || {}).fundWithAmount || 0}
        increment={(selectedAgent.schedule || {}).increment || 1}
        isOpen={modalType === 'schedule'}
        lastName={selectedAgent.lastName}
        lastTransfer={(selectedAgent.schedule || {}).lastTransfer || undefined}
        mode={(selectedAgent.schedule || {}).mode || 'none'}
        setScheduleProp={setScheduleProp}
        startTime={
          (selectedAgent.schedule || {}).startTime || new Date().toISOString()
        }
        unit={(selectedAgent.schedule || {}).unit || 'hour'}
      /> */}
      <h3 className="ml-3">My Agents</h3>
      <div className="d-flex">
        <div
          className={classNames('flex-fill d-flex flex-column', {
            'border-primary': statusFilter === null,
            'border-warning': statusFilter === 'false',
            'border-success': statusFilter === 'true',
          })}
          style={{ marginRight: 270 }}
        >
          <Card className="mb-3 ml-0 py-0">
            <Pagination
              items={getItems()}
              itemsPerPage={50}
              resetParams={[agents, search, statusFilter, transferStatus]}
              watchList={[descending, sort]}
            >
              <div
                className={classNames('d-flex justify-content-center py-3', {
                  'bg-primary border-primary': statusFilter === null,
                  'bg-warning border-warning': statusFilter === 'false',
                  'bg-success border-success': statusFilter === 'true',
                })}
                style={{
                  borderLeft: '4px solid #f8f8f8',
                }}
              >
                <ButtonGroup className="w-50 mx-auto">
                  <Button
                    onClick={() => setStatusFilter(null)}
                    active={statusFilter === null}
                  >
                    All
                  </Button>
                  <Button
                    onClick={() => setStatusFilter('false')}
                    active={statusFilter === 'false'}
                  >
                    Pending
                  </Button>
                  <Button
                    onClick={() => setStatusFilter('true')}
                    active={statusFilter === 'true'}
                  >
                    Approved
                  </Button>
                </ButtonGroup>
              </div>
              <AvForm
                className={classNames({
                  'border-primary': statusFilter === null,
                  'border-warning': statusFilter === 'false',
                  'border-success': statusFilter === 'true',
                })}
                style={{
                  borderLeft: '4px solid #f8f8f8',
                }}
              >
                <div className="bg-light py-3 px-4 d-flex align-items-center justify-content-between">
                  <div className="d-flex alignt-items-center">
                    <span className="mr-2 font-weight-bold text-white">
                      Credit Transfer Status
                    </span>
                    <ButtonGroup size="sm">
                      <Button
                        onClick={() => setTransferStatus(null)}
                        active={transferStatus === null}
                        color="primary"
                        outline
                      >
                        All
                      </Button>
                      <Button
                        onClick={() => setTransferStatus('pending')}
                        active={transferStatus === 'pending'}
                        color="primary"
                        outline
                      >
                        Pending
                      </Button>
                      <Button
                        onClick={() => setTransferStatus('contributed')}
                        active={transferStatus === 'contributed'}
                        color="primary"
                        outline
                      >
                        Contributed
                      </Button>
                    </ButtonGroup>
                  </div>{' '}
                  <InputGroup className="d-flex justify-content-end w-auto">
                    <AvSelect
                      styles={{
                        menuList: provided => ({
                          ...provided,
                          color: '#232323',
                        }),
                      }}
                      name="sort"
                      id="sort-select"
                      options={sortOptions}
                      onChange={option => setSort(option)}
                      value={sort}
                      inputProps={{
                        width: 200,
                      }}
                    />
                    <InputGroupAddon
                      style={{ cursor: 'pointer' }}
                      onClick={() => setDescending(!descending)}
                      addonType="append"
                    >
                      <InputGroupText>
                        {descending ? <FaSortAmountDown /> : <FaSortAmountUp />}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {refreshButton()}
                </div>
                <CardBody className="d-flex py-2">
                  <Input
                    type="text"
                    name="searchText"
                    placeholder="Search..."
                    className="mr-2"
                    value={debouncedSearch}
                    onChange={({ target }) => setDebouncedSearch(target.value)}
                  />
                  <PaginationControls marginPages={0} breakLabel={false} />
                </CardBody>
              </AvForm>
              <AgentTable
                withdrawAll={withdrawAll}
                className={classNames({
                  'border-primary': statusFilter === null,
                  'border-warning': statusFilter === 'false',
                  'border-success': statusFilter === 'true',
                })}
                style={{
                  borderLeft: '4px solid #f8f8f8',
                }}
              >
                <PaginationContent
                  containerTag="tbody"
                  component={AgentTableRow}
                  itemKey="agentID"
                  userRole={userRole}
                  assumeRole={assumeRole}
                  navigate={navigate}
                  navigateToViewProfile={agentID =>
                    navigate(`/profile/${agentID}`)
                  }
                  agentTransfers={agentStore.agentTransfers}
                  setAmount={setAmount}
                />
              </AgentTable>
            </Pagination>
          </Card>
        </div>
        <Card
          body
          className="d-flex flex-column"
          style={{
            width: 250,
            position: 'fixed',
            right: 24,
            height: 'fit-content',
          }}
        >
          <div>
            <span className="d-flex align-items-center">
              Your Credit{' '}
              {creditSyncing ? (
                <FaSync
                  className="ml-1 text-warning loading-sync"
                  title="Syncing your credit."
                />
              ) : (
                <FaCheckCircle className="ml-1 text-success" />
              )}
            </span>
            <br />
            <h5>{formatCurrencyForDisplay(loggedInUserCredit)}</h5>
          </div>
          <div>
            Credit to Transfer
            <br />
            <h5>{formatCurrencyForDisplay(-agentStore.totalTransferAmount)}</h5>
          </div>
          <div>
            Remaining Credit
            <br />
            <h5
              className={
                loggedInUserCredit - agentStore.totalTransferAmount < 0
                  ? 'text-danger'
                  : ''
              }
            >
              {formatCurrencyForDisplay(
                loggedInUserCredit - agentStore.totalTransferAmount
              )}
            </h5>
          </div>
          <div>
            <h5 className="text-danger">{saveText}</h5>
          </div>
          <div>
            <ButtonGroup>
              <Button disabled={disableReset} onClick={resetChanges}>
                Reset
              </Button>
              <Button
                color="primary"
                disabled={disableSave}
                onClick={() => setModal('confirm_transfer_funds')}
              >
                Save
              </Button>
            </ButtonGroup>
          </div>
        </Card>
      </div>
    </div>
  );
});

MyAgentsPage.propTypes = {
  assumeRole: PropTypes.func.isRequired,
  isLoadingUserCredit: PropTypes.bool.isRequired,
  isLoadingAgents: PropTypes.bool.isRequired,
  loggedInUserCredit: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired,
  userRole: PropTypes.oneOf(['admin', 'manager', 'super_admin', 'user'])
    .isRequired,
  agentStore: PropTypes.object,
  creditSyncing: PropTypes.bool.isRequired,
};

export default observer(props => {
  const {
    creditStore: {
      credit: loggedInUserCredit,
      loading: isLoadingUserCredit,
      syncing: creditSyncing,
      syncCredit,
    },
    agentStore,
    userStore,
  } = useContext(StoreContext);

  useEffect(() => {
    syncCredit();
    if (agentStore.loading) {
      agentStore.init();
    }
  }, []);

  return (
    <MyAgentsPage
      assumeRole={userStore.assumeOtherRole}
      isLoadingUserCredit={isLoadingUserCredit}
      creditSyncing={creditSyncing}
      agentStore={agentStore}
      isLoadingAgents={agentStore.loading}
      loggedInUserCredit={loggedInUserCredit}
      userRole={userStore.role}
      {...props}
    />
  );
});
