/* eslint-disable no-undef */
import {
  // AvCheckbox,
  // AvCheckboxGroup,
  AvForm,
} from 'availity-reactstrap-validation';
import Button from '@gopro16/button';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FaSync } from 'react-icons/fa';
import { Link } from '@reach/router';
import {
  Card,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Button as RsButton,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledPopover,
} from 'reactstrap';
import Select from '@availity/reactstrap-validation-select';
import Switch from 'react-switch';

import { Page404 } from '..';
import API from '../../shared/api';
import LocalTable from '../../shared/components/LocalTable';
import {
  CenterComponent,
  LoadingComponent,
} from '../../shared/helpers/helpers';
import StoreContext from '../../stores';
import CRMProfiles from './CRMProfiles';
import Agents from './Agents';

// const REGIONS = ["AL","AZ","AR","CO","FL","GA","IL","IN","IA","KS","KY","LA","MD","MI","MS","MO","MT","NE","NV","NC","OH","OK","PA","SC","TN","TX","UT","VA","WV","WY"];

const DisabledPopOver = ({ id, className }) => (
  <span>
    <UncontrolledPopover target={`${id}-Question`} trigger="hover">
      This feature is disabled. Pending future update.
    </UncontrolledPopover>
    <i className={`fa fa-question-circle ${className}`} id={`${id}-Question`} />
  </span>
);

const Profile = ({ agentID }) => {
  const {
    notificationStore,
    userStore: {
      loadUser,
      marketplace,
      updateUser,
      syncAgent,
      user,
      user: { network, flags = {} },
      networkAgents,
    },
  } = useContext(StoreContext);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted orange',
      backgroundColor: 'black',
      color: 'white',
      padding: 5,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 200,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const profileID = agentID === 'me' ? user.agentID : agentID;
  const { allowSelfEdit } = flags;

  const isSameUser = profileID === user.agentID;
  const canEdit = !isSameUser && ['manager', 'super_admin'].includes(user.role);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [regionDropdownOpen, setRegionDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [originalManagers, setOriginalManagers] = useState([]);

  const [selectedNetworkAgents, setSelectedNetworkAgents] = useState([]);
  const [myNetworkAgents, setMyNetworkAgents] = useState([]);
  const [myNetworkAgentsModal, setMyNetworkAgentsModal] = useState(false);
  const [myAgentSearch, setMyAgentSearch] = useState('');

  const [profile, setProfile] = useState(null);
  const [crmProfiles, setCRMProfiles] = useState();
  const [managersLoading, setManagersLoading] = useState(true);

  const toggleMyNetworkAgents = () => {
    setMyNetworkAgentsModal(!myNetworkAgentsModal);
  };

  const cancelMyNetworkAgents = () => {
    const { myAgents = [] } = profile;
    setSelectedNetworkAgents(myAgents);
    toggleMyNetworkAgents();
  };

  const getAgents = () => {
    return myNetworkAgents.filter(item => {
      if (myAgentSearch.length > 3) {
        return JSON.stringify(item).includes(myAgentSearch);
      }
      return true;
    });
  };

  const selectedNetworkAgentRow = index => {
    const arrayCopy = myNetworkAgents.slice();
    arrayCopy[index].selected = !arrayCopy[index].selected;
    setMyNetworkAgents([...arrayCopy]);
  };

  const makeIcon = role => {
    switch (role) {
      case 'user':
        return 'user';
      case 'manager':
        return 'user';
      case 'admin':
        return 'user';
      case 'super_admin':
        return 'user';
      default:
        return 'user';
    }
  };

  const isUser = role => {
    switch (role) {
      case 'manager':
        return false;
      case 'admin':
        return false;
      case 'super_admin':
        return false;
      default:
        return true;
    }
  };

  const makeNetworkAgents = () => {
    const myNetworkAgents = networkAgents.map(agent => {
      const { firstName, lastName, agentID, role, email, phone } = agent;

      const selected = selectedNetworkAgents.includes(agentID);

      return {
        label: `${firstName} ${lastName} - ${role}`,
        searchText: JSON.stringify({ firstName, lastName, email, role }),
        hoverText: `${firstName} ${lastName}`,
        icon: makeIcon(role),
        disabled: false,
        selected,
        id: agentID,
        attributes: {
          agentID,
          firstName,
          lastName,
          email,
          phone,
          role,
        },
      };
    });

    setMyNetworkAgents(myNetworkAgents);
  };

  const getManagers = async () => {
    const { data, success } = await API.post(
      process.env.REACT_APP_API,
      '/network/managers',
      { body: { marketplace } }
    );
    setManagersLoading(false);
    if (success) {
      setManagers(
        data.map(manager => ({
          label: `${manager.firstName} ${manager.lastName}`,
          value: manager.agentID,
        }))
      );

      const agentsManagers = data
        .filter(manager => manager.agents.includes(profileID))
        .map(manager => manager.agentID);
      setOriginalManagers(agentsManagers);
      setSelectedManagers(agentsManagers);
    }
  };

  const getProfile = async () => {
    if (!profile) {
      if (isSameUser) {
        const { myAgents = [] } = user;
        setProfile(user);
        setCRMProfiles(user.crmProfiles);
        setSelectedNetworkAgents(myAgents);
      } else {
        const newProfile = await loadUser(profileID);
        const { myAgents = [] } = newProfile;
        setProfile(newProfile);
        setCRMProfiles(newProfile.crmProfiles);
        setSelectedNetworkAgents(myAgents);
      }
    }
  };

  const saveMyAgents = async () => {
    toggleMyNetworkAgents();
    try {
      const { data, success, message } = await API.post(
        process.env.REACT_APP_API,
        '/agent/agents',
        {
          body: {
            agentID: profileID,
            agentIDs: myNetworkAgents
              .filter(a => {
                return a.selected;
              })
              .map(b => {
                return b.id;
              }),
          },
        }
      );

      if (success) {
        notificationStore.create('success', 'Agents updated successfully');
        setProfile({ ...profile, myAgents: data });
      } else {
        const { myAgents = [] } = profile;
        setSelectedNetworkAgents(myAgents);
        notificationStore.create('danger', message);
      }
    } catch (error) {
      const { myAgents = [] } = profile;
      setSelectedNetworkAgents(myAgents);
      notificationStore.create('danger', error.message);
    }
  };

  const changeRegion = region => {
    const networks = {
      usha_dorne: '187bf812-09e0-4a6a-b762-f80b93cadc62',
      usha_greif: 'd0085065-49f4-4ded-b244-23b063e31405',
      usha_still: '09454175-9390-436e-a9f2-dbe7278a5a41',
      usha_yecina: 'd3e7f212-cdea-4420-9e3a-e470dbc18f29',
    };

    const newRegion = {
      companyID: region,
      marketplace: region === 'usha_dorne' ? 'usha' : region,
      networkID: networks[region],
    };

    console.log(newRegion);

    setProfile({ ...profile, ...newRegion });
  };

  const save = async () => {
    console.log('profile save');
    const {
      agentApproved,
      agentCRMID,
      agentID,
      email,
      firstName,
      lastName,
      phone,
      profileID,
      role,
      subID,
      networkID,
      corpID,
      marketplace,
      companyID,
    } = profile;

    try {
      setLoading(true);
      const { data, success, message } = await updateUser({
        agentApproved,
        agentCRMID,
        agentID,
        email,
        firstName,
        lastName,
        phone,
        profileID,
        role,
        subID,
        crmProfiles,
        networkID,
        marketplace,
        companyID,
      });

      console.log('here', isSameUser, success);
      if (success) {
        notificationStore.create('success', 'Profile updated successfully');
        if (isSameUser) {
          syncAgent(data);
        } else {
          setProfile(data);
        }
      } else {
        notificationStore.create('failure', message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getManagers();
    getProfile();
  }, [profileID]);

  useEffect(() => {
    if (networkAgents) {
      makeNetworkAgents();
    }
  }, [selectedNetworkAgents, networkAgents]);

  useEffect(() => {
    if (profile) {
      const { myAgents = [], crmProfiles = {} } = profile;
      setCRMProfiles(crmProfiles);
      setSelectedNetworkAgents(myAgents);
    }
  }, [profile]);

  if (loading || profile === null) {
    return <LoadingComponent />;
  }

  if (profile === false) {
    return <Page404 />;
  }
  let roleLabel;

  switch (profile.role) {
    case 'user':
      roleLabel = 'User';
      break;
    case 'manager':
      roleLabel = 'Manager';
      break;
    case 'admin':
      roleLabel = 'Administrator';
      break;
    case 'super_admin':
      roleLabel = 'Super Admin';
      break;
    default:
  }

  const isJustAgent = isUser(profile.role);

  return (
    <div>
      <AvForm
        style={{ width: '100%' }}
        model={{
          agentCRMID: profile.agentCRMID,
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          phone: profile.phone,
        }}
        onSubmit={() => {}}
        onValidSubmit={() => {
          save();
        }}
      >
        {' '}
        <CenterComponent>
          <Card body style={{ maxWidth: 900 }}>
            <CardTitle
              className="text-right pr-3 pl-3 text-white pb-2"
              tag="h4"
            >
              <div style={{ float: 'left' }}>
                <ButtonGroup>
                  <RsButton size="md" tag={Link} to="/myagents">
                    <i
                      className="fas fa-caret-left"
                      style={{ marginRight: '5px' }}
                    />
                    Back
                  </RsButton>
                  <Button disabled={!allowSelfEdit} color="success">
                    Save Profile
                  </Button>

                  <Button
                    id="EditAgentsButton"
                    disabled
                    color="primary"
                    onClick={toggleMyNetworkAgents}
                  >
                    Edit Agents
                    <DisabledPopOver className="ml-2" id="editAgents" />
                  </Button>
                </ButtonGroup>
              </div>
              {isSameUser ? 'My' : 'Agent'} Profile
            </CardTitle>

            {!isSameUser && ['manager', 'super_admin'].includes(user.role) && (
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label for="agentApproved">Approval</Label>
                    <br />
                    <Switch
                      checked={['true', true].includes(profile.agentApproved)}
                      id="agentApproved"
                      onChange={() =>
                        setProfile(
                          Object.assign({}, profile, {
                            agentApproved: !['true', true].includes(
                              profile.agentApproved
                            ),
                          })
                        )
                      }
                    />
                    <FormText>
                      Agents require manager approval before they can use
                      marketplace services.
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <div className="d-flex align-items-baseline">
                      <Label for="managers" className="mr-2">
                        Managers <DisabledPopOver id="managers" />
                      </Label>
                      {managersLoading && (
                        <FaSync className="loading-sync" size={24} />
                      )}
                    </div>

                    <Select
                      isMulti
                      isDisabled
                      styles={customStyles}
                      name="managers"
                      //  labelClass='text-dark'
                      onChange={m => setSelectedManagers(m)}
                      options={managers}
                      value={selectedManagers}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {!isSameUser && ['admin', 'super_admin'].includes(user.role) && (
              <Row>
                <Col xs="4">
                  <FormGroup>
                    <Label>Role</Label>
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={() => setDropdownOpen(!dropdownOpen)}
                    >
                      <DropdownToggle caret>{roleLabel}</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() =>
                            setProfile(
                              Object.assign({}, profile, {
                                role: 'user',
                              })
                            )
                          }
                          value="user"
                        >
                          User
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setProfile(
                              Object.assign({}, profile, {
                                role: 'manager',
                              })
                            )
                          }
                          value="manager"
                        >
                          Manager
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            setProfile(
                              Object.assign({}, profile, {
                                role: 'admin',
                              })
                            )
                          }
                          value="admin"
                        >
                          Admin
                        </DropdownItem>
                        {user.role === 'super_admin' && (
                          <DropdownItem
                            onClick={() =>
                              setProfile(
                                Object.assign({}, profile, {
                                  role: 'super_admin',
                                })
                              )
                            }
                            value="super_admin"
                          >
                            Super Admin
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>

                {['super_admin'].includes(user.role) && (
                  <Col xs="4">
                    <FormGroup>
                      <Label>Region</Label>
                      <Dropdown
                        isOpen={regionDropdownOpen}
                        toggle={() =>
                          setRegionDropdownOpen(!regionDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {profile.companyID}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => changeRegion('usha_dorne')}
                            value="usha_dorne"
                          >
                            Dorne
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => changeRegion('usha_greif')}
                            value="usha_greif"
                          >
                            Greif
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => changeRegion('usha_still')}
                            value="usha_still"
                          >
                            Still
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => changeRegion('usha_yecina')}
                            value="usha_yecina"
                          >
                            Yecina
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            )}
            <Row>
              <Col xs="6">
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    disabled={!allowSelfEdit}
                    name="firstName"
                    onChange={e =>
                      setProfile(
                        Object.assign({}, profile, {
                          firstName: e.target.value,
                        })
                      )
                    }
                    value={profile.firstName}
                  />
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    disabled={!allowSelfEdit}
                    name="lastName"
                    onChange={e =>
                      setProfile(
                        Object.assign({}, profile, {
                          lastName: e.target.value,
                        })
                      )
                    }
                    value={profile.lastName}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <FormGroup>
                  <Label>Phone</Label>
                  <Input
                    disabled={!canEdit}
                    name="phone"
                    onChange={e =>
                      setProfile(
                        Object.assign({}, profile, {
                          phone: e.target.value,
                        })
                      )
                    }
                    value={profile.phone}
                  />
                </FormGroup>
              </Col>
              <Col xs="8">
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    disabled={!canEdit}
                    name="email"
                    onChange={e =>
                      setProfile(
                        Object.assign({}, profile, {
                          email: e.target.value,
                        })
                      )
                    }
                    value={profile.email}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Card>
        </CenterComponent>
        <CenterComponent>
          <Card body style={{ maxWidth: 900 }}>
            {network.availibleCRMs ? (
              <CRMProfiles
                save={save}
                canEdit={canEdit}
                allowSelfEdit={allowSelfEdit}
                role={profile.role}
                onChange={item => {
                  setCRMProfiles(item);
                }}
                availibleCRMs={network.availibleCRMs}
                profiles={crmProfiles}
              />
            ) : null}
          </Card>
        </CenterComponent>
      </AvForm>

      <CenterComponent>
        <Card body style={{ maxWidth: 900 }}>
          <Agents
            agents={myNetworkAgents
              .filter(a => {
                return a.selected;
              })
              .map(b => {
                return { ...b.attributes };
              })}
          />
        </Card>
      </CenterComponent>

      <Modal isOpen={myNetworkAgentsModal} toggle={toggleMyNetworkAgents}>
        <ModalHeader toggle={toggleMyNetworkAgents}>Network Agents</ModalHeader>
        <ModalBody>
          <LocalTable
            multiSelect
            data={getAgents()}
            groupBy={3}
            pageSize={5}
            rowSelected={selectedNetworkAgentRow}
            searched={searchText => {
              setMyAgentSearch(searchText);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={cancelMyNetworkAgents}>
            Cancel
          </Button>
          <Button color="primary" onClick={saveMyAgents}>
            Save Agents
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
Profile.propTypes = {
  agentID: PropTypes.string.isRequired,
};

export default observer(Profile);
