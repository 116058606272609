/* eslint-disable promise/always-return */

/* eslint-disable promise/catch-or-return */

/* import React  from "react"; 
import { Redirect } from '@reach/router'; 
 
// For Now Redirect 
export default () => <Redirect to="/account" /> */

import React, { useEffect, useState, useContext } from 'react';
import Iframe from 'react-iframe';
import { Button, Card } from "reactstrap";
import { CenterComponent } from "../../shared/helpers/helpers";
import StoreContext from '../../stores';

const Home = () => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [statURL, setStatURL] = useState();

  const {
    userStore: { user },
  } = useContext(StoreContext);
  // const { marketplace } = user;
  const { marketplace } = user;

  const updateWindowDimensions = () => {
    //   const width = window.innerWidth;
    // const height = window.innerHeight;
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {

      if(marketplace === 'sonic'){
        setStatURL('https://search-sonic-public-hxpqpqo22jq3glsfkw2oqdtoxq.us-east-1.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/84ddec90-d0d8-11e8-8627-114b46fb9795?embed=true&_g=(refreshInterval%3A(pause%3A!f%2Cvalue%3A300000)%2Ctime%3A(from%3Anow%2Fd%2Cmode%3Aquick%2Cto%3Anow%2Fd))')
      }else if(marketplace === 'usha'){
        setStatURL(`https://d18qtpflo17qb9.cloudfront.net`)
      }
  }, [user]); // empty-array means don't watch for any updates

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []); // empty-array means don't watch for any updates

  const iframeOffset = 120;

  

  return (
    <div className="mt-1 d-flex justify-content-center">

      <div style={{ width: width - iframeOffset, height }}>
      <CenterComponent>
      <Button
      outline
      color="primary"
      size="lg"
  
      onClick={() => window.open('https://sonic.helpscoutdocs.com/', "_blank") }
    >
      Help Guides
    </Button>        
    </CenterComponent>
        <div
          style={{
            backgroundColor: '#222',
            height: 45,
            position: 'absolute',
            width: width - iframeOffset,
            borderTopWidth: 2,
            borderColor: '#000000',
          }}
        />
        {statURL && (
          <Iframe
            url={statURL}
            width={width - iframeOffset}
            height={height}
            className="border-0"
            id="board"
            display="initial"
            allowFullScreen
          />
        )}
      </div>
    </div>
  );
};

Home.propTypes = {
  // navigate: PropTypes.func.isRequired,
};

export default Home;
