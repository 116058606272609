import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
  FaFlask,
  FaFont,
  FaHashtag,
  FaTrash,
} from 'react-icons/fa';
import {
  Button,
  ButtonGroup,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
} from 'reactstrap';

import StoreContext from '../../../stores';

const chunk = (arr, size) => {
  const chunks = [];
  let idx = 0;

  while (idx < arr.length) {
    chunks.push(arr.slice(idx, size + idx));
    idx += size;
  }
  return chunks;
};

const VanillaSoftTab = ({
  canEdit,
  crmCampaign,
  crmID,
  onChange,
  profiles,
}) => {
  const {
    userStore,
  } = React.useContext(StoreContext);

  const networkFields = toJS(userStore.user.network.availibleCRMs.vanillaSoftDirect.fields);

  const createProfile = () => {
    if (canEdit) {
      onChange(crmID, 'profiles', profiles.concat([{
        crmExcludeUserID: false,
        crmFields: Object.keys(networkFields).reduce(
          (acc, fieldProp) => ({
            ...acc,
            [fieldProp]: '',
          }),
          {},
        ),
        id: `vanillaSoftDirect${profiles.length + 1}`,
      }]));
    }
  };

  const deleteProfile = deleteIdx => {
    if (canEdit) {
      onChange(crmID, 'profiles', profiles.filter(
        (profile, idx) => idx !== deleteIdx,
      ));
    }
  };

  const setProfileField = (setIdx, fieldName, fieldValue) => {
    if (canEdit) {
      onChange(crmID, 'profiles', profiles.map(
        (profile, idx) => idx === setIdx ? {
          ...profile,
          [fieldName]: fieldValue,
        } : profile,
      ));
    }
  };

  const setProfileCRMField = (setIdx, fieldName, fieldValue) => {
    if (canEdit) {
      setProfileField(setIdx, 'crmFields', {
        ...profiles[setIdx].crmFields,
        [fieldName]: fieldValue,
      });
    }
  };

  return (
    <Table style={{
      userSelect: 'none',
    }}>
      <thead>
        {userStore.user.role === 'user' ? null : (
          <tr style={{ border: 'none' }}>
            <th colSpan="2">
              <InputGroup>
                <InputGroupAddon addonType="prepend">Campaign</InputGroupAddon>
                <Input
                  onChange={e => onChange(crmID, 'crmCampaign', e.target.value)}
                  disabled={!canEdit}
                  placeholder="Jon Smith Team"
                  type="text"
                  value={crmCampaign}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FaFont title="text" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </th>
          </tr>
        )}
        <tr>
          <th style={{ width: '50px' }}>Profiles</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {profiles.map(
          (profile, profileIdx) => (
            <tr key={profile.id}>
              <td>
                <span>
                  <FaHashtag style={{ marginBottom: '12px' }} />
                  <h4 style={{ display: 'inline' }}>{profileIdx + 1}</h4>
                </span>
                {canEdit && (
                  <ButtonGroup vertical>
                    <Button
                      color="info"
                      disabled
                      title="Test profile (COMING SOON)" // TODO: Add this
                    >
                      <FaFlask />
                    </Button>
                    <Button
                      color="danger"
                      onClick={() => deleteProfile(profileIdx)}
                      title="Delete profile"
                    >
                      <FaTrash />
                    </Button>
                  </ButtonGroup>
                )}
              </td>
              <td>
                <Table>
                  <thead>
                    <tr>
                      <th style={{
                        border: 'none',
                        width: '50%',
                      }} />
                      <th style={{ border: 'none' }} />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        colSpan="2"
                        style={{ border: 'none' }}
                      >
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">Description</InputGroupAddon>
                          <Input
                            disabled={!canEdit}
                            invalid={!profile.description}
                            onChange={e => setProfileField(profileIdx, 'description', e.target.value)}
                            placeholder="A descriptive name for this profile"
                            type="string"
                            value={profile.description}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <FaFont title="text" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormFeedback>This field is required</FormFeedback>
                        </InputGroup>
                      </td>
                    </tr>
                    {chunk(
                      Object.keys(networkFields).map(
                        fieldProp => ({
                          ...networkFields[fieldProp],
                          fieldProp,
                        }),
                      ),
                      2,
                    ).map(
                      (chunk, chunkIdx) => (
                        <tr key={`chunk:${chunkIdx}`}>
                          <td style={{ border: 'none' }}>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">{chunk[0].label}</InputGroupAddon>
                              <Input
                                disabled={!canEdit}
                                invalid={chunk[0].required && !(profile.crmFields || {})[chunk[0].fieldProp]}
                                onChange={e => setProfileCRMField(profileIdx, chunk[0].fieldProp, e.target.value)}
                                placeholder={chunk[0].placeholder}
                                type={chunk[0].type}
                                value={(profile.crmFields || {})[chunk[0].fieldProp]}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  {chunk[0].type === 'number' ?
                                    <FaHashtag title="number" /> :
                                    <FaFont title="text" />
                                  }
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>This field is required</FormFeedback>
                            </InputGroup>
                          </td>
                          <td style={{ border: 'none' }}>
                            {chunk.length === 2 && (
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">{chunk[1].label}</InputGroupAddon>
                                <Input
                                  disabled={!canEdit}
                                  invalid={chunk[1].required && !(profile.crmFields || {})[chunk[1].fieldProp]}
                                  onChange={e => setProfileCRMField(profileIdx, chunk[1].fieldProp, e.target.value)}
                                  placeholder={chunk[1].placeholder}
                                  type={chunk[1].type}
                                  value={(profile.crmFields || {})[chunk[1].fieldProp]}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    {chunk[1].type === 'number' ?
                                      <FaHashtag title="number" /> :
                                      <FaFont title="text" />
                                    }
                                  </InputGroupText>
                                </InputGroupAddon>
                                <FormFeedback>This field is required</FormFeedback>
                              </InputGroup>
                            )}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                   {/*   <tfoot>
                <tr>
                      <td colSpan="2" style={{ border: 'none' }}>
                        {canEdit && (
                          <FormGroup>
                            <CustomInput
                              checked={profile.crmExcludeUserID}
                              id={`crmExcludeUserID${profileIdx}`}
                              label="Exclude CRM user ID"
                              onChange={() => setProfileField(profileIdx, 'crmExcludeUserID', !profile.crmExcludeUserID)}
                              type="checkbox"
                            />
                          </FormGroup>
                        )}
                      </td>
                    </tr>
                        </tfoot>*/}
                </Table>
              </td>
            </tr>
          ),
        )}
      </tbody>
      <tfoot>
      {canEdit && <tr>
          <td colSpan="2">
          <Button
              color="success"
              onClick={() => createProfile()}
            >
              Add New profile
            </Button>
          </td>
        </tr> }
      </tfoot>
    </Table>
  );
};

VanillaSoftTab.defaultProps = {
  profiles: [],
};

VanillaSoftTab.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  crmCampaign: PropTypes.string.isRequired,
  crmID: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({
    crmCampaign: PropTypes.string.isRequired,
    crmExcludeUserID: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
  })),
};

export default observer(VanillaSoftTab);