import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

const ButtonIcon = ({
  color,
  iconClassName,
  ...otherProps,
}) => (
  <Button
    color={color}
    outline
    {...otherProps}
  >
    <i className={iconClassName} />
  </Button>
);

ButtonIcon.defaultProps = {
  color: 'primary',
};
ButtonIcon.propTypes = {
  color: PropTypes.oneOf(['danger', 'primary', 'secondary', 'success', 'warning']),
  iconClassName: PropTypes.string.isRequired,
};

export default ButtonIcon;