import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { Page404 } from '..';
import Form from './Form';
import API from '../../shared/api';
import {
  CenterComponent,
  LoadingComponent,
} from '../../shared/helpers/helpers';
import StoreContext from '../../stores';

const Campaign = props => {
  const { campaignID } = props;
  const {
    userStore: { hasAssumedRole, user },
  } = useContext(StoreContext);
  const { marketplace } = user;

  if (hasAssumedRole) {
    return (
      <Card>
        <CardHeader>Unauthorized</CardHeader>
        <CardBody>
          Campaign editing is not allowed for assumed roles. We apologize for
          the inconvenience.
        </CardBody>
      </Card>
    );
  }
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState({
    queueName: marketplace === 'sonic' ? 'sonic_campaigns' : 'usha_campaignsV3',
    campaignStatus: 'active',
    leadType: marketplace === 'sonic' ? 'exclusive' : 'vendor',
    destinations: [],
    verticalType: 'health',
    isAgent: true,
    maxAgeAllowed: user.network.maxAgeAllowed || 86400000,
    priceMapType: 'fixed',
  });

  const getCampaign = async id => {
    const body = { campaignID: id };

    setLoading(true);

    try {
      const { data, message, success } = await API.post(
        process.env.REACT_APP_API,
        `/campaign/single`,
        { body }
      );

      if (success) {
        setCampaign(data);
      } else {
        setErrorMessage(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const refresh = campaignID => {
    getCampaign(campaignID);
  };

  useEffect(() => {
    setLoading(true);

    if (campaignID !== 'new' && campaignID !== '') {
      getCampaign(campaignID);
    } else {
      setLoading(false);
    }
  }, [campaignID]);

  if (loading) {
    return (
      <CenterComponent>
        <LoadingComponent
          color="danger"
          style={{
            height: '5rem',
            width: '5rem',
          }}
          type="grow"
        />
      </CenterComponent>
    );
  }

  if (Object.keys(campaign).length === 0) {
    return <Page404 />;
  }
  return (
    <div style={{ marginTop: 20 }}>
      {errorMessage ? (
        <p style={{ color: 'red' }}>{errorMessage}</p>
      ) : (
        <Form
          campaign={campaign}
          refresh={refresh}
          viewLoading={loading}
          {...props}
        />
      )}
    </div>
  );
};

Campaign.propTypes = {
  navigate: PropTypes.func,
  campaignID: PropTypes.string,
};

export default Campaign;
