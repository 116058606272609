import {
  action,
  decorate,
  observable,
} from 'mobx';

import API from '../shared/api';

class VendorCampaignStore {
  list = [];

  synced = false;

  constructor(notificationStore) {
    this.notificationStore = notificationStore;
  }

  async create(props) {
    this.synced = false;

    try {
      const {
        data: vendorResults,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/vendor_campaigns',
        { body: props },
      );

      if (success) {
        const { vendorCampaigns, dynamoVendorLayers} = vendorResults
        this.list = dynamoVendorLayers;
        this.campaignsList = vendorCampaigns
        this.synced = true;
      } else {
        this.notificationStore.create('danger', message);
      }
    } catch (error) {
      console.error(error);

      this.notificationStore.create('danger', 'Internal server error.');
    }
  }

  async load(marketplace) {
    this.synced = false;

    try {
      const {
        data: vendorResults,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/vendor_campaigns/list',
        { body: { marketplace } },
      );
  
      if (success) {
        const { vendorCampaigns, dynamoVendorLayers} = vendorResults
        this.list = dynamoVendorLayers;
        this.campaignsList = vendorCampaigns
        this.synced = true;
      } else {
        this.notificationStore.create('danger', message);
      }
    } catch (error) {
      console.error(error);

      this.notificationStore.create('danger', 'Internal server error.');
    }
  }

  async save(passedVendorCampaigns, marketplace) {
    this.synced = false;

    try {
      const {
        data: vendorResults,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/vendor_campaigns/tier_assignments',
        { body: { vendorCampaigns: passedVendorCampaigns, marketplace } },
      );

      if (success) {
        const { vendorCampaigns, dynamoVendorLayers} = vendorResults
        this.list = dynamoVendorLayers;
        this.campaignsList = vendorCampaigns
        this.synced = true;
      } else {
        this.notificationStore.create('danger', message);
      }
    } catch (error) {
      console.error(error);

      this.notificationStore.create('danger', 'Internal server error.');
    }
  }

}

decorate(VendorCampaignStore, {
  list: observable,
  campaignsList: observable,
  synced: observable,
  create: action,
  load: action,
  save: action,
});

export default VendorCampaignStore;