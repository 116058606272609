import PropTypes from 'prop-types';
import React from 'react';

import DestinationComponent from '../../../../Destinations';

const StepDelivery = ({
  destinationIDs,
  myAgentDestinationIDs,
  setDestinationIDs,
}) => (
  <>
    <h5>Deliver your leads</h5>
    <DestinationComponent
      onChange={payload => {
        setDestinationIDs([...new Set(payload.destinations)], [...new Set(payload.myAgentDestinations)]);
      }}
      selectedAgentDestIDs={myAgentDestinationIDs}
      selectedDestIDs={destinationIDs}
    />
  </>
);

StepDelivery.defaultProps = {
  destinationIDs: [],
  myAgentDestinationIDs: [],
};

StepDelivery.propTypes = {
  destinationIDs: PropTypes.arrayOf(PropTypes.string),
  myAgentDestinationIDs: PropTypes.arrayOf(PropTypes.string),
  setDestinationIDs: PropTypes.func.isRequired,
};

export default StepDelivery;