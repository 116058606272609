import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
    Button,
    ButtonGroup,
    FormGroup,
    FormText,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap';

import MoneyInput from '../../../shared/components/MoneyInput';
import { formatCurrencyForDisplay } from '../Utils';

const AgentTableRow = ({
    agentID,
    assumeRole,
    contributedCredit,
    firstName,
    email,
    lastName,
    navigateToViewProfile,
    setAmount,
    team,
    totalCredit,
    agentTransfers,
    userRole
  }) => {
    const transferAmount = agentTransfers[agentID] || 0;
    const setFinalCredit = amount => setAmount(amount - totalCredit, agentID);
    const setTransferAmount = amount => setAmount(amount,agentID);

    let transferErrorMessage;

    if (totalCredit >= contributedCredit && contributedCredit + transferAmount < 0) {
      transferErrorMessage = 'You cannot withdraw more than you contributed.';
    } else if (totalCredit < contributedCredit && totalCredit + transferAmount < 0) {
      transferErrorMessage = 'You cannot withdraw more than the agent has.';
    } else {
      transferErrorMessage = '';
    }
    return(
      <tr>
        <td>
          {team}
          <br />
          <i>{firstName} {lastName}</i>
        </td>
        <td className="pl-4 text-left">
          <ButtonGroup size="sm">
            <Button
              onClick={() => navigateToViewProfile(agentID)}
              outline
              title="View Profile"
            >
              <i className="fa fa-eye" />
            </Button>
            {['manager', 'super_admin'].includes(userRole) && <Button
              color="primary"
              onClick={() => assumeRole(agentID)}
              title="Assume Role"
            >
              <i className="fa fa-user-friends" />
            </Button>}
          </ButtonGroup>
        </td>
        <td>{email}</td>
        <td style={{ minWidth: '72px' }}>{formatCurrencyForDisplay(totalCredit)}</td>
        <td style={{ minWidth: '72px' }}>{formatCurrencyForDisplay(contributedCredit)}</td>
        <td>
          <InputGroup
            size="sm"
            style={{ minWidth: '100px' }}
          >
            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
            <MoneyInput
              minimum={-contributedCredit}
              setValue={setTransferAmount}
              value={transferAmount}
            />
          </InputGroup>
        </td>
        <td>
          <FormGroup style={{ margin: 0 }}>
            <InputGroup
              size="sm"
              style={{ minWidth: '100px' }}
            >
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <MoneyInput
                minimum={(totalCredit - contributedCredit)}
                setValue={setFinalCredit}
                value={(totalCredit + transferAmount)}
              />
            </InputGroup>
            <FormText
              color="danger"
              style={{
                height: '19px',
                position: 'relative',
              }}
            >
                <span style={{
                  position: 'absolute',
                  right: 0,
                  whiteSpace: 'nowrap',
                }}>
                  {transferErrorMessage}
                </span>
            </FormText>
          </FormGroup>
        </td>
      </tr>
    );
  }

  AgentTableRow.defaultProps = {
    team: '',
  };

  AgentTableRow.propTypes = {
    agentID: PropTypes.string.isRequired,
    assumeRole: PropTypes.func.isRequired,
    contributedCredit: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    navigateToViewProfile: PropTypes.func.isRequired,
    setAmount: PropTypes.func.isRequired,
    team: PropTypes.string,
    totalCredit: PropTypes.number.isRequired,
    agentTransfers: PropTypes.object.isRequired,
    userRole: PropTypes.oneOf(['admin', 'manager', 'super_admin', 'user']).isRequired,
  };

export default observer(AgentTableRow);