import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from 'reactstrap';
import { FaSync } from 'react-icons/fa';
import uuid from 'uuidv4';

import SpendingPeriod from './SpendingPeriod';

import {
    sumSpendingPeriods,
} from '../Utils';
import { formatCurrencyForDisplay } from '../../MyAgents/Utils';

const MAX_SPENDING_PERIODS = 4;
const MIN_DAILY_SPEND = 100;

const EditSpendingPeriodModal = observer(({
    campaign,
    closeModal,
    isOpen,
    saveCampaign,
    saving,
}) => {
    const initialSpendingPeriods = JSON.parse(JSON.stringify(campaign.spendingPeriods));
    const [spendingPeriods, setSpendingPeriods] = useState(initialSpendingPeriods);

    // Done
    const addNewSpendingPeriod = () => {


        const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));

        nextSpendingPeriods.push({
            dailySpendLimit: 0,
            dowSun: true,
            dowMon: true,
            dowTue: true,
            dowWed: true,
            dowThu: true,
            dowFri: true,
            dowSat: true,
            endTime: 0,
            id: uuid(),
            lastPurchaseEpoch: 0,
            lastPurchasePeriodSpend: 0,
            startTime: 0,
        });
        setSpendingPeriods(nextSpendingPeriods);
    };

    // Done
    const close = () => {
        setSpendingPeriods(initialSpendingPeriods);
        closeModal();
    };

    // Done
    const removeSpendingPeriod = periodIdx => {
        const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));

        nextSpendingPeriods.splice(periodIdx, 1);
        setSpendingPeriods(nextSpendingPeriods);
    };

    // Done
    const save = async () => {
        campaign.spendingPeriods = spendingPeriods;

        await saveCampaign(JSON.parse(JSON.stringify(campaign)));
        closeModal();
    }

    const canSave = spendingPeriods.filter(s => s.dailySpendLimit >= MIN_DAILY_SPEND).length === spendingPeriods.length;

    const spendingPeriodExists = spendingPeriods.length > 0;


   const getNoActive = () => <tr><td>No active spending periods.</td></tr>;

   const getSpendingPeriods = () => spendingPeriods.map((data, periodIdx) => {
            return <SpendingPeriod 
                    {...data} 
                    period={periodIdx} 
                    setSpendingPeriods={setSpendingPeriods}
                    spendingPeriods={spendingPeriods}
                    removeSpendingPeriod={removeSpendingPeriod}/>       
            }
         )




    const getSubmitButtons = () =>  <ButtonGroup>
            <Button
                color="primary"
                disabled={campaign.spendingPeriods.length >= MAX_SPENDING_PERIODS}
                onClick={addNewSpendingPeriod}
            >
                Add New Spending Period
            </Button>
            {canSave && <Button
                color="success"
                onClick={save}
            >
                Save Campaign
            </Button>}
        </ButtonGroup>


    return (
        <Modal
            isOpen={isOpen}
            toggle={close}
        >
            <ModalHeader toggle={close}>Spending Periods - {campaign.campaignName}</ModalHeader>
            <ModalBody>
                <Table>
                    <tbody>
                        
                        { spendingPeriodExists ? getSpendingPeriods() : getNoActive()}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                {saving ? <FaSync className="loading-sync" size={24} />: getSubmitButtons()}
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </ModalBody>
            <ModalFooter>Weekly maximum: {formatCurrencyForDisplay(sumSpendingPeriods(campaign.spendingPeriods))}</ModalFooter>
        </Modal>
    );
});

EditSpendingPeriodModal.propTypes = {
    campaign: PropTypes.shape({
        autopaySettings: PropTypes.shape({
            addBalance: PropTypes.number,
            enabled: PropTypes.bool,
            threshold: PropTypes.number,
        }),
        balance: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        paused: PropTypes.bool,
        spendingPeriods: PropTypes.arrayOf(PropTypes.shape({
            dailySpendLimit: PropTypes.number,
            dowSun: PropTypes.bool,
            dowMon: PropTypes.bool,
            dowTue: PropTypes.bool,
            dowWed: PropTypes.bool,
            dowThu: PropTypes.bool,
            dowFri: PropTypes.bool,
            dowSat: PropTypes.bool,
            endTime: PropTypes.number,
            id: PropTypes.string,
            startTime: PropTypes.number,
        })),
    }),
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    saveCampaign: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
};

export default EditSpendingPeriodModal;