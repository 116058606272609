import React from "react"
import { observer } from 'mobx-react-lite';
import dayjs from "dayjs";
import SonicTable from "../../shared/components/SonicTable"
import { currencyFormater } from "../../shared/helpers/helpers";

const List = observer(() => (
    <SonicTable
      mobxTableName="transfers"
      rowKey="actionID"
      columns={[
        {
          "dataField": "activityDate",
          "displayName": "Date",
          formatter: value => dayjs(value).format("MM/DD/YY hh:mm a"),
          "visible": true
        },
        {
          "dataField": "actionID",
          "displayName": "Transfer Id",
          "visible": false,
        },
        {
          "dataField": "otherAgentFirstName",
          "displayName": "First",
          "visible": true
        },
        {
          "dataField": "otherAgentLastName",
          "displayName": "Last",
          "visible": true
        },
        {
          "dataField": "otherAgentEmail",
          "displayName": "Email",
          "visible": true
        },
        {
          "dataField": "diffAmount",
          "displayName": "Transfer Amount",
          "visible": true,
          formatter: value => currencyFormater.format((value) / 100)
        },
      ]}
      endpoint="/transfers"
      uniqueKeyProperty="actionID"
      totals={[
        {
        label:'Total Amount', 
        attribute:'diffAmount', 
        agg: 'sum', 
        formatter: value => currencyFormater.format((value) / 100)
      },
      ]}
    />
  ));

export default List
