import React, { useContext } from "react"
import { observer } from 'mobx-react-lite';
import SonicTable from "shared/components/SonicTable"
import dayjs from "dayjs";
import { currencyFormater } from "../../shared/helpers/helpers";
import StoreContext from '../../stores';


const List = observer(() => {

  const {
    userStore: {
      role,
    },
  } = useContext(StoreContext);


   return ( <SonicTable
      mobxTableName="leads"
      rowKey="leadID"
      columns={[
        {
          "dataField": "soldDate",
          "displayName": "Date",
          formatter: value => dayjs(value).format("MM/DD/YY h:mm a"),
          "visible": true
        },
        {
          "dataField": "leadID",
          "displayName": "Lead Id",
          "visible": false,
        },
        {
          "dataField": "campaignName",
          "displayName": "Campaign",
          "visible": true,
        },
        {
          "dataField": "price",
          "displayName": "Price",
          formatter: value => currencyFormater.format(value / 100),
          "visible": true,
        },
        {
          "dataField": "email",
          "displayName": "Email",
          "visible": true,
        },
        {
          "dataField": "firstName",
          "displayName": "First Name",
          "visible": true,
        },
        {
          "dataField": "lastName",
          "displayName": "Last Name",
          "visible": true,
        },
        {
          "dataField": "phone",
          "displayName": "Phone",
          "visible": true,
        },
        {
          "dataField": "region",
          "displayName": "Region",
          "visible": true,
        },
        {
          "dataField": "crmContactID",
          "displayName": "CRM ID",
          "visible": true,
        },
        {
          "dataField": "leadType",
          "displayName": "Lead Type",
          "visible": true,
        }, 
        {
          "dataField": "rawDOB",
          "displayName": "Dob",
          "visible": false,
        },
        {
          "dataField": "postalCode",
          "displayName": "Zip",
          "visible": false,
        },
        {
          "dataField": "city",
          "displayName": "City",
          "visible": false,
        },
        {
          "dataField": "household",
          "displayName": "Household",
          "visible": false,
        },
        {
          "dataField": "addressOne",
          "displayName": "Address1",
          "visible": false,
        },
        {
          "dataField": "addressTwo",
          "displayName": "Address2",
          "visible": false,
        },
        {
          "dataField": "addressThree",
          "displayName": "Address3",
          "visible": false,
        },
        {
          "dataField": "crmLastOfCallHistoryNameLastFirst",
          "displayName": "Last Assigned To",
          "visible": role !== 'user',
        },
        {
          "dataField": "result",
          "displayName": "Result",
          "visible": true,
        },
        {
          "dataField": "emailOnly",
          "displayName": "EmailOnly",
          "visible": false,
        },
        {
          "dataField": "isDuplicate",
          "displayName": "IsDuplicate",
          "visible": false,
        },

      ]}
      endpoint="/leads"
      uniqueKeyProperty="leadID"
    />)

  });

export default List
