import React from 'react';
import PropTypes from 'prop-types';
import { usePagination } from '@availity/pagination';
import { InputGroup } from 'reactstrap';
import { 
  AvForm, 
} from 'availity-reactstrap-validation';
import AvSelect from '@availity/reactstrap-validation-select';

const sizeOptions = [
    {
      label: '2',
      value: 2,
    },
    {
      label: '10',
      value: 10,
    },
    {
      label: '25',
      value: 25,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ];


const actionOptions = [
  {
    label: 'Export',
    value: 'export',
  },
  /*{
    label: 'Save Filter',
    value: 'save',
  }*/
];

const CursorControls = ({ onSearch, size, exportItems }) => {
  const { currentPage, setPage } = usePagination();
    
  const search = async payload => {
    onSearch(payload)
    if (currentPage !== 1) {
      setPage(1);
    }
}

const peformTableAction = async option => {
  if(option === 'export'){
    exportItems()
  }
}
  
  return (

    <AvForm>
    <InputGroup className="d-flex justify-content-between w-auto">

    <AvSelect
    className="ml-2"
    styles={{
      menuList: provided => ({
        ...provided,
        color: '#232323',
      }),
    }}
    name="tableAction"
    id="action-select"
    options={actionOptions}
    onChange={option => peformTableAction(option)}
    value=''
    placeholder="Action"
    inputProps={{
      width: 200,
    }}
  />

    <AvSelect
    className="ml-2"
    styles={{
      menuList: provided => ({
        ...provided,
        color: '#232323',
      }),
    }}
    name="size"
    id="size-select"
    options={sizeOptions}
    onChange={option => search({size: option})}
    value={size}
    inputProps={{
      width: 200,
    }}
  />

  </InputGroup>
  </AvForm>
  );
};

CursorControls.propTypes = {
    onSearch: PropTypes.func,
    size: PropTypes.number,
    exportItems: PropTypes.func,
};

export default CursorControls;