import React, {useState, useEffect, Fragment} from "react";
import PropTypes from 'prop-types';
import { Table, Pagination, PaginationItem, PaginationLink,
  InputGroup, Input, Label
} from 'reactstrap';
import {
  FaUser,
  FaUserFriends,
  FaLink,
  FaSms,
  FaEnvelope,
 // FAQuestion

} from 'react-icons/fa';

const LocalTable = ({data = [], pageSize = 10, groupBy = 4, minToShowEnds = 100, rowSelected, multiSelect }) => {
//  const pagesCount = Math.ceil(data.length / pageSize)
  const [currentPage, setCurrentPage] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const [showBeginLast] = useState(data.length > minToShowEnds);
  const [searchText, setSearchText] = useState('');
  const [pagesCount, setPagesCount] = useState(0)
  const [pageData, setPageData] = useState([])

  useEffect(() => {
    setPagesCount(Math.ceil(data.length / pageSize))
    setPageData(data.filter(item =>{
        return JSON.stringify(item).includes(searchText)
    }))
  }, [data, searchText]);

  const handleRowSelection = item => {
      const index = data.findIndex(i => {
        return i === item
      })
      rowSelected(index)
  }

  const handleNext = e => {
    e.preventDefault();
    const newGroup = groupCount + groupBy
    setCurrentPage(newGroup)
    setGroupCount(groupCount + groupBy)
  }
  
  const handlePrevious = e => {
    
    e.preventDefault();
    const newGroup = groupCount - groupBy
    setCurrentPage(newGroup)
    setGroupCount(groupCount - groupBy)
  }
  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index + groupCount)
  }
  const resetStart = e => {
    e.preventDefault();
    setCurrentPage(0)
    setGroupCount(0)
  }
  const goToEnd = e => {
    e.preventDefault();
    setCurrentPage(pagesCount - 1)
    setGroupCount(pagesCount - 1)
  }


  const makePagnationItems = () => {
    const pages = [...new Array(groupBy)]
    return pages.map((page, i) => {
      const disable = (i + groupCount) * pageSize > pageData.length - 1
      if(disable){
       return null
      }
      return (
      <PaginationItem disabled={disable} active={i + groupCount === currentPage } key={i}>
        <PaginationLink onClick={e => handleClick(e, i)} href="#">
          {i + groupCount + 1}
        </PaginationLink>
      </PaginationItem>)
    })
  }
  const makeIcon = name => {
    switch(name){
      case 'user':
         return <FaUser />
      case 'team':
         return <FaUserFriends />
      case 'link':
        return <FaLink />
      case 'sms':
        return <FaSms />
      case 'email':
        return <FaEnvelope />
      default:
        return null
    }
  }
  const makeRows = data => {
    return data.map(item => {
      const { label, icon, disabled, selected, id  } = item
    return (
      <tr key={id} className="border border-white">
      <td >
      <InputGroup>
      <Input 
        className="ml-2"
        type={multiSelect ? "checkbox" : "radio"} 
        disabled={disabled}
        value={label}
        checked={selected}
        onChange={() => handleRowSelection(item)}/>
      <Label className="ml-4">{label}</Label>
      </InputGroup>
      </td> 
      <td className="d-flex justify-content-end" >
      <div className="mr-3"> {makeIcon(icon)} </div>
      </td>  
    </tr>)
    })
  }
  return (
    <Fragment>  
      <div className="d-flex justify-content-between">
      <InputGroup className="mr-1" >
      <Input 
        type="text" 
        placeholder="Search..."
        onChange={e => setSearchText(e.target.value)}
        value={searchText}
      />
      </InputGroup>
       <Pagination >
       {showBeginLast ? <PaginationItem disabled={currentPage < groupBy}>
          <PaginationLink
              onClick={e => resetStart(e)}
              first
              href="#"
            />
          </PaginationItem> : null}
          <PaginationItem disabled={currentPage < groupBy}>
          <PaginationLink
              onClick={e => handlePrevious(e)}
              previous
              href="#"
            />
          </PaginationItem>
          {makePagnationItems()}
          <PaginationItem disabled={currentPage + groupBy >= pagesCount - 1}> 
            <PaginationLink
              onClick={e => handleNext(e)}
              next
              href="#"
            />     
          </PaginationItem>
          {showBeginLast ?   <PaginationItem disabled={currentPage + groupBy >= pagesCount - 1}>
            <PaginationLink
                onClick={e => goToEnd(e)}
              last
              href="#"
            />     
          </PaginationItem> : null}
        </Pagination>
        </div>
      <Table hover size="sm" className="mt-1 border border-white">
        <tbody>
       {makeRows(pageData).slice(
            (currentPage) * pageSize,
            (currentPage + 1) * pageSize
          )}
        </tbody>
      </Table>

    </Fragment>
  );
}



LocalTable.propTypes = {
    data: PropTypes.array,
    pageSize: PropTypes.number,
    groupBy: PropTypes.number,
    rowSelected: PropTypes.func,
    multiSelect:PropTypes.bool, 
    minToShowEnds:PropTypes.number,
  };
  
  export default LocalTable;


