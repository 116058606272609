import PropTypes from 'prop-types';
import React from 'react';
import {
  Badge,
  Button,
} from 'reactstrap';
import { useDrag } from 'react-dnd';

const VendorCampaignBadge = ({
  canRemove,
  removeVendorCampaignFromTier,
  tierName,
  valid,
  vendorCampaignName,
}) => {
  const [, drag] = useDrag({
    item: {
      tierName,
      type: canRemove ?
        'VENDOR_CAMPAIGN_BADGE_REMOVABLE' :
        'VENDOR_CAMPAIGN_BADGE',
      valid,
      vendorCampaignName,
    },
  });

  return (
    <Badge
      color={valid ? 'dark' : 'danger'}
      key={vendorCampaignName}
      pill
      style={{
        height: '30px',
        margin: '0 5px 7px 0',
      }}
      title={vendorCampaignName}
    >
      <span
        ref={drag}
        style={{
          cursor: 'move',
          display: 'inline-block',
          maxWidth: '153px',
          overflow: 'hidden',
          paddingTop: '6px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {vendorCampaignName}
      </span>
      {canRemove && (
        <Button
          onClick={() => removeVendorCampaignFromTier(vendorCampaignName, tierName)}
          style={{
            backgroundColor: 'inherit',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            verticalAlign: 'inherit',
          }}
        >
          &ensp;×
        </Button>
      )}
    </Badge>
  );
};

VendorCampaignBadge.propTypes = {
  canRemove: PropTypes.bool.isRequired,
  removeVendorCampaignFromTier: PropTypes.func.isRequired,
  tierName: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  vendorCampaignName: PropTypes.string.isRequired,
};

export default VendorCampaignBadge;