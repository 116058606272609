/* eslint-disable no-undef */
import React, { useContext, useState } from 'react';
import { Card, CardTitle, Label, Button as RsButton } from 'reactstrap';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { AvSelectField } from '@availity/reactstrap-validation-select';
import Button from '@gopro16/button';
import { CenterComponent } from '../../shared/helpers/helpers';
import StoreContext from '../../stores';

const supportTypes = [
  { label: 'Request a CRM Profile', value: 'crmProfile' },
  { label: 'Leads Not Showing in CRM', value: 'leadNoShow' },
  { label: 'Account Profile', value: 'profile' },
  { label: 'Agent Transfers', value: 'transfers' },
  { label: 'Cards, Charges and Reciepts', value: 'billing' },
  { label: 'General Support Request', value: 'support' },
  { label: 'Report Bug', value: 'bug' },
  { label: 'Contribute Idea', value: 'idea' },
  { label: 'Other', value: 'other' },
];

const SUBMIT_TYPES = {
  submitting: 'SUBMITTING',
  submitted: 'SUBMITTED',
};

export default ({ navigate }) => {
  const [submitStep, setSubmitStep] = useState(null);
  const {
    userStore: { email, phone, userID, companyID, corpID, networkID, firstName, lastName, subID },
  } = useContext(StoreContext);

  return (
    <CenterComponent>
      <Card body style={{ maxWidth: 500 }}>
        <CardTitle tag="h4" className="text-center pr-3 pl-3 text-primary pb-2">
          {submitStep === SUBMIT_TYPES.submitted
            ? 'Thanks for your Feedback'
            : 'Reach out to Us'}
        </CardTitle>
        {submitStep === SUBMIT_TYPES.submitted ? (
          <h5 className="text-center">
            We will reach out to you as soon as possible.
            <div className="mt-3">
              <RsButton onClick={() => navigate('/')} color="primary">
                Back To Home
              </RsButton>
            </div>
          </h5>
        ) : (
          <AvForm
            onSubmit={() => setSubmitStep(null)}
            onInvalidSubmit={() => setSubmitStep(SUBMIT_TYPES.submitting)}
            onValidSubmit={async (event, data) => {
              const result = await fetch(
                'https://hooks.zapier.com/hooks/catch/2323687/vdsckq/',
                {
                  method: 'POST',
                  body: JSON.stringify({
                    email,
                    phone,
                    userID,
                    companyID, corpID, networkID, firstName, lastName, subID,
                    ...data,
                  }),
                }
              );
              setSubmitStep(SUBMIT_TYPES.submitted);
            }}
          >
            <AvSelectField
              name="supportType"
              options={supportTypes}
              label="Support Type"
              styles={{
                menuList: provided => ({
                  ...provided,
                  color: '#232323'
                })
              }}
              required
            />
            <AvField
              label="Description"
              name="description"
              rows="5"
              placeholder="Please provide a detailed description of your issue. 
              For campaign issues, please include the campaign name and spending period description. 
              If billing issues, please include transaction amount and date."
              type="textarea"
              required
            />
            <Button
              loading={submitStep === SUBMIT_TYPES.submitting}
              color="primary"
              block
            >
              Submit
            </Button>
          </AvForm>
        )}
      </Card>
    </CenterComponent>
  );
};

