// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_content_delivery_bucket:
    'sonic-marketplace-20190414012556-hostingbucket-dev',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://dgarhdt3o9zlk.cloudfront.net',
  aws_cognito_identity_pool_id:
    'us-east-1:1c500333-d67b-40fe-b596-4d22eb319ee2',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_VxaRaMrPy',
  aws_user_pools_web_client_id: '1d3nppplk4sqp1ba1coten94s0',
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: 'MarketPlace',
      endpoint: 'https://2fo5gtgkh6.execute-api.us-east-1.amazonaws.com/dev',
      region: 'us-east-1',
    },
  ],
};

export default awsmobile;
