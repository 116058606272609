import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'reactstrap';

class MoneyInput extends React.Component {
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      displayValue: (value / 100).toFixed(2),
      useControlledValue: true,
    };
  }

  setDisplayValue(nextDisplayValue) {
    const { setValue } = this.props;
    let { displayValue } = this.state;

    if (
      nextDisplayValue !== displayValue &&
      /^-?\d*(?:\.\d*)?$/.test(nextDisplayValue)
    ) {
      if (nextDisplayValue === '') {
        displayValue = '';
        setValue(0);
      } else {
        const [dollars, cents] = nextDisplayValue.split('.');

        if (typeof cents === 'string' && cents.length > 2) {
          const previousCents = displayValue.split('.')[1];

          if (cents.slice(1) === previousCents) {
            displayValue = `${dollars}.${cents[0]}`
          } else {
            displayValue = `${dollars}.${cents.slice(0, 2)}`;
          }
        } else {
          displayValue = nextDisplayValue;
        }
        const nextValue = parseFloat(displayValue) * 100;

        if (Number.isInteger(nextValue)) {
          setValue(nextValue);
        }
      }
      this.setState({ displayValue });
    }
  }

  setControlledValue(value) {
    this.setState({ useControlledValue: value });
  }

  render() {
    const {
      minimum,
      setValue,
      value,
      ...extraProps
    } = this.props;
    const { useControlledValue } = this.state;
    let { displayValue } = this.state;

    if (useControlledValue) {
      displayValue = (value / 100).toFixed(2);
    }
    return (
      <Input
        {...extraProps}
        onBlur={() => {
            this.setControlledValue(true);
            this.setDisplayValue((value / 100).toFixed(2));
        }}
        onChange={event => this.setDisplayValue(event.target.value)}
        onFocus={() => {
            this.setControlledValue(false);
            this.setDisplayValue((value / 100).toFixed(2));
        }}
        style={value < minimum ? {
            borderColor: '#dc3545',
        } : {}}
        type="number"
        value={displayValue}
      />
    );
  }
}
MoneyInput.defaultProps = {
  minimum: 0,
};
MoneyInput.propTypes = {
  minimum: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default MoneyInput;
