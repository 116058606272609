import React from 'react';
import PropTypes from 'prop-types';
import { AvDateRange } from '@availity/reactstrap-validation-date';
import { FaCalendarMinus } from 'react-icons/fa';
import '@availity/reactstrap-validation-date/styles.scss';

const DateSelector = ({ onChange, startDate, endDate, ...rest }) => (
  <AvDateRange
    name="AvDateRange"
    calendarIcon={<FaCalendarMinus />}
    start={{
      name: 'date.start',
      value: startDate.format("MM/DD/YYYY"),
    }}
    end={{ name: 'date.end', value: endDate.format("MM/DD/YYYY") }}
    {...rest}
  />
);

DateSelector.propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default DateSelector;
