import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FaSortAmountDown,
  FaSortAmountUp,
} from 'react-icons/fa';
import { 
  Button,
  ButtonGroup,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
 } from 'reactstrap';
import { 
  AvForm, 
  AvInput

} from 'availity-reactstrap-validation';
import AvSelect from '@availity/reactstrap-validation-select';
import { usePagination } from '@availity/pagination';
import dayjs from 'dayjs'
import DateSelector from '../DateSelector';


const sortOptions = [
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'First Name',
    value: 'otherAgentFirstName',
  },
  {
    label: 'Last Name',
    value: 'otherAgentLastName',
  },
  {
    label: 'Email',
    value: 'otherAgentEmail',
  },
  {
    label: 'Amount',
    value: 'diffAmount',
  },
];


  /* const groupOptions = [
  {
    label: 'Agent',
    value: 'agentID',
  },
  {
    label: 'Day',
    value: 'addedDate',
  },
{
    label: 'Manager',
    value: 'directManager',
  },
  {
    label: 'Role',
    value: 'role',
  },
  {
    label: 'Group',
    value: 'groups',
  },
]; */

const SearchControls = ({ onSearch, searchText: oldSearchText, sortKey, descending, startDate, endDate, leftSelectOptions = [] }) => {
  const { setPage, currentPage } = usePagination();
  const [sort, setSort] = useState(sortKey);
  const [searchText, setSearchText] = useState(oldSearchText);
  const [otherAgentID, setOtherAgentID] = useState();
  const [transferDirection, setTransferDirection] = useState(0);
  const [desc, setDescending] = useState(descending);
  const [mounted, setMounted] = useState();
  // const [lastSearch, setLastSearch] = useState({});

  const search = async payload => {
        const { date } = payload
        const newPayload = {...payload}
        if(!date){
          newPayload.startDate = startDate
          newPayload.endDate = endDate
        }
       // setLastSearch(newPayload)
        onSearch(newPayload)
        if (currentPage !== 1) {
          setPage(1);
        }
  }
  const autoSearch = () => {
    search({
      searchText,
      otherAgentID,
      transferDirection,
      sortKey: sort,
      descending: desc,
    })
  }

  useEffect(
    () => {
      if(mounted){
        const handler = setTimeout(() => {
          autoSearch()
        }, 700);
        return () => {
          clearTimeout(handler);
        };
      }
  
    },
    [searchText] 
  );

  useEffect(
    () => {
      if(mounted){
        autoSearch()
      }else{
        setMounted(true)
      }
     
    },
    [sort, otherAgentID, transferDirection, desc]
  )

  return (
    <AvForm
    //  className="d-flex float-right pb-2 pt-2"
      onValidSubmit={(e, data) => {
        console.log('AvForm search')
        search({
          searchText,
          ...data,
          otherAgentID,
          transferDirection,
          sortKey: sort,
          descending: desc,
          startDate: data.date.start !== "" ? dayjs(data.date.start): undefined,
          endDate: data.date.end !== "" ? dayjs(data.date.end).add(1, 'day'): undefined,
        });
        if (currentPage !== 1) {
          setPage(1);
        }
      }}
    >
  
    <div className="bg-light py-3 px-4 d-flex align-items-center justify-content-between">
    {leftSelectOptions ?
    <div className="d-flex alignt-items-center">
      <InputGroup className="d-flex justify-content-end w-auto">
      <AvSelect
        styles={{
          menuList: provided => ({
            ...provided,
            color: '#232323',
          }),
        }}
        name="otherAgent"
        id="agent-select"
       options={leftSelectOptions}
        onChange={option => setOtherAgentID(option)}
        value={otherAgentID}
        placeholder="Filter By Agent"
        inputProps={{
          width: 200,
        }}
      />
      <InputGroupAddon addonType="prepend"><Button
      onClick={() => setOtherAgentID(null)}
      >Clear</Button></InputGroupAddon>
      </InputGroup>
    </div>: null}




    <ButtonGroup className="w-50 mx-auto">
    <Button
      onClick={() => setTransferDirection(-1)}
      active={transferDirection < 0}
    >
      Outgoing
    </Button>
    <Button
    onClick={() => setTransferDirection(0)}
    active={transferDirection === 0}
  >
    All
  </Button>
    <Button
      onClick={() => setTransferDirection(1)}
      active={transferDirection > 0}
    >
      Incoming
    </Button>

  </ButtonGroup>
    <InputGroup className="d-flex justify-content-end w-auto">
      <AvSelect
        styles={{
          menuList: provided => ({
            ...provided,
            color: '#232323',
          }),
        }}
        name="sort"
        id="sort-select"
        options={sortOptions}
        onChange={option => setSort(option)}
        value={sort}
        placeholder="Sort By"
        inputProps={{
          width: 200,
        }}
      />
      <InputGroupAddon
        style={{ cursor: 'pointer' }}
        onClick={() => setDescending(!desc)}
        addonType="append"
      >
        <InputGroupText>
          {desc ? <FaSortAmountDown /> : <FaSortAmountUp />}
        </InputGroupText>
      </InputGroupAddon>

   
  </InputGroup>
  </div>
  {/* <div className="bg-light py-3 px-4 d-flex align-items-center">

  <InputGroup className="w-50 mx-auto">
  <AvSelect
  className="mr-5"
  styles={{
    menuList: provided => ({
      ...provided,
      color: '#232323',
    }),
  }}
  name="groupBy"
  id="group-select"
  options={groupOptions}
  onChange={option => setGroupBy(option)}
  value={groupBy}
  placeholder="Total By"
  inputProps={{
    width: 200,
  }}
/>
<RangeSelector greater={0} lower={0}/>

</InputGroup>
</div> */}
  <CardBody className="d-flex py-2">
  <AvInput 
        name="searchText"
        placeholder="Search..."
        type="text"
        onChange={e => setSearchText(e.target.value)}
        value={searchText}
        className="mr-2"
      />
    
      <DateSelector startDate={startDate} endDate={endDate} />
      <Button className="ml-2" color="primary">
        Search
      </Button>
      
      
</CardBody>
      
    </AvForm>

  );
};

SearchControls.propTypes = {
  onSearch: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  searchText: PropTypes.string,
  sortKey:PropTypes.string,
  descending:PropTypes.bool, 
  leftSelectOptions: PropTypes.array
};

export default SearchControls;
