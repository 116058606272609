import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import API from '../../../../../shared/api';
import { CenterComponent } from '../../../../../shared/helpers/helpers';
import CopyBlock from '../../../../../shared/components/CopyBlock';

import StoreContext from '../../../../../stores';

import {
  STEP_VENDOR_NAME,
} from '../../../constants';

const ScreenSuccess = ({
  isNew,
  stepState,
  savedVC,
}) => {
  const {
    notificationStore,
  } = React.useContext(StoreContext);

  const [email, setEmail] = React.useState('');
  const [sending, setSending] = React.useState(false);

  const emailIsValid = /[^@]+@[^.]+\..+/.test(email);

  const sendEmail = async () => { // TODO: Add
    const { layerID, vendorID, vendorSourceCampaign } = savedVC
    setSending(true);

    try {
      const {
        data: messageID,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/vendor_campaigns/email',
        { body: {
          toAddresses: [email],
          templateName: 'vendorIntegration',
          templateData: { layerID, vendorID, vendorSourceCampaign },
        } },
      );

      if (success) {
        notificationStore.create('success', `Email successfully sent to ${email}!`);
      } else {
        notificationStore.create('danger', message);
      }
    } catch (error) {
      notificationStore.create('danger', error.message);
    } finally {
      setSending(false);
    }
  };

  return (
    <Form>
      {isNew ? (
        <div>
          <h5>Congratulations on your new vendor link!</h5>
          <br />
          <p>
            {stepState[STEP_VENDOR_NAME].vendorCampaignName} is now listed in your vendor campaign list.
          </p>
        </div>
      ) : (
        <h5>Vendor campaign update successful!</h5>
      )}
      <br />
      <br />
      <p className="text-muted">
        Use the below email field to send your vendor instructions on how to establish the integration.
      </p>
      {sending ? (
        <CenterComponent>
          <Spinner />
        </CenterComponent>
      ) : (
        <>
          <FormGroup>
            <Label for="shareEmail">Email Address</Label>
            <Input
              id="shareEmail"
              invalid={email.length > 0 && !emailIsValid}
              onChange={e => setEmail(e.target.value)}
              placeholder="example@email.com"
              type="email"
              valid={emailIsValid}
              value={email}
            />
            <FormText>(optional)</FormText>
            <FormFeedback>Must be a valid email address</FormFeedback>
          </FormGroup>
          {email.length > 0 && emailIsValid ? (
            <FormGroup>
              <Button
                color="success"
                onClick={sendEmail}
              >
                Send Email
              </Button>
            </FormGroup>
          ) : null}
        </>
      )}
    </Form>
  );
};

ScreenSuccess.propTypes = {
  isNew: PropTypes.bool.isRequired,
  stepState: PropTypes.object.isRequired,
  savedVC: PropTypes.object.isRequired,
};

export default observer(ScreenSuccess);