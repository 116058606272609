import { observer } from 'mobx-react-lite';
import * as Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Progress from 'react-progressbar';
import {
    Button,
    ButtonGroup,
    Table,
} from 'reactstrap';

import CampaignTableRow, { getProgress, getProgressColor } from './CampaignTableRow';

import { formatCurrencyForDisplay } from '../../MyAgents/Utils';

const CampaignTable = observer(({
    campaigns,
    loggedInUserCredit,
    navigate,
    openModal,
    toggleLoadStates,
    togglePauseAll,
    togglePaused,
    toggleResumeAll,
}) => {
    const atleastOneCampaignIsActive = campaigns.filter(campaign => !campaign.paused).length > 0;
    const atleastOneCampaignIsPaused = campaigns.filter(campaign => campaign.paused).length > 0;
    const currentMoment = Moment();
    let totalSpend = 0;
    let maxTotalSpend = 0;
    let maxWeeklySpend = 0;

    campaigns
        .filter(
            campaign => !campaign.paused,
        )
        .forEach(
            campaign => {
                campaign.spendingPeriods.forEach(
                    s => {
                        const [dailySpend, maxSpend] = getProgress(s, currentMoment);

                        totalSpend += dailySpend;
                        maxTotalSpend += maxSpend;

                        [
                            s.dowSun,
                            s.dowMon,
                            s.dowTue,
                            s.dowWed,
                            s.dowThu,
                            s.dowFri,
                            s.dowSat,
                        ].filter(
                            dow => !!dow,
                        ).forEach(() => {
                            maxWeeklySpend += maxSpend;
                        });
                    },
                );
            },
        );
    const progress = totalSpend / maxTotalSpend * 100;

    return (
        <Table
            className="text-right"
            hover
        >
            <thead>
                <tr>
                    <td style={{ padding: '21px 12px' }} className="border-0">
                        <h5>Campaigns</h5>
                    </td>
                    <td className="border-0" />
                    <td className="border-0">
                        {maxTotalSpend > 0 && (
                            <>
                                <div style={{
                                    backgroundColor: 'lightgray',
                                    marginTop: '21px' }}
                                >
                                    <Progress
                                        color={getProgressColor(progress)}
                                        completed={progress}
                                    />
                                </div>
                                {formatCurrencyForDisplay(maxTotalSpend - totalSpend)} remaining today.
                            </>
                        )}
                    </td>
                    <td style={{ padding: '21px 12px' }} className="border-0" colSpan="3">
                        <Button
                            color="success"
                            onClick={() =>  navigate('/account')}
                        >
                            Add Credit
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td style={{
                        paddingTop: '26px',
                        width: '14%',
                    }}>
                        <Button
                            color="primary"
                            onClick={() => navigate(`/campaign/new`)}
                        >
                            New Campaign
                        </Button>
                    </td>
                    <td style={{
                        paddingTop: '26px',
                        width: '16%',
                    }}>
                        <ButtonGroup>
                            <Button
                                color="danger"
                                disabled={!atleastOneCampaignIsActive}
                                onClick={togglePauseAll}
                            >
                                Pause
                            </Button>
                            <Button
                                color="success"
                                disabled={!atleastOneCampaignIsPaused}
                                onClick={toggleResumeAll}
                            >
                                Resume
                            </Button>
                        </ButtonGroup>
                    </td>
                    <td />
                    <td />
                    <td style={{ width: '17%' }}>
                        Max Total Spend
                        <br />
                        <h5>{formatCurrencyForDisplay(maxWeeklySpend)}/wk</h5>
                    </td>
                    <td style={{ width: '14%' }}>
                        Your Credit
                        <br />
                        <h5>{formatCurrencyForDisplay(loggedInUserCredit)}</h5>
                    </td>
                </tr>
                <tr>
                    <td><b>Campaign Name</b></td>
                    <td className="text-center"><b>Running</b></td>
                    <td className="text-left"><b>Active Spending Periods</b></td>
                    <td />
                    <td><b>Max Campaign Spend</b></td>
                    <td />
                </tr>
            </thead>
            <tbody>
                {campaigns.map((campaign, idx) => (
                    <CampaignTableRow
                        id={campaign.campaignID}
                        key={campaign.campaignID}
                        name={campaign.campaignName}
                        navigateToEditCampaignPage={() => navigate(`/campaign/${campaign.campaignID}`)}
                        openModal={openModal}
                        paused={campaign.paused}
                        spendingPeriods={campaign.spendingPeriods}
                        togglePaused={() => togglePaused(idx)}
                        togglePending={toggleLoadStates.has(idx)}
                    />
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>
            </tfoot>
        </Table>
    );
});

CampaignTable.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.shape({
        campaignName: PropTypes.string,
        id: PropTypes.string,
        paused: PropTypes.bool,
        spendingPeriods: PropTypes.arrayOf(PropTypes.shape({
            dailySpendLimit: PropTypes.number,
            dowSun: PropTypes.bool,
            dowMon: PropTypes.bool,
            dowTue: PropTypes.bool,
            dowWed: PropTypes.bool,
            dowThu: PropTypes.bool,
            dowFri: PropTypes.bool,
            dowSat: PropTypes.bool,
            endTime: PropTypes.number,
            id: PropTypes.string,
            startTime: PropTypes.number,
        })),
    })).isRequired,
    loggedInUserCredit: PropTypes.number.isRequired,
    navigate: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    toggleLoadStates: PropTypes.object.isRequired,
    togglePauseAll: PropTypes.func.isRequired,
    togglePaused: PropTypes.func.isRequired,
    toggleResumeAll: PropTypes.func.isRequired,
};

export default CampaignTable;