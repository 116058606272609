import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonGroup,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import API from '../../../shared/api';
import { CenterComponent } from '../../../shared/helpers/helpers';

import StoreContext from '../../../stores';

const ShareEmailModal = ({
  dismiss,
  isOpen,
  id,
}) => {
  const {
    notificationStore,
    vendorCampaignStore,
  } = React.useContext(StoreContext);

  const [email, setEmail] = React.useState('');
  const [sending, setSending] = React.useState(false);
  const currentVendorCampaign = toJS(vendorCampaignStore.list.find(
    vendorCampaign => vendorCampaign.layerID === id,
  ));

  const canDismiss = !sending;
  const emailIsValid = /[^@]+@[^.]+\..+/.test(email);

  const cleanupAndDismiss = () => {
    if (canDismiss) {
      setEmail('');
      dismiss();
    }
  };

  const sendEmail = async () => {
    setSending(true);
    const { layerID, vendorID, vendorSourceCampaign } = currentVendorCampaign

    try {
  
     const {
        data: messageID,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/vendor_campaigns/email',
        { body: {
          toAddresses: [email],
          templateName: 'vendorIntegration',
          templateData: { layerID, vendorID, vendorSourceCampaign },
        } },
      );
      if (success) {
        cleanupAndDismiss();
        notificationStore.create('success', `Email successfully sent to ${email}!`);
      } else {
        notificationStore.create('danger', message);
      }
    } catch (error) {
      notificationStore.create('danger', error.message);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={cleanupAndDismiss}
    >
      <ModalHeader toggle={cleanupAndDismiss}>
        Share Integration Email
      </ModalHeader>
      <ModalBody>
        {sending ? (
          <CenterComponent>
            <Spinner />
          </CenterComponent>
        ) : (
          <>
            <p className="text-muted">
              We sent an email with instructions on how to establish the link with your vendor to the creator of this vendor campaign when it was first created. You may send another copy of these instructions using this form.{/* <a href="/">Read more</a> TODO: Link this to a help page */}
            </p>
            <FormGroup>
              <Label for="shareEmail">Email address</Label>
              <Input
                id="shareEmail"
                invalid={email.length > 0 && !emailIsValid}
                onChange={e => setEmail(e.target.value)}
                placeholder="example@email.com"
                type="email"
                valid={emailIsValid}
                value={email}
              />
              <FormFeedback>Must be a valid email address</FormFeedback>
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            disabled={!canDismiss}
            onClick={cleanupAndDismiss}
          >
            Dismiss
          </Button>
          {emailIsValid && (
            <Button
              color="success"
              onClick={sendEmail}
            >
              Send Email
            </Button>
          )}
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

ShareEmailModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default observer(ShareEmailModal);