import React, { useState, useRef, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import StoreContext from '../../stores';
import Form from './Form';
import SelectedList from './SelectedList';
import LocalTable from '../../shared/components/LocalTable';
import { LoadingComponent } from '../../shared/helpers/helpers';

const Destinations = observer(
  ({
    selectedDestIDs = [],
    selectedAgentDestIDs = [],
    onChange = () => {},
  }) => {
    console.log(selectedDestIDs);
    console.log(selectedAgentDestIDs);
    const {
      userStore: { user, userID, marketplace },
      agentStore,
      agentStore: { agents = [] },
      destinationStore: {
        init: destInit,
        loading: destLoading,
        destinations,
        test: testDestination,
      },
    } = useContext(StoreContext);

    let mounted = useRef(false);

    const [myAgentsModal, setMyAgentsModal] = useState(false);
    const [myDestinationsModal, setMyDestinationsModal] = useState(false);
    const [editDestinationsModal, setEditDestinationsModal] = useState(false);

    const [errorMessage] = useState();
    const [myAgentDestinations, setMyAgentDestinations] = useState([]);
    const [myDestinations, setMyDestinations] = useState([]);

    const [selectedAgentDestinations, setSelectedAgentDestinations] = useState(
      selectedAgentDestIDs
    );
    const [selectedDestinations, setSelectedDestinations] = useState(
      selectedDestIDs
    );

    const [pendingEdit, setPendingEdit] = useState({});

    const toggleMyAgents = () => {
      setMyAgentsModal(!myAgentsModal);
    };

    const toggleMyDestinations = () => {
      setMyDestinationsModal(!myDestinationsModal);
    };

    const toggleEditDestination = () => {
      console.log('toggleEditDestination');
      const temp = !editDestinationsModal;
      setEditDestinationsModal(temp);
      if (!temp) {
        setPendingEdit({});
      }
    };

    const sendTestToDestination = dest => {
      testDestination(dest);
    };

    const editDestination = (destID = '') => {
      const legacyID = destID.split(`${user.agentID}@lookup@`); // need to convert back to just id
      if (legacyID.length === 2) {
        const dest = destinations.filter(d => {
          return d.destID === legacyID[1];
        });
        if (dest.length === 1) {
          setPendingEdit(dest[0]);
          toggleEditDestination();
        }
      } else {
        toggleEditDestination();
      }
    };

    const getLegacyDestID = id => {
      let legacyID = '';
      const destArray = id.split('@');
      if (destArray.length === 3) {
        legacyID = destArray[2];
      }
      return legacyID;
    };

    const extractCRMProfiles = (agent, selectedDest, isSelf) => {
      const destinations = [];
      console.log(agent);
      const { agentID, firstName, lastName, email } = agent;
      let { crmProfiles } = agent;
      console.log(crmProfiles);
      if (typeof crmProfiles === 'string') {
        crmProfiles = JSON.parse(toJS(crmProfiles));
      }
      // const crmProfilesT = JSON.parse(toJS(crmProfiles))

      Object.keys(crmProfiles).forEach(key => {
        console.log(key);
        //    if(key === 'vanillaSoftDirect'){
        const { crmCampaign, label, profiles } = crmProfiles[key] || {};
        console.log(profiles);
        if (!profiles) {
          return;
        }

        profiles.forEach(profile => {
          console.log(profile);
          const { id, description } = profile;

          const selected = selectedDest.includes(
            `${agentID}@${key}@agent@${id}`
          );

          destinations.push({
            label: isSelf
              ? `${label} - ${description || 'No Description'}`
              : `${label} - ${description ||
                  'No Description'} - ${firstName} ${lastName}`,
            searchText: JSON.stringify({
              firstName,
              lastName,
              email,
              type: 'Personal',
            }),
            hoverText: `${email}`,
            icon: 'user',
            disabled: false,
            type: key,
            selected,
            id: `${agentID}@${key}@agent@${id}`,
          });
        });
        if (profiles.length > 0) {
          if (crmCampaign) {
            const selected = selectedDest.includes(
              `${agentID}@${key}@team@${profiles[0].id}`
            );

            destinations.push({
              label: isSelf
                ? `${label} - Team`
                : `${label} - Team - ${firstName} ${lastName}`,
              searchText: JSON.stringify({
                firstName,
                lastName,
                email,
                type: 'Team',
              }),
              type: 'Team',
              hoverText: `${email}`,
              icon: 'team',
              disabled: false,
              selected,
              id: `${agentID}@${key}@team@${profiles[0].id}`,
            });
          }
        }

        // }
      });
      return destinations;
    };

    const makeMyAgents = () => {
      let myAgentDestinations = [];
      const agentsWithCRMProfiles = agents.filter(agent => {
        return agent.crmProfiles;
      });

      agentsWithCRMProfiles.forEach(agent => {
        const destinations = extractCRMProfiles(
          agent,
          selectedAgentDestinations,
          false
        );
        console.log(destinations);
        myAgentDestinations = [...myAgentDestinations, ...destinations];
      });
      console.log(myAgentDestinations);

      setMyAgentDestinations(myAgentDestinations);
    };

    const makeIcon = name => {
      switch (name) {
        case 'url':
          return 'link';
        case 'email':
          return 'email';
        default:
          return '';
      }
    };
    const makeMyDestinations = () => {
      const { agentID, firstName, lastName, email } = user;

      const myDestinations = destinations.map(destination => {
        const {
          destinationName,
          destID,
          destination: destinationEndPoint,
          destinationType,
        } = destination;
        const selected =
          selectedDestinations.includes(`${agentID}@lookup@${destID}`) ||
          selectedDestinations.includes(destID);

        // const selected = selectedDestinations.includes(`${agentID}@lookup@${destID}`)
        return {
          label: destinationName,
          searchText: JSON.stringify({
            firstName,
            lastName,
            email,
            type: 'Personal',
            destinationName,
            destinationEndPoint,
          }),
          hoverText: `${destinationEndPoint}`,
          icon: makeIcon(destinationType),
          disabled: false,
          selected,
          id: `${agentID}@lookup@${destID}`,
        };
      });
      console.log(user);
      const userDestinations = extractCRMProfiles(
        user,
        selectedDestinations,
        true
      );

      console.log('setMyDestinations');
      console.log(myDestinations);

      console.log('userDestinations');
      console.log(userDestinations);
      // setMyDestinations(myDestinations)
      setMyDestinations([...myDestinations, ...userDestinations]);
    };

    const selectedAgentRow = index => {
      const agentDestCopy = myAgentDestinations.slice();
      agentDestCopy[index].selected = !agentDestCopy[index].selected;
      agentDestCopy.forEach(item => {
        if (item.id !== agentDestCopy[index].id) {
          item.selected = false;
        }
      });
      setSelectedAgentDestinations([agentDestCopy[index].id]);
    };

    const selectedDestinationRow = index => {
      console.log('selectedDestinationRow');
      const destsCopy = myDestinations.slice();
      const { id: destID } = destsCopy[index];
      destsCopy[index].selected = !destsCopy[index].selected;

      console.log(destID);

      if (destsCopy[index].selected) {
        const foundDest = selectedDestinations.filter(id => {
          return id === destID || id === `${userID}@lookup@${destID}`;
        });

        if (foundDest.length === 0) {
          console.log(destID);

          if (destID.includes('@agent') || destID.includes('@team')) {
            /* only allow one personal dest to selected. 
          const cleanDest = selectedDestinations.filter(d => {
            return !d.includes('@agent') && !d.includes('@team')
          })*/

            destsCopy.forEach(item => {
              if (item.id.includes('@agent') || item.id.includes('@team')) {
                if (item.id !== destID) {
                  //item.selected = false
                }
              }
            });
            console.log(selectedDestinations);
            setSelectedDestinations([...selectedDestinations, destID]);
          } else {
            console.log(selectedDestinations);
            setSelectedDestinations([...selectedDestinations, destID]);
          }
        }
      } else {
        const updatedDest = selectedDestinations.filter(id => {
          return id !== destID && id !== `${userID}@lookup@${destID}`;
        });

        console.log(updatedDest);

        setSelectedDestinations(updatedDest);
      }
    };

    const deleteDestination = id => {
      const destsCopy = myDestinations.slice();
      const index = destsCopy.findIndex(i => i.id === id);

      destsCopy[index].selected = false;

      setMyDestinations(destsCopy);

      console.log(id);
      /*let legacyID = ''
    const destArray = id.split('@')
    if(destArray.length === 3){
      legacyID = destArray[2]
    }*/

      const updatedDest = selectedDestinations.filter(d => {
        return d !== id && d !== getLegacyDestID(id);
      });

      console.log(updatedDest);

      setSelectedDestinations(updatedDest);
    };

    const deleteAgentDestination = id => {
      const agentDestCopy = myAgentDestinations.slice();
      const index = agentDestCopy.findIndex(i => i.id === id);

      agentDestCopy[index].selected = !agentDestCopy[index].selected;

      setMyAgentDestinations(agentDestCopy);

      const updatedDest = selectedAgentDestinations.filter(d => {
        return d !== id;
      });

      //setSelectedAgentDestinations(updatedDest)
      setSelectedAgentDestinations([]);
    };

    const agentsSearched = searchText => {
      myAgentDestinations.filter(item => {
        if (searchText.length > 3) {
          return JSON.stringify(item).includes(searchText);
        }
        return true;
      });
    };

    const destinationsSearched = searchText => {
      myDestinations.filter(item => {
        if (searchText.length > 3) {
          return JSON.stringify(item).includes(searchText);
        }
        return true;
      });
    };

    useEffect(() => {
      if (agentStore.loading) {
        agentStore.init();
      }
    }, []);

    useEffect(() => {
      if (destLoading) {
        destInit();
      }
    }, []);

    useEffect(() => {
      if (mounted) {
        console.log({
          destinations: selectedDestinations,
          myAgentDestinations: selectedAgentDestinations,
        });
        onChange({
          destinations: selectedDestinations,
          myAgentDestinations: selectedAgentDestinations,
        });
      } else {
        mounted = true;
      }
    }, [selectedDestinations, selectedAgentDestinations]);

    useEffect(() => {
      makeMyAgents();
    }, [agents]);
    useEffect(() => {
      makeMyDestinations();
    }, [destinations]);

    // useEffect(() => {makeMyDestinations()}, [selectedDestIDs]);
    // useEffect(() => {makeMyAgents()}, [selectedAgentDestIDs]);

    return (
      <div className="p-2 border border-white">
        <ButtonGroup className="flex-d mb-4 justify-content-between">
          <Button
            className="mr-4"
            onClick={() => {
              setMyDestinationsModal(true);
            }}
          >
            {' '}
            My Destinations
          </Button>
          <Button
            className="mr-4"
            onClick={() => {
              setMyAgentsModal(true);
            }}
          >
            Agent Destinations
          </Button>
          <Button
            className="mr-4"
            onClick={() => {
              editDestination();
            }}
          >
            Create Destination
          </Button>
        </ButtonGroup>
        <SelectedList
          marketplace={marketplace}
          destinations={myAgentDestinations.filter(d => {
            return d.selected;
          })}
          onDelete={deleteAgentDestination}
        />
        <SelectedList
          marketplace={marketplace}
          destinations={myDestinations.filter(d => {
            return d.selected;
          })}
          onDelete={deleteDestination}
          onEdit={editDestination}
          onTest={sendTestToDestination}
        />
        <Modal isOpen={editDestinationsModal} toggle={toggleEditDestination}>
          <ModalBody>
            <Form
              destination={pendingEdit}
              setSelectedDestination={id =>
                selectedDestinationRow(myDestinations.length - 1)
              }
              toggle={toggleEditDestination}
            />
            <p style={{ color: 'red' }}>{errorMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleEditDestination}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={myAgentsModal} toggle={toggleMyAgents}>
          <ModalHeader toggle={toggleMyAgents}>
            My Agent Destinations
          </ModalHeader>
          <ModalBody>
            <LocalTable
              data={myAgentDestinations}
              groupBy={3}
              rowSelected={selectedAgentRow}
              searched={agentsSearched}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleMyAgents}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={myDestinationsModal} toggle={toggleMyDestinations}>
          <ModalHeader toggle={toggleMyDestinations}>
            My Destinations
          </ModalHeader>
          <ModalBody>
            {destLoading ? (
              <LoadingComponent />
            ) : (
              <LocalTable
                multiSelect
                data={myDestinations}
                groupBy={3}
                pageSize={5}
                rowSelected={selectedDestinationRow}
                searched={destinationsSearched}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleMyDestinations}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
);

Destinations.propTypes = {
  selected: PropTypes.array,
};

export default Destinations;
