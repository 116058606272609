import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { formatCurrencyForDisplay } from '../../MyAgents/Utils';
import MoneyInput from '../../../shared/components/MoneyInput';

import {
  MAX_FALLBACK_TIER_MINUTES,
  MAX_TIER_DESC_LENGTH,
  MAX_TIER_NAME_LENGTH,
  MAX_TIER_PRICE,
  MIN_FALLBACK_TIER_MINUTES,
  MIN_TIER_NAME_LENGTH,
  MIN_TIER_PRICE,
} from '../constants';

const EditTierModal = ({
  baseName,
  basePrice,
  baseFallbackMinutes,
  baseFallbackIncrement,
  baseFallbackTierName,
  baseDescription,
  dismiss,
  editMode,
  id,
  isOpen,
  save,
  tiers,
}) => {
  const tierNames = tiers.map(
    tier => tier.name,
  );

  const isOverflow = baseName === 'Overflow';

  const [name, setName] = React.useState(baseName);
  const [price, setPrice] = React.useState(basePrice);
  const [fallbackMinutes, setFallbackMinutes] = React.useState(baseFallbackMinutes);
  const [fallbackIncrement, setFallbackIncrement] = React.useState(baseFallbackIncrement);
  const [fallbackTierName, setFallbackTierName] = React.useState(baseFallbackTierName);
  const [description, setDescription] = React.useState(baseDescription);
  const [openDropdown, setOpenDropdown] = React.useState(null);

  React.useEffect(() => {
    setName(baseName);
    setPrice(basePrice);
    setFallbackMinutes(baseFallbackMinutes);
    setFallbackIncrement(baseFallbackIncrement);
    setFallbackTierName(baseFallbackTierName);
    setDescription(baseDescription);
  }, [
    baseName,
    basePrice,
    baseFallbackMinutes,
    baseFallbackIncrement,
    baseFallbackTierName,
    baseDescription,
    isOpen,
  ]);

  const isValidFallback = potentialFallbackTierName => {
    if (isOverflow || potentialFallbackTierName === baseName) {
      return false;
    } else if (potentialFallbackTierName === 'Overflow') {
      return true;
    }
    const fallbackTierIdx = tiers.findIndex(
      tier => tier.name === potentialFallbackTierName,
    );

    return fallbackTierIdx === -1 ? false : isValidFallback(tiers[fallbackTierIdx].fallbackTierName);
  };

  const nameUnique = !tierNames.includes(name);
  const nameLongEnough = name.length >= MIN_TIER_NAME_LENGTH;
  const nameShortEnough = name.length <= MAX_TIER_NAME_LENGTH;
  const nameValid = nameUnique && nameLongEnough && nameShortEnough;
  const priceHighEnough = price >= MIN_TIER_PRICE;
  const priceLowEnough = price <= MAX_TIER_PRICE;
  const priceValid = priceHighEnough && priceLowEnough;
  const fallbackTierMinutesHighEnough = fallbackMinutes >= MIN_FALLBACK_TIER_MINUTES;
  const fallbackTierMinutesLowEnough = fallbackMinutes <= MAX_FALLBACK_TIER_MINUTES;
  const fallbackTierIsNonCircular = isValidFallback(fallbackTierName);
  const fallbackTierValid = isOverflow || (fallbackTierMinutesHighEnough && fallbackTierMinutesLowEnough && fallbackTierIsNonCircular);
  const descShortEnough = description.length <= MAX_TIER_DESC_LENGTH;
  const descValid = descShortEnough;

  let nameFeedback;
  let priceFeedback;
  let fallbackTierFeedback;
  let descFeedback;

  if (name === baseName) {
    nameFeedback = '';
  } else if (nameValid) {
    nameFeedback = 'Tier name is valid.';
  } else if (!nameUnique) {
    nameFeedback = 'Tier name is taken.';
  } else if (!nameLongEnough) {
    nameFeedback = `Tier name must be at least ${MIN_TIER_NAME_LENGTH} characters.`;
  } else {
    nameFeedback = `Tier name must be at most ${MAX_TIER_NAME_LENGTH} characters.`;
  }

  if (price === basePrice) {
    priceFeedback = '';
  } else if (priceValid) {
    priceFeedback = 'Tier price is valid.';
  } else if (!priceHighEnough) {
    priceFeedback = `Tier price must be at least ${formatCurrencyForDisplay(MIN_TIER_PRICE)}.`
  } else {
    priceFeedback = `Tier price must be at most ${formatCurrencyForDisplay(MAX_TIER_PRICE)}.`;
  }

  if (
    fallbackMinutes === baseFallbackMinutes &&
    fallbackTierName === baseFallbackTierName
  ) {
    fallbackTierFeedback = '';
  } else if (fallbackTierValid) {
    fallbackTierFeedback = 'Fallback tier is valid.';
  } else if (!fallbackTierMinutesHighEnough) {
    fallbackTierFeedback = `Fallback tier timeout must be at least ${MIN_FALLBACK_TIER_MINUTES}.`;
  } else if (!fallbackTierMinutesLowEnough) {
    fallbackTierFeedback = `Fallback tier timeout must be at most ${MAX_FALLBACK_TIER_MINUTES}.`;
  } else {
    fallbackTierFeedback = `Falling back to ${fallbackTierName} would create a loop, which is not allowed.`;
  }

  if (description === baseDescription) {
    descFeedback = '';
  } else if (descValid) {
    descFeedback = 'Tier description is valid.';
  } else {
    descFeedback = `Tier description must be at most ${MAX_TIER_DESC_LENGTH} characters.`;
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={dismiss}
    >
      <ModalHeader toggle={dismiss}>
        {editMode ?
          `Edit ${name}` :
          'New Tier'
        }
      </ModalHeader>
      <ModalBody>
        {<FormGroup>
          <Label for="newTierName">Name</Label>
          <Input
            disabled={editMode}
            onChange={e => setName(e.target.value)}
            id="newTierName"
            invalid={nameFeedback !== '' && !nameValid}
            name="newTierName"
            valid={nameFeedback !== '' && nameValid}
            value={name}
          />
          <FormFeedback>{nameFeedback}</FormFeedback>
        </FormGroup>}
        <FormGroup>
          <Label for="newTierPrice">Price</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
            <MoneyInput
              id="newTierPrice"
              invalid={priceFeedback !== '' && !priceValid}
              maximum={MAX_TIER_PRICE}
              minimum={MIN_TIER_PRICE}
              name="newTierPrice"
              setValue={setPrice}
              valid={priceFeedback !== '' && priceValid}
              value={editMode && price === 0 ? basePrice : price}
            />
            <FormFeedback>{priceFeedback}</FormFeedback>
          </InputGroup>
        </FormGroup>
        {!isOverflow && (
          <FormGroup>
            <Label for="newTierFallback">Fallback Tier</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">After</InputGroupAddon>
              <Input
                id="newTierFallback"
                invalid={fallbackTierFeedback !== '' && !fallbackTierValid}
                onChange={e => setFallbackMinutes(e.target.value)}
                type="number"
                valid={fallbackTierFeedback !== '' && fallbackTierValid}
                value={fallbackMinutes}
              />
              <InputGroupButtonDropdown
                addonType="append"
                isOpen={openDropdown === 'increment'}
                toggle={() => setOpenDropdown(openDropdown === 'increment' ? null : 'increment')}
              >
                <DropdownToggle caret>
                  {fallbackIncrement}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setFallbackIncrement('seconds')}>seconds</DropdownItem>
                  <DropdownItem onClick={() => setFallbackIncrement('minutes')}>minutes</DropdownItem>
                  <DropdownItem onClick={() => setFallbackIncrement('hours')}>hours</DropdownItem>
                  <DropdownItem onClick={() => setFallbackIncrement('days')}>days</DropdownItem>
                </DropdownMenu>
              </InputGroupButtonDropdown>
              <InputGroupAddon addonType="append">unsold, sell as</InputGroupAddon>
              <InputGroupButtonDropdown
                addonType="append"
                isOpen={openDropdown === 'tierSelect'}
                toggle={() => setOpenDropdown(openDropdown === 'tierSelect' ? null : 'tierSelect')}
              >
                <DropdownToggle caret>
                  {fallbackTierName}
                </DropdownToggle>
                <DropdownMenu>
                  {tierNames.filter(
                    tierName => tierName !== baseName,
                  ).map(
                    tierName => (
                      <DropdownItem
                        key={tierName}
                        onClick={() => setFallbackTierName(tierName)}
                        title={tierName}
                      >
                        {tierName}
                      </DropdownItem>
                    ),
                  )}
                </DropdownMenu>
              </InputGroupButtonDropdown>
              <FormFeedback>{fallbackTierFeedback}</FormFeedback>
            </InputGroup>
          </FormGroup>
        )}
        <FormGroup>
          <Label for="newTierDescription">Description</Label>
          <Input
            id="newTierDescription"
            invalid={descFeedback !== '' && !descValid}
            name="newTierDescription"
            onChange={e => setDescription(e.target.value)}
            rows="5"
            style={{ resize: 'none' }}
            type="textarea"
            valid={descFeedback !== '' && descValid}
            value={description}
          />
          <FormText color="muted">(optional)</FormText>
          <FormFeedback>{descFeedback}</FormFeedback>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          {priceValid && fallbackTierValid && descValid && (editMode ? name === baseName && (price !== basePrice || fallbackIncrement !== baseFallbackIncrement || fallbackMinutes !== baseFallbackMinutes || fallbackTierName !== baseFallbackTierName || description !== baseDescription) : nameValid) && (
            <Button
              color="success"
              onClick={() => save({
                description,
                fallbackMinutes,
                fallbackIncrement,
                fallbackTierName,
                id,
                name,
                price,
                vertical: 'health', // TODO: Remove hardcoding
              })}
            >
              {editMode ? 'Update' : 'Create'} Tier
            </Button>
          )}
          <Button
            color="secondary"
            onClick={dismiss}
            outline
          >
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

EditTierModal.defaultProps = {
  id: undefined,
};

EditTierModal.propTypes = {
  baseName: PropTypes.string.isRequired,
  basePrice: PropTypes.number.isRequired,
  baseFallbackMinutes: PropTypes.number,
  baseFallbackIncrement: PropTypes.oneOf(['seconds', 'minutes', 'hours', 'days']),
  baseFallbackTierName: PropTypes.string,
  baseDescription: PropTypes.string.isRequired,
  dismiss: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  id: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  tiers: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    fallbackMinutes: PropTypes.number,
    fallbackTierName: PropTypes.string,
    id: PropTypes.string.isRequired,
    marketplace: PropTypes.oneOf(['usha']).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    vertical: PropTypes.oneOf(['health']).isRequired,
  })).isRequired,
};

export default EditTierModal;