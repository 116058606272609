import ActivityStore from './activityStore';
import CampaignStore from './campaignStore';
import CreditStore from './creditStore';
import NotificationStore from './notificationStore';
import UserStore from './userStore';
import AgentStore from './agentStore';
import TableStore from './tableStore';
import AccountStore from './accountStore';
import DestinationStore from './destinationStore';
import NetworkStore from './networkStore';
import PriceTierStore from './priceTierStore';
import VendorStore from './vendorStore';
import VendorCampaignStore from './vendorCampaignStore';

export default class RootStore {
  constructor() {
    this.activityStore = new ActivityStore();
    this.campaignStore = new CampaignStore();
    this.creditStore = new CreditStore();
    this.userStore = new UserStore();
    this.notificationStore = new NotificationStore(
      this.campaignStore,
      this.userStore
    );
    this.agentStore = new AgentStore(this.notificationStore);
    this.tableStore = new TableStore();
    this.accountStore = new AccountStore(this.userStore, this.creditStore);
    this.destinationStore = new DestinationStore(
      this.userStore,
      this.creditStore,
      this.notificationStore
    );
    this.networkStore = new NetworkStore(this.notificationStore);
    this.priceTierStore = new PriceTierStore(this.notificationStore);
    this.vendorStore = new VendorStore(this.notificationStore);
    this.vendorCampaignStore = new VendorCampaignStore(this.notificationStore);
  }
}
