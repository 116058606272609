import React from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardLink,
  CardText,
  CardTitle,
} from 'reactstrap';

const Page404 = () => (
  <Card>
    <CardBody>
      <CardTitle>Page not found</CardTitle>
      <CardText>This page cannot be found.</CardText>
    </CardBody>
    <CardFooter>
      <CardLink href="/">Return to home page</CardLink>
    </CardFooter>
  </Card>
);

export default Page404;