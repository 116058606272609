import {
  action,
  computed,
  decorate,
  observable,
} from 'mobx';

import API from '../shared/api';

class VendorStore {
  list = [];

  synced = false;

  constructor(notificationStore) {
    this.notificationStore = notificationStore;
  }

  async load() {
    this.synced = false;

    try {
      const {
        data: vendors,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/vendors/list',
        { body: {} },
      );
  
      if (success) {
        this.list = vendors;
        this.synced = true;
      } else {
        this.notificationStore.create('danger', message);
      }
    } catch (error) {
      this.notificationStore.create('danger', error.message);
    }
  }

  get nameById() {
    return vendorID => {
      return (this.list.find(
        vendor => vendor.vendorID === vendorID,
      ) || {}).vendorName;
    };
  }
}

decorate(VendorStore, {
  list: observable,
  synced: observable,
  load: action,
  nameById: computed,
});

export default VendorStore;