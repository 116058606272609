import { action, decorate, observable } from 'mobx';

import API from '../shared/api';
import { listen } from '../socket';

class CreditStore {
  credit = null;

  loading = false;

  syncing = false

  constructor() {
    listen('credit', ({ newCredit }) => {
      this.set(newCredit);
    });
  }

  set(credit) {
    this.credit = credit;
  }

  syncCredit = async () => {
    this.syncing = true;
    this.set(
      await API.post(process.env.REACT_APP_API, '/campaign/money/credit', {
        body: {},
      })
    );
    this.syncing = false;
  };

  initCredit = async () => {
    this.loading = true;

    try {
      this.set(
        await API.post(process.env.REACT_APP_API, '/campaign/money/credit', {
          body: {},
        })
      );
    } finally {
      this.loading = false;
    }
  };
}

decorate(CreditStore, {
  credit: observable,
  loading: observable,
  set: action,
  syncCredit: action,
  initCredit: action,
  syncing: observable
});

export default CreditStore;
