import {
  action,
  computed,
  decorate,
  observable,
  toJS,
} from 'mobx';
import moment from 'moment';

import API from '../shared/api';

const wrapNumber = (number, wrap) => number < 0 ? (wrap + number) % wrap : number % wrap;

export const localizeSpendingPeriod = (spendingPeriod, utcOffsetMinutes) => {
  const nextSpendingPeriod = Object.assign({}, spendingPeriod, {
    endTime: wrapNumber(spendingPeriod.endTime + utcOffsetMinutes, 1440),
    startTime: spendingPeriod.startTime + utcOffsetMinutes,
  });

  if (
    nextSpendingPeriod.startTime >= 1440 ||
    nextSpendingPeriod.startTime < 0
  ) {
    let dowSun;
    let dowMon;
    let dowTue;
    let dowWed;
    let dowThu;
    let dowFri;
    let dowSat;

    if (nextSpendingPeriod.startTime >= 1440) {
      [dowSun, dowMon, dowTue, dowWed, dowThu, dowFri, dowSat] = [
        spendingPeriod.dowSat,
        spendingPeriod.dowSun,
        spendingPeriod.dowMon,
        spendingPeriod.dowTue,
        spendingPeriod.dowWed,
        spendingPeriod.dowThu,
        spendingPeriod.dowFri,
      ];
    } else {
      [dowSun, dowMon, dowTue, dowWed, dowThu, dowFri, dowSat] = [
        spendingPeriod.dowMon,
        spendingPeriod.dowTue,
        spendingPeriod.dowWed,
        spendingPeriod.dowThu,
        spendingPeriod.dowFri,
        spendingPeriod.dowSat,
        spendingPeriod.dowSun,
      ];
    }
    nextSpendingPeriod.dowSun = dowSun;
    nextSpendingPeriod.dowMon = dowMon;
    nextSpendingPeriod.dowTue = dowTue;
    nextSpendingPeriod.dowWed = dowWed;
    nextSpendingPeriod.dowThu = dowThu;
    nextSpendingPeriod.dowFri = dowFri;
    nextSpendingPeriod.dowSat = dowSat;
    nextSpendingPeriod.startTime = wrapNumber(nextSpendingPeriod.startTime, 1440);
  }
  return nextSpendingPeriod;
};

const localizeCampaigns = (campaigns, utcOffsetMinutes) => campaigns.map(
  c => Object.assign({}, c, {
    spendingPeriods: c.spendingPeriods.map(
      s => localizeSpendingPeriod(s, utcOffsetMinutes),
    ),
  }),
);

const delocalizeCampaigns = (campaigns, utcOffsetMinutes) => localizeCampaigns(campaigns, -utcOffsetMinutes);

class CampaignStore {
  list = [];

  loading = false;

  saving = false;

  pauseTogglesLoading = new Set();

  addSpendingPeriodFunds = async (campaignID, spendingPeriodID, amount, epoch, spend, delaySpendBy) => {
    this.list = toJS(this.list).map(
      campaign => campaign.campaignID === campaignID ?
        Object.assign({}, campaign, {
          spendingPeriods: campaign.spendingPeriods.map(
            spendingPeriod => spendingPeriod.id === spendingPeriodID ?
              Object.assign({}, spendingPeriod, {
                lastPurchaseEpoch: epoch,
                lastPurchasePeriodSpend: spend, 
                delaySpendBy
              }) :
              spendingPeriod,
          ),
        }) :
        campaign,
    );
  };

  deleteCampaign = async campaignID => {

    try{
      const data = await API.post(
        process.env.REACT_APP_API,
        '/campaign/remove',
        {
        body: {
          campaignID,
        },
      },
      );
      this.list = this.list.filter(item => {
          return item.campaignID !== campaignID
      })
  
   //   this.list = localizeCampaigns(data, moment().utcOffset());
    }finally{
    this.loading = false;
    }

    // TODO: Figure out why setting this.list to the response crashes the app so
    //       we can remove this reload.
   // window.location.reload();
  };

  load = async () => {
    this.loading = true;

    try {
      const data = await API.post(
        process.env.REACT_APP_API,
        '/campaign/all',
        { body: {} },
      );
      this.list = localizeCampaigns(data, moment().utcOffset());
    } finally {
      this.loading = false;
    }
  };

  get ready() {
    return !this.loading && !this.saving;
  }

  save = async campaigns => {
    this.saving = true;

    try {
      await API.post(
        process.env.REACT_APP_API,
        '/campaign/update',
        {
          body: {
            campaigns: delocalizeCampaigns(campaigns, moment().utcOffset()),
          },
        },
      );

      this.list = this.list.map(
        campaign => {
          const newCampaign = campaigns.find(
            c => c.campaignID === campaign.campaignID,
          );

          return newCampaign || campaign;
        },
      );
    } finally {
      this.saving = false;
    }
  };

  setSpendingPeriods = (campaignIdx, periods) => {
    this.list[campaignIdx].spendingPeriods = periods;
  };

  togglePause = async campaignIdx => {
    this.pauseTogglesLoading.add(campaignIdx);

    const paused = !this.list[campaignIdx].paused;

    await API.post(
      process.env.REACT_APP_API,
      '/campaign/update',
      {
        body: {
          campaigns: delocalizeCampaigns([Object.assign({}, this.list[campaignIdx], {
            paused,
          })], moment().utcOffset()),
        },
      },
    );

    this.list[campaignIdx].paused = paused;
    this.pauseTogglesLoading.delete(campaignIdx);
  };
}

decorate(CampaignStore, {
  addSpendingPeriodFunds: action,
  deleteCampaign: action,
  list: observable,
  loading: observable,
  load: action,
  pauseTogglesLoading: observable,
  ready: computed,
  save: action,
  saving: observable,
  setSpendingPeriods: action,
  togglePause: action,
});

export default CampaignStore;