export const MS_PER_DAY = 86400000;

const MS_PER_YEAR = MS_PER_DAY * 365;

export const MS_PER_99_YEARS = MS_PER_YEAR * 99;
export const STEP_CLEAN_NAME = 'Clean';
export const STEP_DELIVERY_NAME = 'Delivery';
export const STEP_QUALITY_NAME = 'Quality';
export const STEP_REJECT_NAME = 'Reject';
export const STEP_VENDOR_NAME = 'Vendor';
export const DEFAULT_STEP_STATE = {
  [STEP_CLEAN_NAME]: {
    checkForDuplicates: true,
    dupeSubmissionMode: 'block',
    phoneDupeDays: 30,
    phoneDupeMode: 'forever',
  },
  [STEP_QUALITY_NAME]: {
    leadQualityTier: 'enhance_profile_check_phone',
  },
  [STEP_REJECT_NAME]: {
    acceptsTermsAndConditions: false,
    rejectDisconnected: false,
    rejectDuplicates: false,
  },
  [STEP_VENDOR_NAME]: {
    defaultLeadPrice: 0,
    selectedVendor: null,
    useDefaultPrice: false,
    vendorCampaignName: '',
  },
  [STEP_DELIVERY_NAME]: {
    destinationIDs: [],
    myAgentDestinationIDs: [],
  },
};
export const MAX_FALLBACK_TIER_MINUTES = 1440;
export const MAX_TIER_DESC_LENGTH = 256;
export const MAX_TIER_NAME_LENGTH = 32;
export const MAX_TIER_PRICE = 1000000;
export const MIN_FALLBACK_TIER_MINUTES = 1;
export const MIN_TIER_NAME_LENGTH = 4;
export const MIN_TIER_PRICE = 0;
export const PHONE_DUPE_MAX_DAYS = 365;
export const PHONE_DUPE_MIN_DAYS = 1;