import React from "react";
import PropTypes from "prop-types";
import { SignIn } from "aws-amplify-react";
import { Button, Card, CardTitle } from "reactstrap";
import LoadingButton from "@gopro16/button";
import BlockUI from 'react-block-ui';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { CenterComponent } from "../shared/helpers/helpers";

// const SignIn = ({ onValidSubmit, onInvalidSubmit }) => (
class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn"];

    this.state = {
      loading: false
    };
  }

  showComponent() {
    return (
      <CenterComponent>
        <Card body style={{ maxWidth: 500 }}>
          <CardTitle
            tag="h4"
            className="text-center pr-3 pl-3 border-bottom text-primary pb-2"
          >
            Sign in to Your Account
          </CardTitle>
          <AvForm
            onSubmit={() => this.setState({ loading: true })}
            onInvalidSubmit={() => this.setState({loading:false})}
            onValidSubmit={async event => {
              await this.signIn(event);

              this.setState({loading: false});
            }}
          >
          <BlockUI blocking={this.state.loading}>
            <AvField
              id="username"
              key="username"
              onChange={event => {
                const { target: { value } } = event
                event.target.value = value.toLowerCase()
                this.handleInputChange(event)
              }}
              name="username"
              type="email"
              placeholder="Email"
              label="Email"
              errorMessage="Please provide a valid email"
              validate={{
                required: { value: true, errorMessage: "Please enter an email" }
              }}
            />
            <AvField
              name="password"
              type="password"
              placeholder="Password"
              onChange={this.handleInputChange}
              label="Password"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter a Password"
                },
                minLength: {
                  value: 6,
                  errorMessage: "Your password must be more than 6 characters"
                }
              }}
            />
            </BlockUI>
            <LoadingButton
              loading={this.state.loading}
              disabled={this.state.loading}
              color="primary"
              size="lg"
              block
            >
              Sign In
            </LoadingButton>
          </AvForm>
          <div className="d-flex flex-column pt-2">
            <span>
              Forgot your password?{" "}
              <Button
                color="link"
                onClick={() => this.changeState("forgotPassword")}
              >
                Reset Password
              </Button>
            </span>
             <span>
            No Account?{" "}
              <Button color="link" onClick={() => this.changeState("signUp")}>
                Create Account
              </Button>
             </span>
          </div>
          <Button
          color="link"
          onClick={() => window.open('https://sonic.helpscoutdocs.com/', "_blank") }
        >
          Help Guides
        </Button>
        </Card>
        
      </CenterComponent>
    );
  }
}

CustomSignIn.propTypes = {
  onValidSubmit: PropTypes.func,
  onInvalidSubmit: PropTypes.func
};

export default CustomSignIn;
