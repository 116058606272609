import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import VanillaSoft from './VanillaSoft';
import ISalesCRM from './ISalesCRM';

import StoreContext from '../../../stores';

const CRMProfiles = ({availibleCRMs, role = 'user', profiles = {}, onChange, allowSelfEdit, canEdit = false}) => {
  const {
    networkStore,
    userStore,
  } = React.useContext(StoreContext);

  const { vanillaSoftDirect = {}, iSalesCRM = {} } = profiles

  const [activeTab, setActiveTab] = useState();  
  console.log(activeTab)
  console.log(profiles)

  /*
  useEffect(() => {
    const active = Object.keys(profiles).sort((a, b) => (a < b) ? 1 : -1)[0]
    setActiveTab(active)
 }, [profiles]);*/


  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  const makeTabs = () => {
    return Object.keys(availibleCRMs).map(key => {
      return (
        <NavItem key={key}>
        <NavLink
          className={classnames({ active: activeTab === key })}
          onClick={() => { toggle(key); }}
        >
         {availibleCRMs[key].label}
        </NavLink>
      </NavItem>)

    }).sort((a, b) => (a.label > b.label) ? 1 : -1)
  }
  const crmUpdated = (crmID, key, value) => {
      const updated = {...profiles}
      console.log(availibleCRMs)
      console.log(key)
      console.log(crmID)
      if(crmID in updated){
        updated[crmID][key] = value
      }else{
        updated[crmID] = {
          label: availibleCRMs[crmID].label,
          [key]:value
        }
      }
      onChange(updated)
  }

  const makeCRMFields = () => {
    return Object.keys(availibleCRMs).map(key => {
      if(key === 'vanillaSoftDirect'){
        console.log(vanillaSoftDirect)
       
        return (
          <TabPane key={key} tabId={key}>
            <VanillaSoft
              canEdit={canEdit}
              crmID={key}
              onChange={crmUpdated} 
              role={role}
              {...vanillaSoftDirect}
            />
         </TabPane>)
      }else if (key === 'iSalesCRM'){
        console.log(iSalesCRM)
        return (
          <TabPane key={key} tabId={key}>
           <ISalesCRM canEdit={canEdit} allowSelfEdit={allowSelfEdit} role={role} crmID={key} onChange={crmUpdated} {...iSalesCRM}/>
         </TabPane>)

      }
      return (
       <TabPane tabId={key}>
         <h1>{key}</h1>
      </TabPane>)
    })
  }

  useEffect(() => {
    networkStore.load(userStore.user.networkID);
  }, []);

  const networkFields = toJS(userStore.user.network.availibleCRMs.vanillaSoftDirect.fields);
  const requiredNetworkFields = Object.keys(networkFields).filter(
    fieldProp => networkFields[fieldProp].required,
  );

  return (
    <div>
    <h5 style={{}}>CRM Profiles</h5>
      <Nav tabs>
      {makeTabs()}
      </Nav>
      <TabContent activeTab={activeTab} className="mt-3">
        {makeCRMFields()}
      </TabContent>
      {/*canEdit && (
        <Button
          block
          className="mt-3"
          color="primary"
          disabled={
            vanillaSoftDirect &&
            vanillaSoftDirect.profiles &&
            vanillaSoftDirect.profiles.find(
              profile => !profile.description ||
                requiredNetworkFields.find(
                  requiredField => !(profile.crmFields || {})[requiredField],
                ),
            ) !== undefined}
        //  onClick={save}
        >
          Save
        </Button>
                )*/}
    </div>
  );
}



CRMProfiles.propTypes = {
    profiles: PropTypes.object.isRequired,
    availibleCRMs: PropTypes.object.isRequired,
    onChange:  PropTypes.func.isRequired,
    role:  PropTypes.string,
    canEdit: PropTypes.bool,
    save:  PropTypes.func,
  };

export default observer(CRMProfiles);
  