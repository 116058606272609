import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { DndContext, useDrop } from 'react-dnd';

import VendorCampaignBadge from './VendorCampaignBadge';

const VendorCampaignBox = ({
  assignVendorCampaignToTier,
  removeVendorCampaignFromTier,
  tierName,
  vendorCampaigns,
}) => {
  const vendorCampaignNames = vendorCampaigns.map(
    vendorCampaign => vendorCampaign.name,
  );

  const { dragDropManager: { monitor } } = React.useContext(DndContext);

  const [{ isOver }, drop] = useDrop({
    accept: ['VENDOR_CAMPAIGN_BADGE', 'VENDOR_CAMPAIGN_BADGE_REMOVABLE'],
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
    drop: item => {
      if (item.valid && !vendorCampaignNames.includes(item.name)) {
        assignVendorCampaignToTier(item.vendorCampaignName, tierName);
      }
    },
  });

  const alreadyHasVendorCampaign = isOver && vendorCampaignNames.includes(monitor.getItem().vendorCampaignName);

  return (
    <td
      ref={drop}
      style={isOver && !alreadyHasVendorCampaign ? {
        backgroundColor: 'lightgray',
        border: 'none',
        outline: '4px dashed gray',
        outlineOffset: '-4px',
      } : {
        border: 'none',
      }}
    >
      {vendorCampaigns.length === 0 && !isOver ?
        'No assigned vendor campaigns.' :
        vendorCampaigns.map(vendorCampaign => (
          <VendorCampaignBadge
            canRemove={tierName !== 'Overflow'}
            key={vendorCampaign.name}
            removeVendorCampaignFromTier={() => removeVendorCampaignFromTier(vendorCampaign.name, tierName)}
            tierName={tierName}
            valid={vendorCampaign.valid}
            vendorCampaignName={vendorCampaign.name}
          />
      ))}
      {isOver && !alreadyHasVendorCampaign && (
        <span 
          className="badge badge-pill"
          style={{
            backgroundColor: 'gray',
            color: 'gray',
            height: '30px',
          }}
        >
          <span style={{
            display: 'inline-block',
            maxWidth: '153px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}>
            {monitor.getItem().vendorCampaignName}
          </span>
          <Button style={{
            backgroundColor: 'inherit',
            border: 'none',
            padding: 0,
            verticalAlign: 'inherit',
          }}>
            &ensp;×
          </Button>
        </span>
      )}
    </td>
  );
};

VendorCampaignBox.propTypes = {
  assignVendorCampaignToTier: PropTypes.func.isRequired,
  removeVendorCampaignFromTier: PropTypes.func.isRequired,
  tierName: PropTypes.string.isRequired,
  vendorCampaigns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
  })).isRequired,
};

export default VendorCampaignBox;