import { decorate, observable, action } from 'mobx';
import publicIp from 'public-ip';
import API from '../shared/api';

class AccountStore {
  constructor(userStore, creditStore) {
    this.userStore = userStore;
    this.creditStore = creditStore;
  }

  card = undefined;

  userIP = 'unavailable';

  charges = [];

  errorMessage = undefined;

  loading = true;

  creditModal = false;

  cardModal = false;

  init = async () => {
    this.loadCustomerCard();
    this.getUserIP();
    this.getCustomerCharges();
  };

  getUserIP = async () => {
    try {
      const ip = await publicIp.v4();

      this.userIP = ip;
    } catch (error) {
      console.error(error.stack);
    }
  };

  getCustomerCharges = async () => {
    try {
      const response = await API.post(
        process.env.REACT_APP_API,
        `/campaign/payments/charges`,
        { body: {} }
      );
      const { data: charges } = response.data;
      if ('success' in response) {
        this.charges = charges;
      }
    } catch (error) {
      console.error(error.stack);
    }
  };

  loadCustomerCard = async () => {
    try {
      const response = await API.post(
        process.env.REACT_APP_API,
        '/campaign/payments/customer',
        { body: {} }
      );

      if ('success' in response) {
        const {
          data: { sources },
        } = response;

        if (sources) {
          const { data: cards } = sources;

          this.card = cards[0];
        }
      }
    } catch (error) {
      console.error(error.stack);
    } finally {
      this.loading = false;
    }
  };

  addCustomCredit = async amount => {
    try {
      const body = {
        ...this.card,
        userIP: this.userIP,
        amount,
      };
      const response = await API.post(
        process.env.REACT_APP_API,
        `/campaign/payments/charge`,
        { body }
      );

      if ('success' in response) {
        if(response.success){
            this.creditStore.syncCredit();
            this.creditModal = false;
            this.getCustomerCharges();
        }
      }
  
        const { message } = response;
        this.errorMessage = message;
 
    } catch (error) {
      const { message } = error;
      console.error(error.stack);
      this.errorMessage = message;
    }
  };

  addCustomerCard = async body => {
    try {
      const response = await API.post(
        process.env.REACT_APP_API,
        '/campaign/payments/new',
        { body }
      );

      if ('success' in response) {
        if(response.success){
          const {
            data: { sources },
          } = response;
          if (sources) {
            const { data: cards } = sources;
            this.card = cards[0];
            this.cardModal = false;
            this.getCustomerCharges();
          }
        }
      }
        
      const { message } = response;
      this.errorMessage = message;
   
    } catch (error) {
      console.error(error.stack);
      this.errorMessage = error.message;
    }
  };

   toggleCardModal = () => {
    this.cardModal = !this.cardModal;

    if (!this.cardModal) {
      this.errorMessage = undefined;
    }
  };

  toggleCreditModal = () => {
    this.creditModal = !this.creditModal;

    if (!this.creditModal) {
      this.errorMessage = undefined;
    }
  };
}

decorate(AccountStore, {
  card: observable,
  updating: observable,
  loading: observable,
  cards: observable,
  charges: observable,
  errorMessage: observable,
  creditModal: observable,
  cardModal: observable,
  init: action,
  addCustomCredit: action,
  addCustomerCard: action,
  toggleCreditModal: action,
  toggleCardModal: action
});

export default AccountStore;
