import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';

import { CenterComponent } from '../../../shared/helpers/helpers';
import StoreContext from '../../../stores';

const DeleteCampaignModal = ({
  dismiss,
  id,
  name,
}) => {
  const {
    campaignStore,
  } = React.useContext(StoreContext);

  const cleanDismiss = () => {
    if (campaignStore.ready) {
      dismiss();
    }
  };

  // TODO: Add error handling here
  const deleteCampaign = () => {
    campaignStore.deleteCampaign(id);
    dismiss()
  };

  return (
    <Modal
      isOpen
      toggle={cleanDismiss}
    >
      <ModalHeader toggle={cleanDismiss}>
        Confirm campaign deletion
      </ModalHeader>
      <ModalBody>
        {campaignStore.ready ? (
          <span>Are you sure you want to delete {name}?</span> 
        ) : (
          <CenterComponent>
            <Spinner />
          </CenterComponent>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button
            disabled={!campaignStore.ready}
            onClick={cleanDismiss}
          >
            Dismiss
          </Button>
          <Button
            color="danger"
            disabled={!campaignStore.ready}
            onClick={deleteCampaign}
          >
            Delete campaign
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

DeleteCampaignModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default observer(DeleteCampaignModal);