import { FaSync } from 'react-icons/fa';
import PropTypes from 'prop-types';
import React from 'react';
import Switch from 'react-switch';

const Toggle = ({
  active,
  loading,
  onToggle,
}) => loading ? (
  <FaSync
    className="loading-sync"
    size={24}
  />
) : (
  <Switch
    checked={active}
    onChange={() => onToggle()}
  />
);

Toggle.defaultProps = {
  loading: false,
};
Toggle.propTypes = {
  active: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

export default Toggle;