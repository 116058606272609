import React, { useState, useEffect, useContext } from "react"; 
import BootstrapTable from 'react-bootstrap-table-next'; 
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'; 
import PropTypes from 'prop-types'; 
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'; 
import StoreContext from '../../stores'; 




const Levels = props => { 
  const { options, handleSelection, selected, tiers: allowedLeadTiers } = props 
  const { userStore: { user } } = useContext(StoreContext);
  // const { allowedLeadTiers } = user
  const [levels, setLevels] = useState([])
  
   const selectRow = { 
    mode: 'radio', 
    style: { background: '#6C757D', color: 'white' },
    hideSelectAll: true, 
    selected, 
    onSelect: (row, isSelect, rowIndex, e) => { 
        handleSelection(row, isSelect ) 
    }, 
  };

  useEffect(() => { 
    console.log(allowedLeadTiers)
    console.log(options)
    let levels = []
    if(allowedLeadTiers){
       levels = options.filter(item => {
         console.log(item.fixedCampaignName)
        return allowedLeadTiers.includes(item.fixedCampaignName)
      })
    }

    console.log(levels)
    setLevels(levels)
  }, []); 
 

    return ( 
      <BootstrapTable 
          id="fixedCampaigns"
          keyField="fixedCampaignName" 
          data={allowedLeadTiers.map(t => ({
            description: t.description,
            fixedCampaignName: t.name,
          }))} 
          columns={[ 
            { 
              dataField: 'fixedCampaignName', 
              text: 'Tier Name', 
              hidden: true, 
              editable: (cell, row, rowIndex, colIndex) => false 
            },  
            { 
              dataField: 'description', 
              text: 'Description', 
              editable: (cell, row, rowIndex, colIndex) => { 
              
              } 
            },
          ]} 
          cellEdit={ cellEditFactory({ 
            mode: 'click', 
          }) } 
         selectRow={selectRow} 
      />) 
  

}; 
 
 
 
Levels.propTypes = { 
    navigate: PropTypes.func,
    tiers: PropTypes.arrayOf(PropTypes.string).isRequired,
}; 
 
export default Levels; 