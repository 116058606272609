const apiUrl = process.env.REACT_APP_API_LOCAL_URL;
const resources = {
    activities: process.env.REACT_APP_PORTAL_SERVER,
    transfers: process.env.REACT_APP_PORTAL_SERVER,
    agent: process.env.REACT_APP_PORTAL_SERVER,
    campaigns: process.env.REACT_APP_CAMPAIGNS,
    campaign: process.env.REACT_APP_PORTAL_SERVER,
    user: process.env.REACT_APP_PORTAL_SERVER,
    lead: process.env.REACT_APP_PORTAL_SERVER,
    leads: process.env.REACT_APP_PORTAL_SERVER,
    network: process.env.REACT_APP_PORTAL_SERVER,
    tiers: process.env.REACT_APP_PORTAL_SERVER,
    vendor_campaigns: process.env.REACT_APP_PORTAL_SERVER,
    vendors: process.env.REACT_APP_PORTAL_SERVER,
    payments: process.env.REACT_APP_PORTAL_SERVER,
    money: process.env.REACT_APP_PORTAL_SERVER,
    destinations: process.env.REACT_APP_PORTAL_SERVER,
}

class LocalAPI {
    async request(method, path, myInit = {}) {
        console.log(`${method} ${path}`);

        const resource = path.replace(/^\/([^/]*).*$/, '$1');
        const url = `http://localhost:8081?url=${apiUrl}:${resources[resource]}${path}`;

        console.log(`${method} ${path}`);

        const params = {
            body: ['GET', 'HEAD'].includes(method) ? undefined : JSON.stringify(myInit),
            method,
            // mode: 'no-cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-identity-header': sessionStorage.getItem('authenticatedUserID'),
            }),
        };

        const response = await fetch(url, params);
        console.log(response)
        const jsonResponse = await response.json();

        if (response.status === 200) {
            return jsonResponse;
        }
        return Promise.reject(new Error(method === 'GET' ? jsonResponse : 'Error Loading'));
    }

    get(apiName, path) {
        return this.request('GET', path);
    }

    post(apiName, path, myInit) {
        return this.request('POST', path, myInit);
    }

    put(apiName, path, myInit) {
        return this.request('PUT', path, myInit);
    }
}

export default LocalAPI;