import { formatCurrencyForDisplay } from '../MyAgents/Utils';

export const minutesToTime = minutes => {
    // Remove additional days
    minutes %= 1440;

    let hours = Math.floor(minutes / 60);
    minutes %= 60;

    if (hours < 10) {
        hours = `0${hours}`;
    }

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
};

export const timeToMinutes = timeString => {
    const [hours, minutes] = timeString.split(':').map(s => parseInt(s, 10));

    if (Number.isNaN(hours)) {
        return 0;
    }
    return (hours * 60) + (Number.isNaN(minutes) ? 0 : minutes);
};



const formatTimeString = minutes => {
    if (minutes === 0) {
        return 'midnight';
    }

    if (minutes === 720) {
        return 'noon';
    }
    const hours = Math.floor(minutes / 60);
    let hoursString;

    if (hours === 0) {
        hoursString = '12';
    } else if (hours > 12) {
        hoursString = `${hours - 12}`;
    } else {
        hoursString = `${hours}`;
    }
    minutes %= 60;

    let minutesString;

    if (minutes < 10) {
        minutesString = `0${minutes}`;
    } else {
        minutesString = `${minutes}`;
    }
    return `${hoursString}:${minutesString}${hours >= 12 ? 'pm' : 'am'}`;
};

const formatTimeRangeString = (startTime, endTime) => {
    let nextDayString;

    if (startTime > endTime) {
        nextDayString = ' the next day';
    } else {
        nextDayString = '';
    }

    if (endTime === 1440) {
        endTime = 0;
    }

    if (startTime === endTime) {
        return '';
    }

    if (startTime === 0) {
        return ` until ${formatTimeString(endTime)}`;
    }

    if (endTime === 0) {
        return ` starting at ${formatTimeString(startTime)}`;
    }
    return ` from ${formatTimeString(startTime)} until ${formatTimeString(endTime)}${nextDayString}`;
};

const getDays = spendingPeriod => ([
    spendingPeriod.dowSun,
    spendingPeriod.dowMon,
    spendingPeriod.dowTue,
    spendingPeriod.dowWed,
    spendingPeriod.dowThu,
    spendingPeriod.dowFri,
    spendingPeriod.dowSat,
]);

const getActiveDaysCount = days => days.filter(dow => !!dow);

export const sumSpendingPeriods = spendingPeriods => spendingPeriods.reduce(
    (runningTotal, spendingPeriod) => runningTotal + (getActiveDaysCount(getDays(spendingPeriod)).length * spendingPeriod.dailySpendLimit),
    0,
);

export const getSpendingPeriodString = spendingPeriod => {
    const {
        dailySpendLimit,
        endTime,
        startTime,
    } = spendingPeriod;
    const days = getDays(spendingPeriod);
    const activeDays = getActiveDaysCount(days);

    let dowString;

    if (activeDays.length === 0) {
        return 'Never active.';
    }
    
    if (activeDays.length === 1) {
        dowString = `each ${['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].filter((dow, idx) => !!days[idx])[0]}`;
    } else if (activeDays.length === 7) {
        dowString = 'per day';
    } else if (activeDays.length === 2 && days[0] && days[6]) {
        dowString = 'per weekend day';
    } else if (activeDays.length === 5 && !days[0] && !days[6]) {
        dowString = 'per weekday';
    } else if (activeDays.length > 4) {
        const notActive = ['Sundays', 'Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays']
            .filter((dow, idx) => !days[idx]);
        
        dowString = `per day except ${notActive[0]}${activeDays.length === 5 ? ` and ${notActive[1]}` : ''}`;
    } else {
        const active = ['Sundays', 'Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays']
            .filter((dow, idx) => !!days[idx]);
        
        dowString = `per day on ${active.slice(0, -1).join(', ')} and ${active.slice(-1)}`;
    }
    let timeRangeString = formatTimeRangeString(startTime, endTime);

    if (timeRangeString !== '') {
        timeRangeString = ` ${timeRangeString}`;
    }
    return `Can spend up to ${formatCurrencyForDisplay(dailySpendLimit)} ${dowString}${timeRangeString}.`;
};

export const getSpendingPeriodsString = spendingPeriods => {
    if (spendingPeriods.length === 0) {
        return 'No active spending periods.';
    }
    
    if (spendingPeriods.length === 1) {
        return getSpendingPeriodString(spendingPeriods[0]);
    }
    return `${spendingPeriods.length} active spending periods.`;
};