import React from 'react';
import PropTypes from 'prop-types';
import { RiseLoader } from 'react-spinners';
// eslint-disable-next-line import/prefer-default-export

// const domain = window.location.href;

const getColor = () => {
  return '#1285ff';
};

export const CenterComponent = ({ children }) => (
  <div className="d-flex justify-content-center mt-3">{children}</div>
);

export const currencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

CenterComponent.propTypes = {
  children: PropTypes.node,
};

export const LoadingComponent = () => (
  <div className="d-flex justify-content-center my-4">
    <RiseLoader
      sizeUnit="px"
      color={getColor()}
      loading
    />
  </div>
);
