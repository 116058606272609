import React from 'react';
import { Card, Button, ButtonGroup, CardTitle, CardText, UncontrolledTooltip } from 'reactstrap';
import {
  FaUser,
  FaUserFriends,
  FaLink,
  FaSms,
  FaEnvelope,
  FaFlask,
  FaTrash,
  FaEdit,

 // FAQuestion

} from 'react-icons/fa';

const SelectedList = ({onEdit, onDelete, onTest,  destinations = [], marketplace}) => {
  console.log(destinations)

  const getColor = type => {
      switch(type){
       case 'user':
          return 'primary'
       case 'team':
        return 'info'
       case 'link':
        return 'success'
       case 'sms':
        return 'warning'
       case 'email':
         return 'warning'
       default:
         return 'danger'
      }
  }

  const makeIcon = name => {
    switch(name){
      case 'user':
         return <FaUser size={24}  />
      case 'team':
         return <FaUserFriends size={24} />
      case 'link':
        return <FaLink size={24}  />
      case 'sms':
        return <FaSms size={24}  />
      case 'email':
        return <FaEnvelope  size={24} />
      default:
        return null
    }
  }

  const getDescription = type => {
    switch(type){
      case 'user':
        return 'Delivered based on user setup'
     case 'team':
      return 'Delivered based on user setup'
     case 'link':
      return 'Delivered to an outside webhook url'
     case 'sms':
      return 'Text messaging rates may apply see terms and conditions'
     case 'email':
       return marketplace.includes('usha') ? 'leads@ushamarketplace.com' : 'Sent from bob@sonicleads.com' //will need to make based on whitelabel
     default:
       return 'This is an unknown destination type'
    }
}

  const makeCards = () => {
    console.log(destinations)
        return destinations.map(destination => {
          const { label, icon, id } = destination
           return (
             <Card 
             body inverse key={id} color={getColor(icon)} style={{ backgroundColor: '#333', borderColor: '#333' }}>
            <CardTitle>
            <div className="d-flex justify-content-between">
            <div>{makeIcon(icon)}<h3>{label}</h3></div>
            <div>
              <ButtonGroup>
                <Button id={`${id}destDeleteButton`} color="danger" onClick={() => onDelete(id)}>
                  <FaTrash />
                </Button>
                {icon === 'user' || icon === 'team' ? null :
                  <div>
                    <Button id={`${id}destTestButton`} color='info' onClick={() => onTest(destination)}>
                      <FaFlask />
                    </Button>
                    <Button id={`${id}destEditButton`} color="secondary" onClick={() => onEdit(id)}>
                      <FaEdit />
                    </Button>
                  </div>
                }
              </ButtonGroup>
            </div>
        </div>
            </CardTitle>
            <CardText>{getDescription(icon)} </CardText>
          </Card>
          )

        })

  }


  return (
    <div>
    {makeCards()}
    </div>
  );
};

export default SelectedList;