import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link, navigate } from '@reach/router';

import StoreContext from '../../stores';

const NavigationBar = observer(
  ({ isBeta, isTest, shouldMigrate, ...otherProps }) => {
    const [isOpened, setOpened] = useState(false);
    const {
      userStore: { hasAssumedRole, returnToOwnRole, role, signOut, user },
    } = useContext(StoreContext);

    const [allowMarketplaceBrokering, setAllowMarketplaceBrokering] = useState(
      false
    );
    const [allowMyAgents, setAllowMyAgents] = useState(false);
    const [allowVendors, setAllowVendors] = useState(false);

    const { marketplace } = user;

    React.useEffect(() => {
      console.log(user);
      const { flags } = user;
      if (flags) {
        const {
          allowMyAgents,
          allowMarketplaceBrokering,
          allowVendors,
        } = flags;
        setAllowMarketplaceBrokering(allowMarketplaceBrokering);
        setAllowMyAgents(allowMyAgents);
        setAllowVendors(allowVendors);
      }
    }, [user]);

    const toggle = () => {
      setOpened(!isOpened);
    };

    return (
      <>
        {isBeta && (
          <Alert color="warning">
            <b>BETA: </b>This is the marketplace beta version. To switch to the
            stable version,{' '}
            <Button href="https://ushamarketplace.com">Click here</Button>.
          </Alert>
        )}
        {shouldMigrate && (
          <Alert color="warning" className="d-flex align-items-center">
            <b>IMPORTANT: </b> Please use our new marketplace at
            app.ushamarketplace.com immediately by clicking here.{' '}
            <Button
              className="ml-3"
              href={`https://${
                isTest ? 'dev' : isBeta ? 'beta' : 'app'
              }.ushamarketplace.com`}
            >
              Switch
            </Button>
          </Alert>
        )}
        {isTest && (
          <Alert color="warning">
            <b>TEST: </b>This is the marketplace test version.
          </Alert>
        )}
        {hasAssumedRole && (
          <Alert color="primary">
            You are viewing the portal from the perspective of another user. To
            return to your own perspective,{' '}
            <Button onClick={() => returnToOwnRole()}>Click here</Button>
          </Alert>
        )}
        <Navbar color="light" expand="md" {...otherProps}>
          <NavbarBrand
            className="d-flex align-items-center"
            onClick={() => navigate('/')}
          >
            {marketplace === 'sonic' ? (
              <img
                alt=""
                height={75}
                src="https://s3.amazonaws.com/files.sonicwebdev.com/sonic/sonicgray.png"
                width={75}
              />
            ) : (
              'USHA Marketplace'
            )}
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpened} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink tag={Link} to="/" className="text-white">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/leads" className="text-white">
                  Leads
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/campaigns" className="text-white">
                  Campaigns
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/support" className="text-white">
                  Help
                </NavLink>
              </NavItem>
              <UncontrolledDropdown inNavbar nav>
                <DropdownToggle caret nav className="text-white">
                  Settings
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/account">
                    Account
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/profile/me">
                    Profile
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/agent-transfers">
                    Transfers
                  </DropdownItem>
                  {/*  <DropdownItem
                  tag={Link}
                  to="/activity"
                >
                  Activity
                </DropdownItem> 
               <DropdownItem
                  tag={Link}
                  to="/refund-policy"
                >
                  Refund Policy
                </DropdownItem> */}
                  <DropdownItem tag={Link} to="/campaigns">
                    Campaigns
                  </DropdownItem>
                  {allowMyAgents && (
                    <DropdownItem tag={Link} to="/myagents">
                      My Agents
                    </DropdownItem>
                  )}
                  {allowVendors ? (
                    <DropdownItem tag={Link} to="/vendors">
                      Vendors
                    </DropdownItem>
                  ) : null}
                  <DropdownItem onClick={() => signOut()}>
                    Sign Out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </>
    );
  }
);

NavigationBar.propTypes = {
  isBeta: PropTypes.bool.isRequired,
  isTest: PropTypes.bool.isRequired,
  shouldMigrate: PropTypes.bool.isRequired,
};

export default NavigationBar;
