
import React, { useEffect, useState, useContext } from "react"; 
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types'; 
import { Button, Card, CardTitle } from "reactstrap"; 
import { AvForm, AvField } from "availity-reactstrap-validation"; 
import {
  FaExclamationTriangle,
} from 'react-icons/fa';
import { CenterComponent, LoadingComponent } from "../../shared/helpers/helpers"; 
import StoreContext from '../../stores';


 
const attributedIDs = {
  EMAIL: 'a64fac22-27f7-4bc8-b73c-d652a7977181',
  URL: 'e890ef74-d7be-4d78-b93a-e025da09cae1',
}


const Form = observer(
  ({
    destination = {},
    setSelectedDestination,
    toggle,
  }) => { 
  const { destID} = destination 
  const [loading, setLoading] = useState(false); 
  const [selectedDestinationType, setDestinationType] = useState('EMAIL'); 
  const [errorMessage, setErrorMessage] = useState();
    
  const {
    destinationStore: { save, saving, errorMessage:savingError },
  } = useContext(StoreContext);

  const onSubmit = async (event, values) => { 

    // gave event id because the evnt bubbles up to trigger on submit in campagian
    event.name= "create-destination";

    const { email, url, destinationType } = values 
    
    if(!(destinationType in attributedIDs)){
      setErrorMessage('Missing Attributed ID')
    }

    const body = {destID, ...values, destinationType: destinationType.toLowerCase(), status: 'active'} 
    body.attributesID = attributedIDs[destinationType]
    if(destinationType === 'URL'){ 
      body.destination = url 
    }else{ 
      body.destination = email 
    } 

    const id = await save(body);
    setSelectedDestination(id);
    toggle();
  } 
 
  const onChange = async event => { 
    if(event.target.name === 'destinationType'){ 
      setDestinationType(event.target.value) 
    } 
  } 

  useEffect(() => {
      setLoading(saving)
      setErrorMessage(savingError)
  }, [saving, savingError]);
  
 
  return ( 
    <CenterComponent> 
    <Card body style={{ maxWidth: 500 }}> 
      <CardTitle 
        tag="h4" 
        className="text-center pr-3 pl-3 border-bottom border-danger text-primary pb-2" 
      > {
        destID ? 
        <div> <FaExclamationTriangle size={24} color='yellow' /> Editing this destination will impact ALL associated campaigns </div>: 
        <div>Create New Destination</div>}
      </CardTitle> 
      <AvForm 
      onChange={onChange} 
      onValidSubmit={onSubmit} 
      > 
   
          <AvField 
          name="destinationName" 
          key="destinationName" 
          type="text" 
          value={'destinationName' in destination ? destination.destinationName : null } 
          placeholder="Something descriptive..." 
          label="Destination Name" 
          errorMessage={'"Please provide a valid name"'} 
          validate={{ 
            required: { value: true, errorMessage: "Please enter a name for the destination" } 
          }} 
        /> 
          <AvField 
          name="destinationType" 
          key="destinationType" 
          type="select" 
          value={selectedDestinationType || null} 
          placeholder="Destination Type" 
          label="Destination Type" 
          errorMessage="Please select one" 
          validate={{ 
            required: { value: true, errorMessage: "Please select one" } 
          }} 
        > 
        {['EMAIL'].map(option => <option key={option}>{option}</option>)} 
        </AvField> 
 
        {selectedDestinationType === 'URL' ? <AvField 
        key="url" 
        name="url" 
        type="text" 
        value={'destination' in destination ? destination.destination : null } 
        placeholder="https://..." 
        label="Webhook Url" 
        errorMessage="Please provide a valid url" 
        validate={{ 
          required: { value: true, errorMessage: "Please enter an email" } 
        }}  
        /> : 
        <AvField 
        name="email" 
        key="email" 
        type="email" 
        value={'destination' in destination ? destination.destination : null } 
        placeholder="someone@me.com" 
        label="Email" 
        errorMessage="Please provide a valid email" 
        validate={{ 
          required: { value: true, errorMessage: "Please enter an email" } 
        }} 
        />} 
 
       {loading ? <LoadingComponent />  :   <Button color="primary" size="lg" block>Save</Button>} 
       </AvForm> 
       <p style={{color:'red'}}>{errorMessage}</p>
    </Card> 
    </CenterComponent> 
  ); 
 
})
 
 
Form.propTypes = { 
  destination: PropTypes.object, 
  setSelectedDestination: PropTypes.func.isRequired,
  toggle: PropTypes.func, 
}; 
 
export default Form; 

