import {
  action,
  decorate,
  observable,
} from 'mobx';

import API from '../shared/api';

class PriceTierStore {
  list = [];

  synced = false;

  constructor(notificationStore) {
    this.notificationStore = notificationStore;
  }

  async load(marketplace) {
    this.synced = false;

    try {
      const {
        data: priceTiers,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/tiers/list',
        { body: { marketplace } },
      );
  
      if (success) {
        this.list = priceTiers;
        this.synced = true;
      } else {
        this.notificationStore.create('danger', message);
      }
    } catch (error) {
      console.error(error);

      this.notificationStore.create('danger', 'Internal server error.');
    }
  }

  async save(body) {
    this.synced = false;

    try {
      const {
        data: newPriceTiers,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/tiers',
        { body },
      );

      if (success) {
        this.list = newPriceTiers;
        this.synced = true;
      } else {
        this.notificationStore.create('danger', message);
      }
    } catch (error) {
      console.error(error);

      this.notificationStore.create('danger', 'Internal server error.');
    }
  }
}

decorate(PriceTierStore, {
  list: observable,
  synced: observable,
  load: action,
  save: action,
});

export default PriceTierStore;