import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {
    Button,
    Table,
} from 'reactstrap';


const AgentTable = ({
    children,
    withdrawAll,
    className,
    ...props
}) => (
    <Table
        className={classNames(className,'text-right')}
        hover
        {...props}
    >
        <thead>
            <tr>
                <th 
                    scope="col" 
                    style={{ width: '18%' }}>
                        Agent Name
                </th>
                <th
                    className="pl-4 text-left"
                    scope="col"
                >
                    {/* Automatic Credit Transfer Rules */}
                </th>
                <th 
                    scope="col" 
                    style={{ width: '15%', textAlign:'center'}}>
                        Email
                </th>
                <th 
                    scope="col" 
                    style={{ width: '15%' }}>
                        Total Credit
                </th>
                <th 
                    scope="col"
                    style={{ width: '15%' }}>
                        You Contributed
                </th>
                <th
                    scope="col"
                    style={{
                        width: '15%',
                    }}
                >
                    Transfer Amount
                </th>
                <th
                    scope="col"
                    style={{
                        width: '15%',
                    }}
                >
                    Final Credit
                </th>
            </tr>
        </thead>
            {children}
        <tfoot>
            <tr>
                <td />
                <td />
                <td />
                <td>
                    {/* <Button
                        color="warning"
                        onClick={withdrawAll}
                    >
                        Withdraw All
                    </Button> */}
                </td>
                <td />
                <td />
            </tr>
        </tfoot>
    </Table>
);
AgentTable.propTypes = {
    withdrawAll: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.node
};

export default AgentTable;