import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Table,
} from 'reactstrap';
import { SpinnerComponent } from 'react-element-spinner';
import StoreContext from '../../../../stores';

import ShareEmailModal from '../../modals/ShareEmailModal';
import VendorCampaignModal from '../../modals/vendor_campaign/VendorCampaignModal';
import VendorCampaignRow from './VendorCampaignRow';

import {
  DEFAULT_STEP_STATE,
  MS_PER_DAY,
  MS_PER_99_YEARS,
  STEP_CLEAN_NAME,
  STEP_DELIVERY_NAME,
  STEP_QUALITY_NAME,
  STEP_REJECT_NAME,
  STEP_VENDOR_NAME,
} from '../../constants';

const VendorCampaignsTab = ({
  openSubmenu,
  setOpenSubmenu,
}) => {
  const {
    networkStore,
    vendorCampaignStore,
  } = React.useContext(StoreContext);

  console.log(openSubmenu)
  const id = openSubmenu.split(':').slice(1).join(':');
  console.log(id)
  const currentVendorCampaign = toJS(vendorCampaignStore.list.find(
    vendorCampaign => vendorCampaign.layerID === id,
  ));
  let baseStepState;
  let leadQualityTier;

  if (currentVendorCampaign) {
    if (currentVendorCampaign.enhanceProfile) {
      leadQualityTier = 'enhance_profile_check_phone';
    } else if (currentVendorCampaign.blockDisconnected) {
      leadQualityTier = 'check_phone';
    } else {
      leadQualityTier = 'no_improvement';
    }

    baseStepState = {
      [STEP_CLEAN_NAME]: {
        checkForDuplicates: currentVendorCampaign.blockDuplicates,
        dupeSubmissionMode: currentVendorCampaign.passDuplicates ? 'allow' : 'block',
        phoneDupeDays: Math.floor(currentVendorCampaign.minimumRefresh / MS_PER_DAY),
        phoneDupeMode: currentVendorCampaign.minimumRefresh === MS_PER_99_YEARS ? 'forever' : 'for_x_days',
      },
      [STEP_QUALITY_NAME]: {
        leadQualityTier,
      },
      [STEP_REJECT_NAME]: {
        acceptsTermsAndConditions: false,
        rejectDisconnected: currentVendorCampaign.rejectDisconnected || false,
        rejectDuplicates: currentVendorCampaign.rejectDuplicates || false,
      },
      [STEP_VENDOR_NAME]: {
        defaultLeadPrice: parseFloat(currentVendorCampaign.vendorPrice || '0') * 100,
        selectedVendor: toJS(vendorCampaignStore.list.find(
          vendorCampaign => vendorCampaign.vendorID === currentVendorCampaign.vendorID,
        ) || null),
        vendorCampaignName: currentVendorCampaign.vendorSourceCampaign,
      },
      [STEP_DELIVERY_NAME]: {
        destinationIDs: currentVendorCampaign.destinations || [],
        myAgentDestinationIDs: currentVendorCampaign.myAgentDestinations || [],
      },
    };
  } else {
    baseStepState = DEFAULT_STEP_STATE;
  }

  return (
    <div>
    <SpinnerComponent spinnerType='circle-dots' backgroundColor='#222222' color='#1F85FF' loading={!vendorCampaignStore.synced} position="centered" />
      <ShareEmailModal
        id={id}
        dismiss={() => setOpenSubmenu('')}
        isOpen={openSubmenu.startsWith('vcEmail')}
      />
      <VendorCampaignModal
        baseStepState={baseStepState}
        dismiss={() => setOpenSubmenu('')}
        id={id}
        isOpen={openSubmenu.startsWith('vendorCampaign')}
      />
      <br />
      <h4>Vendor Campaigns</h4>
      <br />
      <Button
        color="success"
        onClick={() => setOpenSubmenu('vendorCampaignNew')}
      >
        Create Vendor Campaign
      </Button>
      <Table hover>
        <thead>
          <tr style={{ height: '124px' }}>
            <th style={{ borderTop: 'none' }}>Vendor</th>
            <th style={{ borderTop: 'none' }}>Campaign Name</th>
          {/*  <th style={{ borderTop: 'none' }}>Instructions</th>*/}
            <th style={{ borderTop: 'none' }}>Add On</th>
            <th style={{ borderTop: 'none' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vendorCampaignStore.list.map(
            vendorCampaign => {
            //  need to put vendor price under another column
           //   let ppl = parseFloat(vendorCampaign.vendorPrice || '0') * 100;
              let ppl = 0
              if (vendorCampaign.enhanceProfile) {
                ppl += parseInt((networkStore.network || {}).enhanceProfile || '0', 10) + parseInt((networkStore.network || {}).blockDisconnected || '0', 10);
              } else if (vendorCampaign.blockDisconnected) {
                ppl += parseInt((networkStore.network || {}).blockDisconnected || '0', 10);
              }

              if (vendorCampaign.blockDuplicates) {
                ppl += parseInt((networkStore.network || {}).blockDuplicates || '0', 10);
              }
              return (
                <VendorCampaignRow
                  layerID={vendorCampaign.layerID}
                  vendorID={vendorCampaign.vendorID}
                  key={vendorCampaign.layerID}
                  ppl={ppl}
                  setOpenSubmenu={setOpenSubmenu}
                  vendorCampaignName={vendorCampaign.vendorSourceCampaign}
                  vendorName={vendorCampaign.vendorName}
                />
              );
            },
          )}
        </tbody>
      </Table>
    </div>
  );
};

VendorCampaignsTab.propTypes = {
  openSubmenu: PropTypes.string.isRequired,
  setOpenSubmenu: PropTypes.func.isRequired,
};

export default observer(VendorCampaignsTab);