import {
    action,
    computed,
    decorate,
    observable,
    toJS,
  } from 'mobx';

  import API from '../shared/api';

  const loadDestinations = async () => {
    try {
      const { data }  = await API.post(
        process.env.REACT_APP_API,
        '/destinations',
        { body: {} },
      );

      return data.filter(d => {
        return d.status === 'active'
      })
    }catch(error){
      console.error(error)
      return []
    }
  };

 

  class DestinationStore {
    constructor(userStore, creditStore,notificationStore) {
      this.notificationStore = notificationStore;
    }
    list = [];
  
    loading = true;
  
    saving = false;

    errorMessage = null
  
    load = async () => {
      console.log('load')
      this.loading = true;
  
      try {
        const { data }  = await API.post(
          process.env.REACT_APP_API,
          '/destinations',
          { body: {} },
        );
        console.log(data)

        this.list = data
      
      } finally {
        this.loading = false;
      }
    };

    init = async () => {

      const loadedDestinations = await loadDestinations();
      this.list = loadedDestinations 
      this.loading = false;
    };

    getSingle = async destID => {
      this.loading = true;
  
      try {
        const { data: { data } }  = await API.post(
          process.env.REACT_APP_API,
          '/destinations',
          { body: {destID} },
        );

        this.list = data
      
      } finally {
        this.loading = false;
      }
    };

    get destinations() {
      return toJS(this.list)
    }
  
    get ready() {
      return !this.loading && !this.saving;
    }

    test = async destination => {
      try {
        const response = await API.put(
          process.env.REACT_APP_API,
          '/destinations/test',
          {
            body: {
              ...destination
            },
          },
        );
        const { success } = response
        if(success){
          this.notificationStore.create(
            'success',
            'Test Sent Successfully'
          );
        }
      }catch(error){
         console.error(error)
         this.notificationStore.create(
          'danger',
          error.message
        );
      }finally {
       // this.saving = false;
      }
    }
  
    save = async destination => {
      this.saving = true;
      this.errorMessage = null
      
      try {
        const response = await API.put(
          process.env.REACT_APP_API,
          '/destinations',
          {
            body: {
              ...destination
            },
          },
        );
        const { success, data } = response
        if(success){
          const { destID } = destination
          if(destID){
            this.list = this.list.map(
              item => {
                const { destID: checkID } = item
                if(checkID === destID){
                  return data
                }
                return item
              },
            );
            return destID;
          }else{
            this.list.push(data)
          }
         
        }

      }catch(error){
          this.errorMessage = error.message
      }finally {
        this.saving = false;
      }
    }
  
  }
  
  decorate(DestinationStore, {
    list: observable,
    loading: observable,
    load: action,
    ready: computed,
    destinations: computed,
    save: action,
    get: action,
    saving: observable,
    errorMessage: observable,
  });
  
  export default DestinationStore;