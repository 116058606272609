import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CustomInput,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';

import StoreContext from '../../../../../stores';

import CopyBlock from '../../../../../shared/components/CopyBlock';
import PriceBadge from '../../../../../shared/components/PriceBadge';

import {
  PHONE_DUPE_MAX_DAYS,
  PHONE_DUPE_MIN_DAYS,
} from '../../../constants';

const StepClean = ({
  checkForDuplicates,
  dupeSubmissionMode,
  phoneDupeDays,
  phoneDupeMode,
  setDupeSubmissionMode,
  setPhoneDupeDays,
  setPhoneDupeMode,
  toggleCheckForDuplicates,
}) => {
  const {
    networkStore,
    notificationStore,
    userStore,
  } = React.useContext(StoreContext);

  return (
    <>
      <h5>Keep your CRM data clean</h5>
      <FormGroup>
        <CustomInput
          checked={checkForDuplicates}
          id="checkForDuplicates"
          label={
            <>
              Check for duplicates <PriceBadge
                price={parseInt((networkStore.network || {}).blockDuplicates || '', 10)}
                style={{ marginLeft: '5px' }}
              />
            </>
          }
          onChange={toggleCheckForDuplicates}
          type="checkbox"
        />
      </FormGroup>
      {checkForDuplicates && (
        <>
          <FormGroup>
            <Label>Phone numbers should be considered duplicates</Label>
            <div>
              <CustomInput
                checked={phoneDupeMode === 'forever'}
                id="phonesDupeForever"
                label="Forever"
                onChange={() => setPhoneDupeMode('forever')}
                type="radio"
              />
            {/*  <CustomInput
                checked={phoneDupeMode === 'for_x_days'}
                id="phonesDupeForXDays"
                label="For a certain number of days"
                onChange={() => setPhoneDupeMode('for_x_days')}
                type="radio"
            />*/}
            </div>
          </FormGroup>
          {phoneDupeMode === 'for_x_days' && (
            <FormGroup>
              <InputGroup>
                <Input
                  invalid={phoneDupeDays < PHONE_DUPE_MIN_DAYS || phoneDupeDays > PHONE_DUPE_MAX_DAYS}
                  onChange={e => setPhoneDupeDays(e.target.value)}
                  type="number"
                  value={phoneDupeDays}
                />
                <InputGroupAddon addonType="append">days</InputGroupAddon>
                <FormFeedback>Days must fall between {PHONE_DUPE_MIN_DAYS} and {PHONE_DUPE_MAX_DAYS}</FormFeedback>
              </InputGroup>
            </FormGroup>
          )}
          {userStore.user.canDeliverDuplicateLeads && (
            <FormGroup>
              <Label>Duplicates are blocked from submission to destinations. This behavior can be changed here. You may also view, export, and analyze duplicate leads within the Marketplace. </Label> {/*TODO: Add read more */}
              <div>
                <CustomInput
                  checked={dupeSubmissionMode === 'block'}
                  id="blockDupeSubmissions"
                  label="Block all duplicates from being sent to my lead destinations"
                  onChange={() => setDupeSubmissionMode('block')}
                  type="radio"
                />
                <CustomInput
                  checked={dupeSubmissionMode === 'allow'}
                  id="allowDupeSubmissions"
                  label="I don't care. Send duplicates to my lead destinations"
                  onChange={() => setDupeSubmissionMode('allow')}
                  type="radio"
                />
              </div>
              {dupeSubmissionMode === 'allow' && (
                <FormText>
                  Duplicate leads will be sent with an additional attribute called
                  <CopyBlock
                    copyText="isDuplicate"
                    onCopy={() => notificationStore.create('success', 'Copied to clipboard!')}
                  />
                  with value
                  <CopyBlock
                    copyText="true"
                    onCopy={() => notificationStore.create('success', 'Copied to clipboard!')}
                  />.
                  </FormText>
              )}
            </FormGroup>
          )}
        </>
      )}
    </>
  );
};

StepClean.propTypes = {
  checkForDuplicates: PropTypes.bool.isRequired,
  dupeSubmissionMode: PropTypes.oneOf(['block', 'allow']).isRequired,
  phoneDupeDays: PropTypes.number.isRequired,
  phoneDupeMode: PropTypes.oneOf(['forever', 'for_x_days']).isRequired,
  setDupeSubmissionMode: PropTypes.func.isRequired,
  setPhoneDupeDays: PropTypes.func.isRequired,
  setPhoneDupeMode: PropTypes.func.isRequired,
  toggleCheckForDuplicates: PropTypes.func.isRequired,
};

export default observer(StepClean);