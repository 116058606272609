import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import { formatCurrencyForDisplay } from '../../../MyAgents/Utils';
import VendorCampaignBox from './VendorCampaignBox';

const TierRow = ({
  assignVendorCampaignToTier,
  fallbackMinutes,
  fallbackIncrement,
  fallbackTierName,
  id,
  name,
  openEditTierModal,
  price,
  removeVendorCampaignFromTier,
  vendorCampaigns,
}) => (
  <tr>
    <td style={{ border: 'none' }}>
      <Button
        onClick={() => openEditTierModal(id)}
        size="sm"
      >
        <i className="fa fa-edit" />
      </Button>
    </td>
    <td
      style={{
        border: 'none',
        maxWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      title={name}
    >
      {name}
    </td>
    <td
      style={{ border: 'none' }}
      title={formatCurrencyForDisplay(price)}
    >
      <h4>{formatCurrencyForDisplay(price)}</h4>
    </td>
    <VendorCampaignBox
      assignVendorCampaignToTier={assignVendorCampaignToTier}
      removeVendorCampaignFromTier={removeVendorCampaignFromTier}
      tierName={name}
      vendorCampaigns={vendorCampaigns}
    />
    <td
      style={{
        border: 'none',
        maxWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      title={`After ${fallbackMinutes} minutes unsold, sell as ${fallbackTierName}`}
    >
      <small><i className="fa fa-clock" /> {fallbackMinutes} {fallbackIncrement} - {fallbackTierName}</small>
    </td>
  </tr>
);

TierRow.propTypes = {
  assignVendorCampaignToTier: PropTypes.func.isRequired,
  fallbackMinutes: PropTypes.number.isRequired,
  fallbackIncrement: PropTypes.oneOf(['seconds', 'minutes', 'hours', 'days']).isRequired,
  fallbackTierName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  openEditTierModal: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  removeVendorCampaignFromTier: PropTypes.func.isRequired,
  vendorCampaigns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
  })).isRequired,
};

export default TierRow;