import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FaSortAmountDown,
  FaSortAmountUp,
} from 'react-icons/fa';
import { 
  Button,
  ButtonGroup,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
 } from 'reactstrap';
import { 
  AvForm, 
  AvInput

} from 'availity-reactstrap-validation';
import AvSelect from '@availity/reactstrap-validation-select';
import { usePagination } from '@availity/pagination';
import dayjs from 'dayjs'
import DateSelector from '../DateSelector';

const sortOptions = [
  {
    label: 'Date',
    value: 'soldDate',
  },
  {
    label: 'Campaign',
    value: 'campaignName',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'State',
    value: 'Region',
  },
  {
    label: 'Lead Type',
    value: 'leadType',
  },
];


const LeadControls = ({ onSearch, searchText: oldSearchText, sortKey, descending, startDate, endDate, leftSelectOptions = [] }) => {
  const { setPage, currentPage } = usePagination();
  const [sort, setSort] = useState(sortKey);
  const [searchText, setSearchText] = useState(oldSearchText);
  const [campaignID, setCampaignID] = useState();
  const [leadType, setLeadType] = useState(0);
  const [desc, setDescending] = useState(descending);
  const [mounted, setMounted] = useState();
  const [errorFilter, setErrorFilter] = useState({emailOnly:false,isDuplicate:false})

  const search = async payload => {
        const { date } = payload
        const newPayload = {...payload}
        if(!date){
          newPayload.startDate = startDate
          newPayload.endDate = endDate
        }
       // setLastSearch(newPayload)
        onSearch(newPayload)
        if (currentPage !== 1) {
          setPage(1);
        }
  }


  const autoSearch = () => {
    search({
      ...errorFilter,
      searchText,
      leadType,
      campaignID,
      sortKey: sort,
      descending: desc,
    })
  }

  useEffect(() => {
      if(mounted){
        const handler = setTimeout(() => {
          autoSearch()
        }, 700);
        return () => {
          clearTimeout(handler);
        };
      }
  
    },
    [searchText] 
  );

    const toggleErrorFilter = id => {
        const { [id]:value } = errorFilter;
        
        setErrorFilter(data => {
          return {
            ...data,
            [id]:!value
          }
        })
    }

  useEffect(
    () => {
      if(mounted){
        autoSearch()
      }else{
        setMounted(true)
      }
     
    },
    [sort, campaignID, leadType, desc]
  )

  return (
    <AvForm
      onValidSubmit={(e, data) => {
        search({
          ...errorFilter,
          searchText,
          ...data,
          campaignID,
          sortKey: sort,
          descending: desc,
          startDate: data.date.start !== "" ? dayjs(data.date.start): undefined,
          endDate: data.date.end !== "" ? dayjs(data.date.end).add(1, 'day'): undefined,
        });
        if (currentPage !== 1) {
          setPage(1);
        }
      }}
    >
  
    <div className="bg-light py-3 px-4 d-flex align-items-center justify-content-between">
    {leftSelectOptions ?
    <div className="d-flex alignt-items-center">
      <InputGroup className="d-flex justify-content-end w-auto">
      <AvSelect
        styles={{
          menuList: provided => ({
            ...provided,
            color: '#232323',
          }),
        }}
        name="campaignID"
        id="campaign-select"
       options={leftSelectOptions}
        onChange={option => setCampaignID(option)}
        value={campaignID}
        placeholder="Filter By Campaign"
        inputProps={{
          width: 200,
        }}
      />
      <InputGroupAddon addonType="prepend"><Button
      onClick={() => setCampaignID(null)}
      >Clear</Button></InputGroupAddon>
      </InputGroup>
    </div>: null}

    <ButtonGroup className="w-50 mx-auto">
    <Button
      onClick={() => setLeadType('vendor')}
      active={leadType === 'vendor'}
    >
      Marketplace 
    </Button>
    <Button
    onClick={() => setLeadType(null)}
    active={!leadType}
  >
    All Leads
  </Button>
    <Button
      onClick={() => setLeadType('agentDirect')}
      active={leadType === 'agentDirect'}
    >
      Agent Direct
    </Button>

  </ButtonGroup>


    <InputGroup className="d-flex justify-content-end w-auto">
      <AvSelect
        styles={{
          menuList: provided => ({
            ...provided,
            color: '#232323',
          }),
        }}
        name="sort"
        id="sort-select"
        options={sortOptions}
        onChange={option => setSort(option)}
        value={sort}
        placeholder="Sort By"
        inputProps={{
          width: 200,
        }}
      />
      <InputGroupAddon
        style={{ cursor: 'pointer' }}
        onClick={() => setDescending(!desc)}
        addonType="append"
      >
        <InputGroupText>
          {desc ? <FaSortAmountDown /> : <FaSortAmountUp />}
        </InputGroupText>
      </InputGroupAddon>

   
  </InputGroup>  
  </div> 
  <CardBody>
    Error Filter:  &nbsp;  &nbsp;
    <CustomInput type="checkbox" id="isDuplicate"label="Duplicate" onChange={e => toggleErrorFilter(e.target.id)} inline />
    <CustomInput type="checkbox" id="emailOnly" label="Email only" onChange={e => toggleErrorFilter(e.target.id)} inline/>
    </CardBody>
  <CardBody className="d-flex py-2">
  <AvInput 
        name="searchText"
        placeholder="Search..."
        type="text"
        onChange={e => setSearchText(e.target.value)}
        value={searchText}
        className="mr-2"
      />
    
      <DateSelector startDate={startDate} endDate={endDate} />
      <Button className="ml-2" color="primary">
        Search
      </Button>
      
      
</CardBody>
      
    </AvForm>

  );
};

LeadControls.propTypes = {
  onSearch: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  searchText: PropTypes.string,
  sortKey:PropTypes.string,
  descending:PropTypes.bool, 
  leftSelectOptions: PropTypes.array
};

export default LeadControls;
