import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react'; 

import CampaignTable from './Table/CampaignTable';
import { LoadingComponent } from "../../shared/helpers/helpers";
import StoreContext from '../../stores';
import DeleteCampaignModal from './Modals/DeleteCampaignModal';
import EditSpendingPeriodModal from './Modals/EditSpendingPeriodModal';

const CampaignPage = ({
  navigate,
}) => {
  const {
    campaignStore,
    creditStore,
  } = useContext(StoreContext);

  const [openModal, setOpenModal] = React.useState(null);

  useEffect(
    () => {
      campaignStore.load();
      creditStore.syncCredit();
    },
    [],
  );

  const { list: rawCampaigns } = campaignStore;
  const campaigns = JSON.parse(JSON.stringify(rawCampaigns));

  const [currentCampaignIdx, currentCampaign] = useMemo(() => {
    let currentCampaignIdx = -1;
    let currentCampaign = null;
  
    if (
      openModal !== null &&
      (
        openModal.startsWith('edit_spending_period') ||
        openModal.startsWith('delete_campaign')
      )
    ) {
      currentCampaignIdx = campaigns.findIndex(({ campaignID }) => campaignID === openModal.split(':').slice(1).join(':'));
  
      if (currentCampaignIdx > -1) {
        currentCampaign = campaigns[currentCampaignIdx];
      }
    }

    return [currentCampaignIdx, currentCampaign]

  }, [openModal, campaigns])

  const isLoading = campaignStore.loading || creditStore.loading;

  if (isLoading) {
    return <LoadingComponent />
  }
  

 //const { campaignID, campaignName } = currentCampaign || {}
 
  return (
    <div>
      {currentCampaign !== null && (
        <EditSpendingPeriodModal
          campaign={currentCampaign}
          campaignIdx={currentCampaignIdx}
          closeModal={() => setOpenModal(null)}
          isOpen={openModal !== null && openModal.startsWith('edit_spending_period')}
          saveCampaign={campaign => campaignStore.save([campaign])}
          saving={campaignStore.saving}
        />
      )}
      {openModal !== null && openModal.startsWith('delete_campaign') && (
        <DeleteCampaignModal
          dismiss={() => setOpenModal(null)}
          id={currentCampaign.campaignID}
          name={currentCampaign.campaignName}
        />
      )}
      <CampaignTable
        campaigns={campaigns}
        loggedInUserCredit={creditStore.credit}
        navigate={navigate}
        openModal={setOpenModal}
        toggleLoadStates={campaignStore.pauseTogglesLoading}
        togglePauseAll={() => {
          campaigns.forEach(
            (c, idx) => {
              if (!c.paused) {
                campaignStore.togglePause(idx);
              }
            },
          );
        }}
        togglePaused={campaignIdx => {
          campaignStore.togglePause(campaignIdx);
        }}
        toggleResumeAll={() => {
          campaigns.forEach(
            (c, idx) => {
              if (c.paused) {
                campaignStore.togglePause(idx);
              }
            },
          );
        }}
      />
    </div>
  );
};

CampaignPage.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default observer(CampaignPage);