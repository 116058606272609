import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import { Page404 } from '..';
import StoreContext from '../../stores';

import {
  TiersTab,
  VendorCampaignsTab,
} from './tabs';

const ConfigPage = () => {
  const {
    priceTierStore,
    networkStore,
    userStore,
    vendorStore,
    vendorCampaignStore,
    vendorCampaignStore:{ list, campaignsList }
  } = React.useContext(StoreContext);
  const {
    marketplace,
    //role,
    user
  } = userStore;

  const [openSubmenu, setOpenSubmenu] = React.useState('');
  const [allowMarketplaceBrokering, setAllowMarketplaceBrokering] = useState(false);
  const [tab, setTab] = React.useState(allowMarketplaceBrokering ? 'price_tiers' : 'vendor_campaigns' );

  React.useEffect(() => {
    const { flags, marketplace } = user;
    if(flags && marketplace){
    const { allowMarketplaceBrokering } = flags;
    vendorCampaignStore.load(marketplace);
    setAllowMarketplaceBrokering(allowMarketplaceBrokering)    
    }
  }, [user]);

  React.useEffect(() => {
    const { flags, marketplace } = user;
    if(flags && marketplace){
      const { allowMarketplaceBrokering } = flags;
      if(allowMarketplaceBrokering){priceTierStore.load(marketplace)}
    }
  }, [list]);

  
  /*React.useEffect(() => {
    priceTierStore.load(marketplace);
    vendorCampaignStore.load(marketplace);
  }, marketplace);*/

  React.useEffect(() => {
    networkStore.load(userStore.user.networkID);
    vendorStore.load();
  }, []);




  if (!allowMarketplaceBrokering) {
  return ( <>
      <Nav tabs disabled={!campaignsList}>
       
        <NavItem>
          <NavLink
            active={tab === 'vendor_campaigns'}
            onClick={() => setTab('vendor_campaigns')}
            style={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            Vendor Campaigns
          </NavLink>
        </NavItem>
      </Nav>
      {tab === 'vendor_campaigns' && (
        <VendorCampaignsTab
          openSubmenu={openSubmenu}
          setOpenSubmenu={setOpenSubmenu}
        />
      )}
    </>)
    }
  
  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink disabled={!campaignsList}
            active={tab === 'price_tiers'}
            onClick={() => setTab('price_tiers')}
            style={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            Price Tiers
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink disabled={!campaignsList}
            active={tab === 'vendor_campaigns'}
            onClick={() => setTab('vendor_campaigns')}
            style={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            Vendor Campaigns
          </NavLink>
        </NavItem>
      </Nav>
      {tab === 'price_tiers' && (
        <TiersTab
          openSubmenu={openSubmenu}
          setOpenSubmenu={setOpenSubmenu}
        />
      )}
      {tab === 'vendor_campaigns' && (
        <VendorCampaignsTab
          openSubmenu={openSubmenu}
          setOpenSubmenu={setOpenSubmenu}
        />
      )}
    </>
  );
};

export default observer(ConfigPage);