import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Container,
} from 'reactstrap';
import Amplify, { Storage } from 'aws-amplify';
import { Greetings, Loading, SignOut } from 'aws-amplify-react';
import constants from 'aws-amplify-react/dist/Auth/common/constants';
import AmplifyRouter from 'amplify-react-router';
import { LoadingComponent } from './shared/helpers/helpers';
import { CacheContext } from './shared/helpers/CacheBuster';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Nav from './shared/components/nav';
import Footer from './shared/components/Footer';
import StoreContext from './stores';

import ConfirmSignUp from './components/ConfirmSignUp';
import {
  Activity,
  Transfers,
  Home,
  Leads,
  Campaigns,
  Campaign,
  Vendors,
  Account,
  MyAgents,
  Page404,
  Support,
  Profile,
  RefundPolicy,
  Help,
} from './areas';
import './styles';
import awsmobile from './aws-exports';

const { aws_user_pools_id: pool } = awsmobile;
const domain = window.location.href;

// Temporary Fix for the refresh. I have no idea how that is getting set
// https://github.com/aws-amplify/amplify-js/blob/bd7964ac95a9998d2697fc9c392b9e3966062d65/packages/aws-amplify-react/src/Auth/Authenticator.jsx#L59-L66
// https://github.com/aws-amplify/amplify-js/blob/bd7964ac95a9998d2697fc9c392b9e3966062d65/packages/auth/src/Auth.ts#L110-L121
// eslint-disable-next-line no-undef
localStorage.removeItem(constants.SIGN_IN_WITH_HOSTEDUI_KEY);

const awsConfig = {
  ...awsmobile,
  aws_cloud_logic_custom: [
    {
      name: 'MarketPlace',
      endpoint:
        pool === process.env.REACT_APP_USER_POOL_PROD
          ? domain.includes('beta')
            ? process.env.REACT_APP_API_URL_BETA
            : process.env.REACT_APP_API_URL_PROD
          : process.env.REACT_APP_API_URL_DEV,
      region: 'us-east-1',
    },
  ],
};

Amplify.configure(awsConfig);

Storage.configure({
  AWSS3: {
    bucket: `reports.leadarena-${process.env.REACT_APP_SONIC_ENV}`,
    region: 'us-east-1',
  },
});

const AppContainer = observer(({ children }) => {
  const [legacyCheck, setLegacyCheck] = useState(false);
  const {
    notificationStore: { notifications },
    userStore: { approved, setOwnRole, user, legacySync },
  } = useContext(StoreContext);

  const { flags = {}, marketplace } = user;

  let isBeta = false;
  let isTest = false;

  if (domain.includes('beta')) {
    isBeta = true;
  } else if (domain.includes('test') || domain.includes('localhost')) {
    isTest = true;
  }

  useEffect(() => {
    setOwnRole();
  }, []);

  useEffect(() => {
    setLegacyCheck(false);
  }, [legacySync]);

  if (!legacyCheck) {
    return (
      <Card>
        <CardBody>
          <CardTitle className="h5">Access Denied</CardTitle>
          {marketplace !== 'sonic' && (
            <CardText>Please use the new Marketplace.</CardText>
          )}
        </CardBody>
        <CardFooter>
          {marketplace !== 'sonic' && (
            <Button
              color="primary"
              href={`https://${
                isTest ? 'dev' : isBeta ? 'beta' : 'app'
              }.ushamarketplace.com`}
            >
              Switch
            </Button>
          )}
          {!['user', 'manager'].includes(user.role) && (
            <Button
              className="ml-2"
              color="warning"
              onClick={() => setLegacyCheck(true)}
            >
              Continue Anyway
            </Button>
          )}
        </CardFooter>
      </Card>
    );
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '1rem',
          zIndex: 1051, // Any lower (even 1050) and the notifications will be hidden under modals
        }}
      >
        {notifications.map(n => (
          <Alert color={n.color} key={n.id} toggle={n.dismiss}>
            {n.message}
          </Alert>
        ))}
      </div>
      {isBeta && !flags.allowBeta ? (
        <Card>
          <CardBody>
            <CardTitle>Approval required</CardTitle>
            <CardText>
              Your account must be approved before you can use the beta.
            </CardText>
          </CardBody>
          <CardFooter>
            <Button color="primary" href="https://ushamarketplace.com">
              Switch to stable version
            </Button>
          </CardFooter>
        </Card>
      ) : approved ? (
        <div>
          <Nav
            isBeta={isBeta}
            isTest={isTest}
            shouldMigrate={['usha', 'usha_greif', 'usha_still'].includes(
              marketplace
            )}
          />
          <Container fluid style={{ paddingTop: 70 }}>
            {children}
          </Container>
          <Footer />
        </div>
      ) : (
        <Card>
          <CardBody>
            <CardTitle>Approval Required</CardTitle>
            <CardText>
              Hello, Your account must be approved before you can use
              marketplace services. Your region admin has been notified, and
              there is no further action needed.
            </CardText>
          </CardBody>
          <CardFooter>
            <Button color="primary" onClick={() => Amplify.Auth.signOut()}>
              Return to Sign In Page
            </Button>
          </CardFooter>
        </Card>
      )}
    </>
  );
});

AppContainer.propTypes = {
  children: PropTypes.node,
};

export default observer(() => {
  const { loading: cacheLoading } = useContext(CacheContext);
  const {
    userStore: { loading: userLoading },
    creditStore: { loading: creditLoading, initCredit },
  } = useContext(StoreContext);

  useEffect(() => {
    initCredit();
  }, []);

  if (cacheLoading || userLoading || creditLoading) {
    return (
      <Container style={{ marginTop: 125 }}>
        {' '}
        <LoadingComponent />{' '}
      </Container>
    );
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <AmplifyRouter
        componentOverrides={[SignIn, SignUp, ConfirmSignUp]}
        amplifyConfig={awsConfig}
        homeRoute="/"
        hide={[Greetings, Loading, SignOut]}
      >
        <AppContainer path="/">
          <Activity path="/activity" />
          <Transfers path="/agent-transfers" />
          <Home path="/" />
          <Leads path="/leads" />
          <Campaigns path="/campaigns" />
          <Campaign path="/campaign/:campaignID" />
          <Vendors path="/vendors" />
          <Account path="/account" />
          <MyAgents path="/myagents" />
          <Support path="/support" />
          <Profile path="/profile/:agentID" />
          <Help path="/support" />
          <RefundPolicy path="/refund-policy" />
          <Page404 default />
        </AppContainer>
      </AmplifyRouter>
    </DndProvider>
  );
});
