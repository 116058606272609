import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const ActivityRow = ({
  actionLabel,
  timestamp,
}) => {
  const momentTimestamp = moment(timestamp);

  return (
    <tr>
      <td>{momentTimestamp.format('YYYY-MM-DD')}</td>
      <td>{momentTimestamp.format('h:mma')}</td>
      <td>{actionLabel}</td>
    </tr>
  );
};
ActivityRow.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
};

export default ActivityRow;