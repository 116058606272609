import { observable, action, decorate } from 'mobx';
import dayjs from 'dayjs';

class TableStore {
  table = {
    loading: true,
    scrollID: undefined,
    total: null,
    pageData: [],
    aggregations: null,
    searchPayload: null,
    search: {
      searchText: '',
      startDate: dayjs()
        .startOf('day')
        .subtract(1, 'day'),
      endDate: dayjs().add(1, 'day'),
      size: 10,
      descending: true
    },
  };

  clearSearch = () => {
    this.table.scrollID = undefined;
    this.table.total = null;
    this.table.pageData = [];
    this.table.aggregations = {}
    this.table.searchPayload = {}
    this.table.search = {
      searchText: '',
      startDate: dayjs()
        .startOf('day')
        .subtract(1, 'day'),
      endDate: dayjs().add(1, 'day'),
      size: 10,
    }
  }
  
  onSearch = newSearch => {
    console.log('onSearch t')
      console.log(newSearch)
      this.table.scrollID = undefined;
      this.table.pageData = [];
      this.table.aggregations = {}
    //  this.table.size = 10
      this.table.loading = true;
      this.table.search ={

          ...this.table.search,
          ...newSearch
      }
  }

  updateTableLoading = ({loading}) =>{

    this.table.loading = loading;
  }

  updateTableItems = ({ total, pageData, scrollID, aggregations = {}, searchPayload }) => {
    console.log(searchPayload)
    
    this.table.total = total;
    this.table.aggregations = aggregations || {}
    this.table.pageData = pageData;
    this.table.scrollID = scrollID;
    this.table.loading = false;
    this.table.searchPayload = searchPayload || this.table.searchPayload;
  }

}

decorate(TableStore, {
  table: observable,
  onSearch: action,
  clearSearch: action
});

export default TableStore;
