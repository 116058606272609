import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardLink,
  CardText,
  CardTitle,
  Table,
} from 'reactstrap';

import ActivityRow from './ActivityRow';

import { LoadingComponent } from '../../shared/helpers/helpers';
import StoreContext from '../../stores';

const ActivityPage = () => {
  const {
    activityStore: {
      hasLoadingErrors,
      list: activities,
      sync: syncActivities,
      loading,
    },
  } = React.useContext(StoreContext);

  useEffect(() => {
    syncActivities();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  if (hasLoadingErrors) {
    return (
      <Card>
        <CardBody>
          <CardTitle>Error</CardTitle>
          <CardText>There was an error loading your activities. Please try again.</CardText>
        </CardBody>
        <CardFooter>
          <CardLink href="/">Return to home page</CardLink>
        </CardFooter>
      </Card>
    );
  }
  return (
    <Table
      className="text-right"
      hover
      striped
    >
      <thead>
        <tr>
          <th style={{ padding: '21px 12px' }}>
            <h5>Activity Log</h5>
          </th>
          <th />
          <th style={{ width: '70%' }} />
        </tr>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {activities.map(a => (
          <ActivityRow
            actionLabel={a.action_label}
            timestamp={a.timestamp}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default observer(ActivityPage);