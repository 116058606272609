import { API } from 'aws-amplify';
import Local from './Local';

const api = process.env.REACT_APP_APP_ENVIROMENT === 'development' ? new Local() : API;

export default {
  get: (apiName, path) => api.get(apiName, path),
  post(apiName, path, myInit) {
    const assumedIdentityID = sessionStorage.getItem('assumedUserID');

    if (assumedIdentityID !== undefined && myInit.body !== undefined) {
      myInit.body.assumedIdentityID = assumedIdentityID;
    }
    return api.post(apiName, path, myInit);
  },
  put(apiName, path, myInit) {
    const assumedIdentityID = sessionStorage.getItem('assumedUserID');

    if (assumedIdentityID !== undefined && myInit.body !== undefined) {
      myInit.body.assumedIdentityID = assumedIdentityID;
    }
    return api.put(apiName, path, myInit);
  },
}
