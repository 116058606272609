/* eslint-disable */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
} from 'reactstrap';
import { FaLock } from 'react-icons/fa';
import { LoadingComponent } from '../shared/helpers/helpers';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  injectStripe,
  PostalCodeElement,
  StripeProvider,
} from 'react-stripe-elements';

import awsmobile from '../aws-exports';

const handleBlur = () => {
  console.log('[blur]');
};

const handleChange = change => {
  console.log('[change]', change);
};

const handleClick = () => {
  console.log('[click]');
};

const handleFocus = () => {
  console.log('[focus]');
};

const handleReady = () => {
  console.log('[ready]');
};

const createOptions = (fontSize, padding) => ({
  style: {
    base: {
      color: '#32325d',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      ':-webkit-autofill': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
      ':-webkit-autofill': {
        color: '#fa755a',
      },
    },
  },
});

class _SplitForm extends React.Component {
  handleSubmit = ev => {
    const {
      addCard,
      stripe,
    } = this.props;

    ev.preventDefault();

    if (stripe) {
      stripe
        .createToken()
        .then(payload => {
          console.log('[_CardForm]', payload);
          addCard(payload.token);
        });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  }

  render() {
    const { fontSize, loading } = this.props
   
    return (
      <Card body>
        <form
          className="d-flex flex-column"
          onSubmit={this.handleSubmit}
        >
          <label>
            <span style={{ marginRight: '10px' }}>Card number</span>
            <img
              alt="Credit Card Logos"
              src="https://www.merchantequip.com/image/?logos=v%7Cm%7Ca%7Cd&height=32(5 kB)
  https://www.merchantequip.com/image/?logos=v%7Cm%7Ca%7Cd&height=32"
              width="152"
            />
            <CardNumberElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(fontSize)}
            />
          </label>
          <label>
            Expiration date
            <CardExpiryElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(fontSize)}
            />
          </label>
          <label>
            CVC
            <CardCVCElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(fontSize)}
            />
          </label>
          <label>
            Postal code
            <PostalCodeElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              {...createOptions(fontSize)}
            />
          </label>
          {loading ? <LoadingComponent /> : (
            <Button color="success">
              <span style={{ marginRight: '10px' }}>Save Card</span>
              <FaLock />
            </Button>
          )}
        </form>
      </Card>
    );
  }
}

const SplitForm = injectStripe(_SplitForm);

class Checkout extends React.Component {
  constructor() {
    super();

    this.state = {
      elementFontSize: window.innerWidth < 450 ? '18px' : '24px',
    };
    window.addEventListener('resize', () => {
      if (window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
        this.setState({ elementFontSize: '14px' });
      } else if (
        window.innerWidth >= 450 &&
        this.state.elementFontSize !== '18px'
      ) {
        this.setState({ elementFontSize: '18px' });
      }
    });
  }
  
  render() {
    return (
      <div>
        <Elements>
          <SplitForm {...this.props} fontSize={16} />
        </Elements>
      </div>
    );
  }
}

export default class Payment extends Component {
  state = {
    stripeDoneLoading: false,
  };

  async componentDidMount() {
    const { marketplace } = this.props;

    // componentDidMount only runs in a browser environment.
    // In addition to loading asynchronously, this code is safe to server-side render.
    // You can inject a script tag manually like this,
    // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
    const stripeJs = document.createElement('script');

    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.addEventListener('load', () => {
      // The setTimeout lets us pretend that Stripe.js took a long time to load
      // Take it out of your production code!
      setTimeout(() => {
        this.setState({
          stripeDoneLoading: true,
          stripe: window.Stripe(this.getKey()),
        });
      }, 500);
    });
    document.body && document.body.append(stripeJs);
  }

  getKey = () => {
    const { marketplace } = this.props;
    const { 'aws_user_pools_id': pool } = awsmobile

    if(pool === process.env.REACT_APP_USER_POOL_PROD ){
      return marketplace === 'sonic' ?
      process.env.REACT_APP_SONIC_STRIPE_PK :
      process.env.REACT_APP_USHA_STRIPE_PK
    }else{
      return marketplace === 'sonic' ?
      process.env.REACT_APP_SONIC_STRIPE_TEST_PK :
      process.env.REACT_APP_USHA_STRIPE_TEST_PK
    }
  }

  render() {
    const { marketplace } = this.props;
    const { stripeDoneLoading } = this.state;

    if (stripeDoneLoading) {
      return (
        <Card style={{ maxWidth: 550}}>
          <StripeProvider apiKey={this.getKey()}
          >
            <Checkout {...this.props} />
          </StripeProvider>
        </Card>
      );
    }
    return null;
  }
};

Payment.propTypes = {
  marketplace: PropTypes.oneOf(['sonic', 'usha']),
};