import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
} from 'reactstrap';
import siteInfomap from '../../../resources/site_infomap.png';
import campaignInfomap from '../../../resources/campaign-setup.png';
import { CenterComponent } from "../../../shared/helpers/helpers";

const Help = ({ navigate }) => {
  const [activeImage, setActiveImage] = useState('new');

  return (
    <div style={{ marginTop: 30 }}>
      <CenterComponent>
        <Button
          color="link"
          disabled={activeImage === 'new'}
          onClick={() => {
            setActiveImage('new');
          }}
        >
          Getting Started
        </Button>
        <Button
          color="link"
          disabled={activeImage === 'campaign'}
          onClick={() => {
            setActiveImage('campaign');
          }}
        >
          Setup a Campaign
        </Button>
        <Button
          color="link"
          onClick={() => {
            navigate('/support');
          }}
        >
          Contact Us
        </Button>
      </CenterComponent>
      <br />
      <CenterComponent>
        <h2>{activeImage === 'new' ? 'Getting Started' : 'Campaign Setup'}</h2>
      </CenterComponent>
      <CenterComponent>
        <img
          alt="Site Infomap"
          src={activeImage === 'new' ? siteInfomap : campaignInfomap}
          width="75%"
        />
      </CenterComponent>
    </div>
  );
};
Help.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default Help; 
