import React from 'react';
import { Container, Button } from 'reactstrap';

const packageJson = require('../../../package.json');

const appVersion = packageJson.version;

const Footer = () => {
  return (
    <Container fluid className="d-flex flex-row justify-content-center align-items-center my-3">
        <span>
          {`Version ${appVersion}`}
        </span>
          <Button
            href="https://app.termly.io/document/privacy-policy/e106eeca-5e2c-414d-8ec6-48a7a8536592"
            rel="noopener noreferrer"
            target="_blank"
            color="link"
          >
            Privacy Policy
          </Button>
    </Container>
  );
};

export default Footer;
