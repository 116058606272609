import * as mobxReactLite from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from 'availity-reactstrap-validation';
import API from '../../shared/api';
import Levels from './Levels';
import Destinations from '../Destinations';
import { LoadingComponent } from '../../shared/helpers/helpers';
import StoreContext from '../../stores';

const REGIONS = [
  'AL',
  'AZ',
  'AR',
  'CO',
  'FL',
  'GA',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'MD',
  'MI',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NC',
  'OH',
  'OK',
  'PA',
  'SC',
  'TN',
  'TX',
  'UT',
  'VA',
  'WI',
  'WV',
  'WY',
];
const SONIC_REGIONS = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const AGE_RANGES = [
  'Younger than 18',
  '18 - 26',
  '27 - 38',
  '39 - 50',
  '51 - 62',
  'Older than 62',
];

// 1000 milliseconds in a second * 86400 seconds in a day * 365 days a year * 2 years
const TWO_YEARS_IN_MILLISECONDS = 1000 * 86400 * 365 * 2;

const Form = mobxReactLite.observer(props => {
  const {
    campaign,
    navigate,
    campaignID: existingCampaignID,
    refresh,
    viewLoading,
  } = props;
  const {
    notificationStore,
    userStore: { user },
  } = useContext(StoreContext);
  const { marketplace, flags } = user;

  const restrictedLeadTiers = user.restrictedLeadTiers || [];

  const allowedLeadTiers = [...user.allowedLeadTiers, ...restrictedLeadTiers];
  const { allowProportionalDestinations } = flags;

  const {
    campaignName,
    destinations: selectedDestinations = [],
    myAgentDestinations: selectedAgentDestinations = [],
    filters,
    fixedCampaignName,
    excludeContactOwner,
    proportionalDestination,
  } = campaign;
  const isNew = existingCampaignID === 'new';
  const [loading, setLoading] = useState(false);
  const [destinations, setDestinations] = useState({
    destinations: selectedDestinations,
    myAgentDestinations: selectedAgentDestinations,
  });
  const [resultMessage, setMessage] = useState();
  const [modal, setModal] = useState(false);
  const [selectedCampaignName, setSelectedCampaignName] = useState(
    fixedCampaignName
  );
  const [tiers, setTiers] = useState([]);
  const [regions] = useState(() => {
    return marketplace === 'sonic' ? SONIC_REGIONS : REGIONS;
  });

  const toggle = () => {
    setModal(!modal);
  };

  const getRegions = filters => {
    if (!filters) {
      return [];
    }

    const { topLevel } = filters;
    const regionFilterFound = topLevel.filter(
      item =>
        item.field ===
        (marketplace === 'sonic' ? 'region' : `region:${marketplace}`)
    );
    let regions = [];

    if (regionFilterFound.length > 0) {
      regions = [...regionFilterFound[0].include];
    }
    return regions;
  };

  const getAgeRanges = filters => {
    if (!filters) {
      return [];
    }

    const { topLevel } = filters;
    const ageFilterFound = topLevel.filter(
      item =>
        item.field ===
        (marketplace === 'sonic' ? 'ageRange' : `ageRange:${marketplace}`)
    );
    let ageRanges = [];

    if (ageFilterFound.length > 0) {
      ageRanges = [...ageFilterFound[0].include];
    }
    return ageRanges;
  };

  const makeRegions = items =>
    items.map(item => (
      <AvCheckbox
        key={item}
        label={item}
        style={{ padding: 10 }}
        value={item}
      />
    ));

  const makeAgeRanges = items =>
    items.map(item => (
      <AvCheckbox
        key={item}
        label={item}
        style={{ padding: 10 }}
        value={item}
      />
    ));

  const makeModel = () => {
    const selected = {};

    if (campaignName) {
      selected.campaignName = campaignName;
    }

    if (excludeContactOwner) {
      selected.excludeContactOwner = excludeContactOwner;
    }

    if (proportionalDestination) {
      selected.proportionalDestination = proportionalDestination;
    }

    if (filters) {
      selected.regions = [];
      selected.ageRanges = [];

      const selectedRegions = getRegions(filters);
      const selectedAgeRanges = getAgeRanges(filters);

      for (let i = 0; i < selectedRegions.length; i += 1) {
        selected.regions.push(selectedRegions[i]);
      }
      for (let i = 0; i < selectedAgeRanges.length; i += 1) {
        selected.ageRanges.push(selectedAgeRanges[i]);
      }
    } else {
      selected.regions = [...regions];
      selected.ageRanges = [...AGE_RANGES];
    }
    return selected;
  };

  const makeFilters = ({ regions, ageRanges }) => {
    const filter = {
      topLevel: [
        {
          field: marketplace === 'sonic' ? 'vendor' : `broker`,
          include: [marketplace],
          recordType: 'lead',
        },
        {
          field: marketplace === 'sonic' ? 'region' : `region:${marketplace}`,
          include: regions,
          recordType: 'profile',
        },
      ],
    };

    /* if(marketplace === 'sonic'){
      filter.topLevel.push( {
        'field': marketplace === 'sonic' ? 'ageRange' : `ageRange:${marketplace}`,
        'include': ageRanges,
        'recordType': 'profile',
      })
    }*/

    return filter;
  };

  const saveCampaign = async payload => {
    setLoading(true);
    setMessage();

    const { campaignID } = payload;
    let response;

    try {
      if (campaignID) {
        response = await API.post(process.env.REACT_APP_API, '/campaign', {
          body: { ...payload },
        });
      } else {
        response = await API.put(process.env.REACT_APP_API, '/campaign', {
          body: { ...payload },
        });
      }

      setLoading(false);

      const { success, message, data } = response;

      if (success) {
        //  const { campaignID: id } = data;

        //  setMessage('Campaign Successfully Updated');
        // toggle();

        //  if (isNew) {
        navigate(`/campaigns`);
        // } else {
        //     refresh(id);
        //  }
      } else {
        setMessage(message);
        toggle();
      }
    } catch (error) {
      console.error(error);

      setLoading(false);

      const { message } = error;

      setMessage(message);
      toggle();
    }
  };

  const loadTiers = async () => {
    try {
      setLoading(true);

      const { data: tiers } = await API.post(
        process.env.REACT_APP_API,
        '/tiers/list',
        { body: { marketplace } }
      );
      console.log(tiers);
      console.log(allowedLeadTiers);
      /*  const allowedTiers = allowedLeadTiers.map(tier => {
          const { fixedCampaignName } = tier
            return fixedCampaignName
        })*/
      const filteredTiers = tiers
        .filter(tier => {
          const { name } = tier;
          return allowedLeadTiers.includes(name);
        })
        .sort((a, b) => (a.price < b.price ? 1 : -1));

      console.log(filteredTiers);

      setTiers(filteredTiers);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTiers();
  }, []);

  const handleTierSelection = (item, isSelect) => {
    if (isSelect) {
      const { fixedCampaignName: name } = item;
      setSelectedCampaignName(name);
    } else {
      // setSelectedCampaignName(null);
    }
  };

  const onSubmit = async (event, values) => {
    const { name = '' } = event;

    // Kicks out it it see event trigger from modal
    if (name === 'create-destination') return;

    const { proportionalDestination } = values;
    if (
      destinations.destinations.length === 0 &&
      destinations.myAgentDestinations.length === 0 &&
      !proportionalDestination
    ) {
      //  if (destinations.length === 0 && proportionalDestination === false) {
      notificationStore.create('danger', 'Destination must be selected');

      return;
    } else if (
      selectedCampaignName === undefined ||
      selectedCampaignName === null ||
      selectedCampaignName === ''
    ) {
      notificationStore.create('danger', 'Tier must be selected');

      return;
    }

    const { campaignID, ...theRest } = campaign;
    const { regions, ageRanges, ...restOfValues } = values;
    const payload = {
      ...theRest,
      ...restOfValues,
      ...destinations,
    };

    if (campaignID !== 'new') {
      payload.campaignID = campaignID;
    }

    const filters = makeFilters({ regions, ageRanges });

    const selectedTier = tiers.find(t => {
      return t.name === selectedCampaignName;
    });

    if (!selectedTier) {
      notificationStore.create('danger', 'Tier must be selected');
      return;
    } else if (selectedTier.allowedDestinationTypes) {
      console.log('selected destinations', [
        ...destinations.myAgentDestinations,
        ...destinations.destinations,
      ]);
      const invalid =
        destinations.myAgentDestinations &&
        [
          ...destinations.myAgentDestinations,
          ...destinations.destinations,
        ].some(d =>
          selectedTier.allowedDestinationTypes.every(t => !d.includes(t))
        );

      if (invalid) {
        notificationStore.create(
          'danger',
          `You can only select destinations of type ${selectedTier.allowedDestinationTypes.toString()}`
        );
        return;
      }
    }

    const newCampaign = {
      ...theRest,
      ...payload,
      maxAgeAllowed: selectedTier.allowedDestinationTypes
        ? TWO_YEARS_IN_MILLISECONDS
        : payload.maxAgeAllowed,
      filters,
      fixedCampaignName: selectedTier.name,
      campaignTierID: selectedTier.id,
    };
    saveCampaign(newCampaign);
  };
  const model = makeModel();
  // console.log(destinations);
  if (viewLoading) {
    return null;
  }
  return (
    <Container>
      <Card>
        <CardTitle
          tag="h4"
          className="pr-3 pl-3 border-bottom text-primary pb-2"
          style={{ marginTop: 20 }}
        >
          Create Campaign
        </CardTitle>
        <CardBody>
          <AvForm
            model={model}
            onValidSubmit={(event, values) => onSubmit(event, values)}
          >
            {isNew ? (
              <AvField label="Campaign Name" name="campaignName" required />
            ) : (
              <AvField
                label="Campaign Name"
                name="campaignName"
                required
                value={campaignName}
              />
            )}
            {/* <AvRadioGroup inline name="fixedCampaignName" label="Tiered Selections" required>
            {makePriceOptions()}
            </AvRadioGroup>
            <Button style={{marginBottom:10}} color="primary" onClick={() => {saveCampaign({campaignID: existingCampaignID, amount: balance * -1})}} >Delete Campaign</Button> */}
            <FormGroup>
              <h5>Tiers</h5>
              <Levels
                options={tiers}
                handleSelection={handleTierSelection}
                selected={[selectedCampaignName]}
                tiers={tiers}
              />
            </FormGroup>
            <hr />
            {/*marketplace === 'sonic' ? 
            <AvCheckboxGroup
            inline
            label="Age Ranges"
            name="ageRanges"
            required>
          {makeAgeRanges(AGE_RANGES)}
          </AvCheckboxGroup> 
          : null */}
            <hr />
            <AvCheckboxGroup
              inline
              label="States"
              name="regions"
              className="p-2"
              required
            >
              {makeRegions(regions)}
            </AvCheckboxGroup>
            <hr />
            <FormGroup>
              <h5>Destinations</h5>
              {allowProportionalDestinations ? (
                <AvField
                  label="Proportional Destination?"
                  name="proportionalDestination"
                  type="checkbox"
                />
              ) : null}
              <Destinations
                onChange={item => {
                  setDestinations(item);
                }}
                selectedDestIDs={destinations.destinations}
                selectedAgentDestIDs={destinations.myAgentDestinations}
              />
            </FormGroup>
            {/* <hr /> 
            <FormGroup> 
            <AvField
            label={'DO NOT Include Contact Owner'}
            name="excludeContactOwner"
            required
            type="checkbox"
          />
          <p><i><small>This will result leads being assigned to the user via the campaign</small></i></p>
          </FormGroup>
           <hr /> */}
            <FormGroup>
              {isNew ? (
                <AvField
                  label={
                    marketplace === 'sonic' ? (
                      <p>
                        I have read and agree to the{' '}
                        <a
                          href="https://www.sonicwebdev.com/terms-and-conditions"
                          rel="noopener noreferrer"
                          style={{ color: 'blue' }}
                          target="_blank"
                        >
                          terms and conditions
                        </a>
                        .
                      </p>
                    ) : (
                      <span>
                        By agreeing, I recognize that any leads purchased
                        through the USHA Marketplace have been offered at a
                        previously discounted market price and that no lead
                        returns will be issued accordingly. Please visit our
                        full Terms of Service and{' '}
                        <a
                          href="https://app.termly.io/document/privacy-policy/e106eeca-5e2c-414d-8ec6-48a7a8536592"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>{' '}
                        for more information about Marketplace lead services.
                      </span>
                    )
                  }
                  name="agreed"
                  required
                  type="checkbox"
                />
              ) : null}
            </FormGroup>
            <FormGroup>
              {loading ? (
                <LoadingComponent />
              ) : (
                <Button block color="primary" size="lg">
                  Submit
                </Button>
              )}
            </FormGroup>
          </AvForm>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update Result</ModalHeader>
        <ModalBody>
          <p>{resultMessage}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
});

Form.propTypes = {
  onValidSubmit: PropTypes.func,
  onInvalidSubmit: PropTypes.func,
  destinationType: PropTypes.string,
  destinationID: PropTypes.string,
  fetchObjectQuery: PropTypes.func,
  saveObjectQuery: PropTypes.func,
};

export default Form;
