import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    Button,
    ButtonGroup,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
} from 'reactstrap';

import MoneyInput from '../../../shared/components/MoneyInput';

import {
    getSpendingPeriodString,
    minutesToTime,
    timeToMinutes
} from '../Utils';
import { formatCurrencyForDisplay } from '../../MyAgents/Utils';

const SpendingPeriod = ({
    dailySpendLimit,
    dowSun,
    dowMon,
    dowTue,
    dowWed,
    dowThu,
    dowFri,
    dowSat,
    endTime,
    startTime,
    period,
    removeSpendingPeriod,
    setSpendingPeriods,
    spendingPeriods,
    throttleNoMoreThan = 1,
    throttleLeadsPer = 60000
}) => {
    const MIN_DAILY_SPEND = 100;

    const checkSpendLimit = dailySpendLimit < MIN_DAILY_SPEND;

    const isThrottleZero = throttleNoMoreThan === 0 || throttleLeadsPer === 0;

    const [isLeadThrottling,setIsLeadThrottling] = useState(!isThrottleZero);

    const setStartTime = minutes => {
        const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));

        nextSpendingPeriods[period].startTime = minutes;
        setSpendingPeriods(nextSpendingPeriods);
    };

    const setEndTime = minutes => {
        const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));

        nextSpendingPeriods[period].endTime = minutes;
        setSpendingPeriods(nextSpendingPeriods);
    };

    const setSpendingLimit = limit => {
        const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));

        nextSpendingPeriods[period].dailySpendLimit = limit;
        setSpendingPeriods(nextSpendingPeriods);
    };

    const setDelaySpendBy = (field, value) => {
            
        console.log(field,value);
        const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));
        nextSpendingPeriods[period][field] = value;
        const delaySpendBy = Math.round(nextSpendingPeriods[period].throttleLeadsPer  /  nextSpendingPeriods[period].throttleNoMoreThan);
        
        nextSpendingPeriods[period].delaySpendBy = delaySpendBy
        
        JSON.parse(JSON.stringify(nextSpendingPeriods));
        setSpendingPeriods(nextSpendingPeriods);
    };

    const toggleSpendingPeriodDow = dow => {
        const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));
    
        nextSpendingPeriods[period][`dow${dow}`] = !spendingPeriods[period][`dow${dow}`];
        setSpendingPeriods(nextSpendingPeriods);
    };

    const throttleLeadsPerChangeHandler = value => {
        // Does not allow user to manually type anything below 1 or above 60
        if(value > 60 || !value === "") return
        
        setDelaySpendBy('throttleLeadsPer', value * 60000)
        
    }

    const setThrottle = command => {

        const nextSpendingPeriods = JSON.parse(JSON.stringify(spendingPeriods));

        switch(command) {
            case 'reset':
            nextSpendingPeriods[period].throttleLeadsPer = 0;
            nextSpendingPeriods[period].throttleNoMoreThan = 0;
            break;
            case 'default':
            nextSpendingPeriods[period].throttleLeadsPer = 60000;
            nextSpendingPeriods[period].throttleNoMoreThan = 1;
            break;
            default:
        }
        const delaySpendBy = Math.round(nextSpendingPeriods[period].throttleLeadsPer  /  nextSpendingPeriods[period].throttleNoMoreThan);
        
        nextSpendingPeriods[period].delaySpendBy = delaySpendBy
        
        JSON.parse(JSON.stringify(nextSpendingPeriods));
        setSpendingPeriods(nextSpendingPeriods);
    } 

    const toggleLeadThrottling = () => {
        if(!isLeadThrottling) {
            setThrottle('default');
        } else {
            setThrottle('reset');
        }
        setIsLeadThrottling(!isLeadThrottling);
    }

    const throttleNoMoreThanChangeHandler = value => {

        // Makes sure that input is a valid character
        if(!value.match(/([1-9]([0-9])*|^$)/)) return;

        setDelaySpendBy('throttleNoMoreThan', value)
    }


    const getLeadThrottlingInput = () => <InputGroup style={{ paddingTop: 12 }}>
                <InputGroupAddon addonType="prepend">No More than</InputGroupAddon>
                <Input
                    onChange={e => throttleNoMoreThanChangeHandler(e.target.value)}
                    onBlur={e => setDelaySpendBy('throttleNoMoreThan', e.target.value || "1")}
                    min={1} 
                    max={60}
                    step={1}
                    type="number"
                    value={throttleNoMoreThan}
                />
                <InputGroupAddon addonType="prepend">lead(s) per </InputGroupAddon>
                <Input
                    onChange={e => throttleLeadsPerChangeHandler(e.target.value)}
                    onBlur={e => setDelaySpendBy('throttleLeadsPer', e.target.value * 60000|| "60000")}
                    min={1} 
                    max={60}
                    step={1}
                    type="number"
                    value={throttleLeadsPer/60000 || ""}
                />
                <InputGroupAddon addonType="prepend">min </InputGroupAddon>
    </InputGroup>;

    
    return (
        <tr>
            <td>
                <FormGroup>
                    <Label>{getSpendingPeriodString(spendingPeriods[period])}</Label>
                    <InputGroup style={{ paddingTop: 12 }}>
                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                        <MoneyInput
                            minimum={MIN_DAILY_SPEND}
                            setValue={value => setSpendingLimit(value)}
                            value={dailySpendLimit}
                        />
                    </InputGroup>
                    <FormText
                        color="danger"
                        style={{
                        height: '19px',
                        position: 'relative',
                        }}
                    >
                        <span style={{
                            position: 'absolute',
                            right: 0,
                            whiteSpace: 'nowrap',
                        }}>
                        { checkSpendLimit ? `The minimum daily spend per period is ${formatCurrencyForDisplay(MIN_DAILY_SPEND)}` : ''}
                    </span>
                    </FormText>
                </FormGroup>
                <ButtonGroup style={{ padding: '12px 0' }}>
                    <Button
                        active={dowSun}
                        color={dowSun ? 'success' : 'secondary'}
                        onClick={() => toggleSpendingPeriodDow('Sun')}
                        outline={!dowSun}
                    >
                        Sun
                    </Button>
                    <Button
                        active={dowMon}
                        color={dowMon ? 'success' : 'secondary'}
                        onClick={() => toggleSpendingPeriodDow('Mon')}
                        outline={!dowMon}
                    >
                        Mon
                    </Button>
                    <Button
                        active={dowTue}
                        color={dowTue ? 'success' : 'secondary'}
                        onClick={() => toggleSpendingPeriodDow('Tue')}
                        outline={!dowTue}
                    >
                        Tue
                    </Button>
                    <Button
                    active={dowWed}
                    color={dowWed ? 'success' : 'secondary'}
                    onClick={() => toggleSpendingPeriodDow('Wed')}
                    outline={!dowWed}
                    >
                        Wed
                    </Button>
                    <Button
                        active={dowThu}
                        color={dowThu ? 'success' : 'secondary'}
                        onClick={() => toggleSpendingPeriodDow('Thu')}
                        outline={!dowThu}
                    >
                        Thu
                    </Button>
                    <Button
                        active={dowFri}
                        color={dowFri ? 'success' : 'secondary'}
                        onClick={() => toggleSpendingPeriodDow('Fri')}
                        outline={!dowFri}
                    >
                        Fri
                    </Button>
                    <Button
                        active={dowSat}
                        color={dowSat ? 'success' : 'secondary'}
                        onClick={() => toggleSpendingPeriodDow('Sat')}
                        outline={!dowSat}
                    >
                        Sat
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => removeSpendingPeriod(period)}
                    >
                        Remove
                    </Button>
                </ButtonGroup>
                <InputGroup style={{ padding: '12px 0' }}>
                    <InputGroupAddon addonType="prepend">from</InputGroupAddon>
                        <Input
                            onChange={e => setStartTime(timeToMinutes(e.target.value))}
                            type="time"
                            value={minutesToTime(startTime)}
                        />
                        <InputGroupAddon
                            addonType="append"
                            style={{ marginRight: '-1px' }}
                        >
                            to
                        </InputGroupAddon>
                        <Input
                            onChange={e => setEndTime(timeToMinutes(e.target.value))}
                            type="time"
                            value={minutesToTime(endTime)}
                        />
                        <InputGroupAddon addonType="append" >
                            {startTime >= endTime ? 'next day' : 'that day'}
                        </InputGroupAddon>
                </InputGroup>
                <FormGroup>
                    <Label>Lead Throttling</Label>
                    &nbsp;
                    <input
                        name="lead-throttling-checkbox"
                        type="checkbox"
                        checked={isLeadThrottling}
                        onChange={toggleLeadThrottling} 
                    />
                    { isLeadThrottling && getLeadThrottlingInput() }
                </FormGroup> 
            </td>
        </tr>
    );
}

SpendingPeriod.propTypes = {
        spendingPeriods: PropTypes.arrayOf(PropTypes.shape({
            dailySpendLimit: PropTypes.number,
            dowSun: PropTypes.bool,
            dowMon: PropTypes.bool,
            dowTue: PropTypes.bool,
            dowWed: PropTypes.bool,
            dowThu: PropTypes.bool,
            dowFri: PropTypes.bool,
            dowSat: PropTypes.bool,
            endTime: PropTypes.number,
            id: PropTypes.string,
            startTime: PropTypes.number,
        })),
        removeSpendingPeriod: PropTypes.func.isRequired,
        setSpendingPeriods: PropTypes.func.isRequired,
        dailySpendLimit: PropTypes.number.isRequired,
        dowSun: PropTypes.bool.isRequired,
        dowMon: PropTypes.bool.isRequired,
        dowTue: PropTypes.bool.isRequired,
        dowWed: PropTypes.bool.isRequired,
        dowThu: PropTypes.bool.isRequired,
        dowFri: PropTypes.bool.isRequired,
        dowSat: PropTypes.bool.isRequired,
        endTime: PropTypes.number.isRequired,
        startTime: PropTypes.number.isRequired,
        period: PropTypes.number.isRequired,
        throttleNoMoreThan: PropTypes.number,
        throttleLeadsPer: PropTypes.number
};

export default SpendingPeriod;