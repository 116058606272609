import RandomAlpha from 'alphanumeric';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';

import ScreenDefault from './screens/ScreenDefault';
import ScreenSuccess from './screens/ScreenSuccess';

import { CenterComponent } from '../../../../shared/helpers/helpers';
import StoreContext from '../../../../stores';

import {
  DEFAULT_STEP_STATE,
  MS_PER_DAY,
  MS_PER_99_YEARS,
  PHONE_DUPE_MAX_DAYS,
  PHONE_DUPE_MIN_DAYS,
  STEP_CLEAN_NAME,
  STEP_DELIVERY_NAME,
  STEP_QUALITY_NAME,
  STEP_REJECT_NAME,
  STEP_VENDOR_NAME,
} from '../../constants';

const DEFAULT_STEP_NUMBER = 1;
const DEFAULT_SUBMENU = null;
const DEFAULT_VISITED_STEPS = [];



const VendorCampaignModal = ({
  baseStepState,
  dismiss,
  id,
  isOpen,
}) => {
  const {
    userStore,
    vendorCampaignStore,
  } = React.useContext(StoreContext);

  const [saveState, setSaveState] = React.useState('default');
  const [savedVC, setSavedVC] = React.useState('default');
  const [openSubmenu, dangerouslySetOpenSubmenu] = React.useState(DEFAULT_SUBMENU);
  const [stepNumber, dangerouslySetStepNumber] = React.useState(DEFAULT_STEP_NUMBER);
  const [stepState, dangerouslySetStepState] = React.useState(DEFAULT_STEP_STATE);
  const [visitedSteps, setVisitedSteps] = React.useState(DEFAULT_VISITED_STEPS);


  React.useEffect(() => {
    console.log(baseStepState)
    console.log(vendorCampaignStore.list)
    console.log(id)

    const currentVendorCampaign = vendorCampaignStore.list.find(
      vendorCampaign => vendorCampaign.layerID === id,
    );
    const existingSteps = {...baseStepState}
    const steps = Object.keys(baseStepState)
    console.log(currentVendorCampaign)
   steps.forEach(step => {
   
        const keys = Object.keys(baseStepState[step])
       
        keys.forEach(key => {
         // if(key in currentVendorCampaign){
          //  existingSteps[step][key] = currentVendorCampaign[key]
        //  }
        })
    })
    console.log(existingSteps)
    dangerouslySetStepState(existingSteps);
  }, [id]);



  const setStepNumber = number => {
    setVisitedSteps(visitedSteps.concat([number]));
    dangerouslySetStepNumber(number);
  };

  const cleanupAndDismiss = () => {
    // We want people to see the confirmation page so we'll block dismissal
    // until after the save is complete.
    if (vendorCampaignStore.synced) {
      dangerouslySetOpenSubmenu(DEFAULT_SUBMENU);
      dangerouslySetStepNumber(DEFAULT_STEP_NUMBER);
      dangerouslySetStepState(DEFAULT_STEP_STATE);
      setSaveState('default');
      setVisitedSteps(DEFAULT_VISITED_STEPS);
      dismiss();
    }
  };

  const setOpenSubmenu = submenuName => {
    dangerouslySetOpenSubmenu(openSubmenu === submenuName ? DEFAULT_SUBMENU : submenuName);
  };

  const setStepStateField = (step, ...fieldsAndValues) => {
    const stateUpdate = {};

    for (let i = 0; i < fieldsAndValues.length; i += 2) {
      stateUpdate[fieldsAndValues[i]] = fieldsAndValues[i + 1];
    }

    dangerouslySetStepState(Object.assign({}, stepState, {
      [step]: Object.assign({}, stepState[step], stateUpdate),
    }));
  };

  const isNew = id === '';

  const save = async () => {
    try {
      const updatedVC = {
        destinations: stepState[STEP_DELIVERY_NAME].destinationIDs,
        myAgentDestinations: stepState[STEP_DELIVERY_NAME].myAgentDestinationIDs,
        vendorID: stepState[STEP_VENDOR_NAME].selectedVendor.vendorID,
        layerID: isNew ?
          RandomAlpha(8) :
          id,
        blockDisconnected: stepState[STEP_QUALITY_NAME].leadQualityTier === 'check_phone',
        blockDuplicates: stepState[STEP_CLEAN_NAME].checkForDuplicates,
        buyer: userStore.user.buyerID,
        company: userStore.user.companyID,
        corp: userStore.user.corpID,
        duplicateLevel: 'corp',
        // duplicateLevel: `corp@${userStore.user.network.brokerID}`,
        enhanceProfile: stepState[STEP_QUALITY_NAME].leadQualityTier === 'enhance_profile_check_phone',
        leadType: (userStore.user.flags || {}).allowMarketplaceBrokering ? 'vendor' : 'agentDirect',
        marketplace: userStore.user.marketplace,
        networkID: userStore.user.networkID,
        minimumRefresh: stepState[STEP_CLEAN_NAME].phoneDupeMode === 'forever' ?
          MS_PER_99_YEARS :
          stepState[STEP_CLEAN_NAME].phoneDupeDays * MS_PER_DAY,
        passDuplicates: stepState[STEP_CLEAN_NAME].dupeSubmissionMode === 'allow',
        qualifier: 'standard',
        vendor: stepState[STEP_VENDOR_NAME].selectedVendor.vendorID,
        vendorLayer: 'main',
        vendorName: stepState[STEP_VENDOR_NAME].selectedVendor.vendorName,
        verticalType: 'health',
        vendorPrice: (stepState[STEP_VENDOR_NAME].defaultLeadPrice / 100).toFixed(2),
        vendorSourceCampaign: stepState[STEP_VENDOR_NAME].vendorCampaignName,
        rejectDisconnected: stepState[STEP_REJECT_NAME].rejectDisconnected,
        rejectDuplicates: stepState[STEP_REJECT_NAME].rejectDuplicates,
      };

      if (!isNew) {
      //  updatedVC.id = id;
      }
      vendorCampaignStore.create(updatedVC);
      setSaveState('complete');
      setSavedVC(updatedVC)
    } catch (error) {
      console.error(error);
      setSaveState('error');
    }
  };

  const userCanSeeReject = (userStore.user.flags || {}).allowBrokerRejects;
  const userCanSeeDelivery = !(userStore.user.flags || {}).allowMarketplaceBrokering;

  const steps = [
    {
      complete:
        stepState[STEP_VENDOR_NAME].selectedVendor !== null &&
        typeof stepState[STEP_VENDOR_NAME].vendorCampaignName === 'string' &&
        stepState[STEP_VENDOR_NAME].vendorCampaignName.length > 0, // TODO: Make all of these dynamic
      title: STEP_VENDOR_NAME,
      valid: (
        vendorCampaignStore.list.find(
          vendorCampaign =>
            !!stepState[STEP_VENDOR_NAME].vendorCampaignName &&
            stepState[STEP_VENDOR_NAME].selectedVendor &&
            stepState[STEP_VENDOR_NAME].selectedVendor.vendorID === vendorCampaign.vendorID &&
            vendorCampaign.vendorSourceCampaign === stepState[STEP_VENDOR_NAME].vendorCampaignName &&
            (
              isNew ||
              vendorCampaign.layerID !== id
            ),
        ) === undefined &&
        stepState[STEP_VENDOR_NAME].defaultLeadPrice >= 0
      ),
      visible: true,
    },
    {
      complete: !isNew || visitedSteps.includes(2),
      title: STEP_QUALITY_NAME,
      valid: true,
      visible: true,
    },
    {
      complete: (
        (!isNew || visitedSteps.includes(3)) &&
        (stepState[STEP_CLEAN_NAME].phoneDupeMode === 'forever' || stepState[STEP_CLEAN_NAME].phoneDupeDays !== '')
      ),
      title: STEP_CLEAN_NAME,
      valid: (
        stepState[STEP_CLEAN_NAME].phoneDupeMode === 'forever' || (stepState[STEP_CLEAN_NAME].phoneDupeDays >= PHONE_DUPE_MIN_DAYS && stepState[STEP_CLEAN_NAME].phoneDupeDays <= PHONE_DUPE_MAX_DAYS)
      ),
      visible: true,
    },
    {
      complete: (
        userCanSeeReject &&
        (!isNew || visitedSteps.includes(4)) &&
        (stepState[STEP_REJECT_NAME].acceptsTermsAndConditions || (
          !stepState[STEP_REJECT_NAME].rejectDisconnected &&
          !stepState[STEP_REJECT_NAME].rejectDuplicates
        ))
      ),
      title: STEP_REJECT_NAME,
      valid: true,
      visible: userCanSeeReject && !!stepState[STEP_VENDOR_NAME].selectedVendor,
    },
    {
      complete: (
        (!isNew || visitedSteps.includes(userCanSeeReject ? 5 : 4)) &&
        (stepState[STEP_DELIVERY_NAME].destinationIDs.length > 0 || stepState[STEP_DELIVERY_NAME].myAgentDestinationIDs.length > 0)
      ),
      title: STEP_DELIVERY_NAME,
      valid: true,
      visible: userCanSeeDelivery,
    },
  ].filter(
    step => step.visible,
  );
  const currentStep = steps[stepNumber - 1];
  const canSave = steps.filter(
    step => step.valid && step.complete,
  ).length === steps.length;

  return (
    <Modal
      isOpen={isOpen}
      toggle={cleanupAndDismiss}
    >
      <ModalHeader toggle={cleanupAndDismiss}>
        {isNew ? 'New' : 'Edit'} Vendor Campaign
      </ModalHeader>
      <ModalBody>
        {saveState === 'default' && (
          <ScreenDefault
            currentStep={currentStep}
            id={id}
            isNew={isNew}
            openSubmenu={openSubmenu}
            setOpenSubmenu={setOpenSubmenu}
            setStepNumber={setStepNumber}
            setStepStateField={setStepStateField}
            stepNumber={stepNumber}
            steps={steps}
            stepState={stepState}
          />
        )}
        {!vendorCampaignStore.synced && (
          <CenterComponent>
            <Spinner />
          </CenterComponent>
        )}
        {(vendorCampaignStore.synced && saveState === 'complete') && (
          <ScreenSuccess
            isNew={isNew}
            stepState={stepState}
            savedVC={savedVC}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {saveState === 'default' && (
          <ButtonGroup>
            <Button
              disabled={stepNumber <= 1}
              onClick={() => setStepNumber(stepNumber - 1)}
            >
              Prev
            </Button>
            <Button
              disabled={stepNumber >= steps.length}
              onClick={() => setStepNumber(stepNumber + 1)}
            >
              Next
            </Button>
          </ButtonGroup>
        )}
        <ButtonGroup>
          <Button onClick={cleanupAndDismiss}>
            Dismiss
          </Button>
          {canSave && saveState === 'default' && (
            <Button
              color="success"
              onClick={save}
            >
              {isNew ? 'Create' : 'Save'} Vendor Campaign
            </Button>
          )}
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

VendorCampaignModal.propTypes = {
  baseStepState: PropTypes.object.isRequired,
  dismiss: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default observer(VendorCampaignModal);