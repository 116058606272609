import openSocket from 'socket.io-client';

const callbacks = {};
let socket;

export const listen = (channel, callback) => {
  if (!callbacks[channel]) {
    callbacks[channel] = [];
  }
  callbacks[channel].push(callback);

  if (socket !== undefined) {
    socket.on(channel, callback);
  }
};

export const open = userID => {
  socket = openSocket(
    `${process.env.REACT_APP_API_NOTIFICATION_SERVER_URL}?userID=${userID}`
  );

  Object.keys(callbacks).forEach(channel => {
    callbacks[channel].forEach(callback => {
      socket.on(channel, callback);
    });
  });
};
