import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { formatCurrencyForDisplay } from '../MyAgents/Utils';

const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const ChargeRow = ({
  amount,
  chargeStatus,
  currency,
  last4,
  timestamp,
}) => {
  const momentTimestamp = moment(timestamp);
  const date = momentTimestamp.format('YYYY-MM-DD');
  const time = momentTimestamp.format('h:mma');
  const formattedAmount = formatCurrencyForDisplay(amount);

  let chargeIcon;

  if (chargeStatus === 'succeeded') {
    chargeIcon = <i className="fa fa-check-circle" style={{ color: '#28a745' }} />;
  } else if (chargeStatus === 'pending') {
    chargeIcon = <i className="fa fa-hourglass-start" style={{ color: '#ffc107' }} />;
  } else {
    chargeIcon = <i className="fa fa-times-circle" style={{ color: '#dc3545' }} />;
  }
  return (
    <tr>
      <td title={chargeStatus}>{chargeIcon}</td>
      <td title={chargeStatus}>{capitalize(chargeStatus)}</td>
      <td title={date}>{date}</td>
      <td title={time}>{time}</td>
      <td title={formattedAmount}>{formattedAmount}</td>
      <td title={currency}>{currency}</td>
      <td title={last4}>**** **** **** {last4}</td>
    </tr>
  );
};
ChargeRow.propTypes = {
  amount: PropTypes.number.isRequired,
  chargeStatus: PropTypes.oneOf(['failed', 'pending', 'succeeded']).isRequired,
  currency: PropTypes.oneOf(['USD']).isRequired,
  last4: PropTypes.number.isRequired,
  timestamp: PropTypes.number.isRequired,
};

export default ChargeRow;