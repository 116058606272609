import { action, decorate, observable } from 'mobx';
import uuid from 'uuidv4';

import { listen } from '../socket';
import { formatCurrencyForDisplay } from '../areas/MyAgents/Utils';

class NotificationStore {
  notifications = [];

  constructor(campaignStore, userStore) {
    listen('credit', data => {
      if (data.type === 'CREDIT_TRANSFER' && data.amount !== 0) {
        if (data.amount > 0) {
          this.create(
            'success',
            `${
              data.initiatorContext.agentName
            } transferred ${formatCurrencyForDisplay(
              data.amount
            )} to your balance.`
          );
        } else {
          this.create(
            'warning',
            `${
              data.initiatorContext.agentName
            }  withdrew ${formatCurrencyForDisplay(
              -data.amount
            )} from your balance.`
          );
        }
      } else if (data.type === 'LEAD_PURCHASE' && data.amount < 0) {
        this.create(
          'success',
          `New ${data.initiatorContext.campaignName} lead purchased: ${
            data.initiatorContext.leadName
          } (${formatCurrencyForDisplay(data.amount)}).`
        );
        campaignStore.addSpendingPeriodFunds(
          data.initiatorContext.campaignID,
          data.initiatorContext.spendingPeriodID,
          data.amount,
          data.initiatorContext.spendingPeriodPurchaseEpoch,
          data.initiatorContext.spendingPeriodPurchaseSpend,
          data.initiatorContext.delaySpendBy
        );
      } else if (data.type === 'AUTO_CREDIT') {
        this.create(
          'success',
          `${formatCurrencyForDisplay(
            data.amount
          )} was added to your account based on your automatic funding rules.`
        );
      } else if (data.type === 'MANUAL_CREDIT') {
        this.create(
          'success',
          `${formatCurrencyForDisplay(data.amount)} was added to your account.`
        );
      } else if (data.type === 'LEGACY_SYNC') {
        userStore.setLegacySync();
      } else if (data.type !== 'SELF_TRANSFER') {
        console.info('Unrecognized credit payload format', data);
      }
    });
  }

  create(color, message, timeout = 5000) {
    if (color === 'error') {
      color = 'danger';
    }
    const id = uuid();

    const dismiss = () => {
      const idx = this.notifications.findIndex(n => n.id === id);

      if (idx > -1) {
        this.notifications.splice(idx, 1);
      }
    };

    this.notifications.push({
      color,
      dismiss,
      id,
      message,
    });
    setTimeout(() => {
      dismiss();
    }, timeout);
  }
}

decorate(NotificationStore, {
  create: action,
  notifications: observable,
});

export default NotificationStore;
