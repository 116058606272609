import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';

import MoneyInput from '../../../../../shared/components/MoneyInput';
import StoreContext from '../../../../../stores';

const StepVendor = ({
  defaultLeadPrice,
  isNew,
  openSubmenu,
  selectedVendor,
  setDefaultLeadPrice,
  setOpenSubmenu,
  setSelectedVendor,
  setVendorCampaignName,
  //vendorCampaignID,
  vendorCampaignName,
}) => {
  const {
    userStore,
    vendorCampaignStore,
    vendorStore,
  } = React.useContext(StoreContext);

  console.log(vendorCampaignName)
  console.log(!isNew)

  return (
    <>
      <h5>Connect to a Lead Vendor</h5>
      <FormGroup>
        <Label for="vendor">Vendor</Label>
        <Dropdown
          id="vendor"
          isOpen={isNew && openSubmenu === 'vendor'}
          toggle={() => setOpenSubmenu('vendor')}
         // disabled={!isNew}
         // active={isNew}
        >
          <DropdownToggle caret>
            {selectedVendor ? selectedVendor.vendorName : 'Select a vendor'}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Vendor name (vendorID)</DropdownItem>
            <DropdownItem divider />
              {[...vendorStore.list]
                // If an agent is allowed to broker through the marketplace,
                // all vendors will be allowed.
                // Otherwise, only those vendors marked as allowing direct
                // agent sales will be allowed.
                .filter(
                  vendor =>
                    (userStore.user.flags || {}).allowMarketplaceBrokering ||
                    (vendor.flags || {}).allowAgentDirect,
                )
                .sort(
                  (a, b) => {
                    if (a.vendorName.toLowerCase() > b.vendorName.toLowerCase()) {
                      return 1;
                    } else if (
                      a.vendorName.toLowerCase() === b.vendorName.toLowerCase() &&
                      a.vendorID.toLowerCase() > b.vendorID.toLowerCase()
                    ) {
                      return 1;
                    }
                    return -1;
                  },
                ).map(
                  leadVendor => (
                    <DropdownItem
                      disabled={selectedVendor ? selectedVendor.vendorID === leadVendor.vendorID : false}
                      key={leadVendor.vendorID}
                      onClick={() => setSelectedVendor(leadVendor)}
                    >
                      {leadVendor.vendorName} <small className="text-muted">({leadVendor.vendorID})</small>
                    </DropdownItem>
                  ),
                )}
          </DropdownMenu>
        </Dropdown>
      </FormGroup>
      <FormGroup>
        <Label for="vendorCampaignName">Vendor Campaign Name</Label>
        <Input
          id="vendorCampaignName"
          invalid={!(/^[a-zA-Z0-9_-\s]*$/.test(vendorCampaignName))}
         /* invalid={!!vendorCampaignStore.list.find(
            vendorCampaign => (
              vendorCampaign.vendorSourceCampaign === vendorCampaignName &&
              (isNew || vendorCampaign.id !== vendorCampaignID)
          ))}*/ //could be duplicated but not recommended. The layer id makes it uniqie and the id is used in the queue
          onChange={e => setVendorCampaignName(e.target.value)}
          value={vendorCampaignName}
        />
        <FormFeedback>Vendor campaign names may only contain letters, numbers, spaces, dashes, and underscores.</FormFeedback>
        <FormText>
          This is a descriptive name for the leads being generated by your vendor.
          Eg: <pre>Shared West Coast</pre>
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label for="defaultLeadPrice">Default Lead Price</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend">$</InputGroupAddon>
          <MoneyInput
            id="defaultLeadPrice"
            invalid={defaultLeadPrice < 0}
            setValue={setDefaultLeadPrice}
            value={defaultLeadPrice}
          />
          <FormFeedback>The default lead price must be positive.</FormFeedback>
        </InputGroup>
        <FormText>If no price is sent with a lead sent through this campaign, this is the price that will be given to it.</FormText>
      </FormGroup>
      {
        selectedVendor !== null &&
        (selectedVendor.flags || {}).sendsPricesWithLeads && (
          <p className="text-muted">
            <i className="fas fa-info-circle text-warning" style={{ marginRight: '5px' }} />
            {selectedVendor.vendorName} is known to send prices with leads. In this case, the default lead price will be overwritten.
          </p>
        )
      }
    </>
  );
};

StepVendor.propTypes = {
  defaultLeadPrice: PropTypes.number.isRequired,
  isNew: PropTypes.bool.isRequired,
  openSubmenu: PropTypes.string,
  selectedVendor: PropTypes.shape({
    vendorID: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
  }),
  setDefaultLeadPrice: PropTypes.func.isRequired,
  setOpenSubmenu: PropTypes.func.isRequired,
  setSelectedVendor: PropTypes.func.isRequired,
  setVendorCampaignName: PropTypes.func.isRequired,
  //vendorCampaignID: PropTypes.string.isRequired,
  vendorCampaignName: PropTypes.string.isRequired,
};

export default observer(StepVendor);