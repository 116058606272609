import React from 'react';
import PropTypes from 'prop-types';
import {
    FaSync,
    FaCheckCircle,
  } from 'react-icons/fa';

const LeadSummaries = ({ loading, total, totals = [], aggregations = [] }) => {

  return (
    <div className="font-weight-light text-white">
    <div className="d-flex justify-content-between w-auto" >
    
    <p className="ml-4">
    <span className="mr-1">{`Records Found: ${total}`}</span>
      {
        totals.map(agg => {
          const { label, attribute, formatter } = agg
          if(aggregations){
            const { [attribute]: key }  = aggregations
            if(key){
              return <span className="mr-1">{`${label}: ${formatter(key.value)}`}</span>
            }
          }
          return null
        })
      }
      </p>
    
      <div className="d-flex align-items-baseline">
      {loading ? (
        <p className="mr-4"> <FaSync
        className="ml-1 text-warning loading-sync"
        title="Searching Transfers..."
        size={20}
      /> Searching Leads... </p>
      ) : (
        <p className="mr-4"><FaCheckCircle  title="Search Completed" className="ml-1 text-success" size={20}/> Search Completed</p>
      )}
      </div>
      </div>
      </div>

  );
};

LeadSummaries.propTypes = {
    loading:PropTypes.bool,
    total:PropTypes.number,
    totals: PropTypes.array,
    aggregations: PropTypes.array
};

export default LeadSummaries;