import PropTypes from 'prop-types';
import React from 'react';
import {
  Form,
} from 'reactstrap';

import StepTabs from '../../../../../shared/components/modal/StepTabs';

import StepClean from '../steps/StepClean';
import StepDelivery from '../steps/StepDelivery';
import StepQuality from '../steps/StepQuality';
import StepReject from '../steps/StepReject';
import StepVendor from '../steps/StepVendor';

import {
  STEP_CLEAN_NAME,
  STEP_DELIVERY_NAME,
  STEP_QUALITY_NAME,
  STEP_REJECT_NAME,
  STEP_VENDOR_NAME,
} from '../../../constants';

const ScreenDefault = ({
  currentStep,
  id,
  isNew,
  openSubmenu,
  setOpenSubmenu,
  setStepNumber,
  setStepStateField,
  stepNumber,
  steps,
  stepState,
}) => {


  console.log(stepState)

  return (
    <>
      <StepTabs
        activeStepNumber={stepNumber}
        setStepNumber={setStepNumber}
        steps={steps}
      />
      <br />
      <br />
      <Form>
        {currentStep.title === STEP_VENDOR_NAME && (
          <StepVendor
            defaultLeadPrice={stepState[STEP_VENDOR_NAME].defaultLeadPrice}
            isNew={isNew}
            openSubmenu={openSubmenu}
            selectedVendor={stepState[STEP_VENDOR_NAME].selectedVendor}
            
            setDefaultLeadPrice={dlp => setStepStateField(STEP_VENDOR_NAME, 'defaultLeadPrice', dlp)}
            setOpenSubmenu={setOpenSubmenu}
            setSelectedVendor={vendor => setStepStateField(STEP_VENDOR_NAME, 'selectedVendor', vendor)}
            setVendorCampaignName={vcn => setStepStateField(STEP_VENDOR_NAME, 'vendorCampaignName', vcn)}
            // vendorCampaignID={id}
            vendorCampaignName={stepState[STEP_VENDOR_NAME].vendorCampaignName || ''}
          />
        )}
        {currentStep.title === STEP_QUALITY_NAME && (
          <StepQuality
            leadQualityTier={stepState[STEP_QUALITY_NAME].leadQualityTier}
            setLeadQualityTier={lqt => setStepStateField(STEP_QUALITY_NAME, 'leadQualityTier', lqt)}
          />
        )}
        {currentStep.title === STEP_CLEAN_NAME && (
          <StepClean
            checkForDuplicates={stepState[STEP_CLEAN_NAME].checkForDuplicates}
            dupeSubmissionMode={stepState[STEP_CLEAN_NAME].dupeSubmissionMode}
            phoneDupeDays={stepState[STEP_CLEAN_NAME].phoneDupeDays}
            phoneDupeMode={stepState[STEP_CLEAN_NAME].phoneDupeMode}
            setDupeSubmissionMode={mode => setStepStateField(STEP_CLEAN_NAME, 'dupeSubmissionMode', mode)}
            setPhoneDupeDays={days => setStepStateField(STEP_CLEAN_NAME, 'phoneDupeDays', days)}
            setPhoneDupeMode={mode => setStepStateField(STEP_CLEAN_NAME, 'phoneDupeMode', mode)}
            toggleCheckForDuplicates={() => setStepStateField(STEP_CLEAN_NAME, 'checkForDuplicates', !stepState[STEP_CLEAN_NAME].checkForDuplicates)}
          />
        )}
        {currentStep.title === STEP_REJECT_NAME && (
          <StepReject
            acceptsTermsAndConditions={stepState[STEP_REJECT_NAME].acceptsTermsAndConditions}
            rejectDisconnected={stepState[STEP_REJECT_NAME].rejectDisconnected}
            rejectDuplicates={stepState[STEP_REJECT_NAME].rejectDuplicates}
            toggleAcceptsTermsAndConditions={() => setStepStateField(STEP_REJECT_NAME, 'acceptsTermsAndConditions', !stepState[STEP_REJECT_NAME].acceptsTermsAndConditions)}
            toggleRejectDisconnected={() => setStepStateField(STEP_REJECT_NAME, 'rejectDisconnected', !stepState[STEP_REJECT_NAME].rejectDisconnected)}
            toggleRejectDuplicates={() => setStepStateField(STEP_REJECT_NAME, 'rejectDuplicates', !stepState[STEP_REJECT_NAME].rejectDuplicates)}
            vendorAllowsDisconnectedRejections={(stepState[STEP_VENDOR_NAME].selectedVendor || {}).allowDisconnectedRejects || ''}
            vendorAllowsDuplicateRejections={(stepState[STEP_VENDOR_NAME].selectedVendor || {}).allowDuplicateRejects || ''}
            vendorName={(stepState[STEP_VENDOR_NAME].selectedVendor || {}).vendorName || ''}
          />
        )}
        {currentStep.title === STEP_DELIVERY_NAME && (
          <StepDelivery
            destinationIDs={stepState[STEP_DELIVERY_NAME].destinationIDs}
            myAgentDestinationIDs={stepState[STEP_DELIVERY_NAME].myAgentDestinationIDs}
            setDestinationIDs={(destinationIDs, myAgentDestinationIDs) => setStepStateField(STEP_DELIVERY_NAME, 'destinationIDs', destinationIDs, 'myAgentDestinationIDs', myAgentDestinationIDs)}
          />
        )}
      </Form>
    </>
  );
};

ScreenDefault.propTypes = {
  currentStep: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  openSubmenu: PropTypes.string,
  setOpenSubmenu: PropTypes.func.isRequired,
  setStepNumber: PropTypes.func.isRequired,
  setStepStateField: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    complete: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
  })).isRequired,
  stepState: PropTypes.object.isRequired,
};

export default ScreenDefault;