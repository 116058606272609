import {
  action,
  decorate,
  observable,
} from 'mobx';

import API from '../shared/api';

class NetworkStore {
  network = null;

  synced = false;

  constructor(notificationStore) {
    this.notificationStore = notificationStore;
  }

  async load(networkID) {
    this.synced = false;

    try {
      const {
        data: network,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/network/get',
        {
          body: {
            id: networkID,
          },
        },
      );
  
      if (success) {
        this.network = network;
        this.synced = true;
      } else {
        this.notificationStore.create('danger', message);
      }
    } catch (error) {
      console.error(error);

      this.notificationStore.create('danger', 'Internal server error.');
    }
  }
}

decorate(NetworkStore, {
  network: observable,
  load: action,
});

export default NetworkStore;