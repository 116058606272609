import {
  action,
  decorate,
  observable,
} from 'mobx';

import API from '../shared/api';

class ActivityStore {
  hasLoadingErrors = null

  list = []

  loading = true

  sync = async () => {
    await this.load();

    this.loading = false;
  }

  init = async () => {
    this.loading = true;
    await this.load();
    this.loading = false;
  }

  load = async () => {
    try {
      const {
        data,
        message,
        success,
      } = await API.post(
        process.env.REACT_APP_API,
        '/activities',
        { body: {} },
      );

      if (!success) {
        throw new Error(message);
      }
      this.hasLoadingErrors = false;
      this.list = data;
    } catch (error) {
      console.error(error);
      this.hasLoadingErrors = true;
    }
  }
}

decorate(ActivityStore, {
  hasLoadingErrors: observable,
  list: observable,
  loading: observable,
  sync: action,
  init: action,
});

export default ActivityStore;