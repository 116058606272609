
import { observer } from 'mobx-react-lite';
import React from 'react';
import {   Table, } from 'reactstrap';

import PropTypes from 'prop-types';

const Agents = ({agents}) => {

  const roleText = (role) => {
    switch (role) {
      case 'user':
        return 'User';
      case 'manager':
        return 'Manager';
      case 'admin':
       return 'Administrator';
      case 'super_admin':
        return 'Super Admin'
      default:
        return 'User'
    }

  }


  return (
      <div>
    <h5 style={{}}>Agents</h5>
    <Table
      className="text-center"
      hover
      striped
    >
      <thead>
        <tr>
          <th style={{ width: '160px' }}>First</th>
          <th style={{ width: '160px' }}>Last</th>
          <th style={{ width: '160px' }}>Email</th>
          <th style={{ width: '160px' }}>Role</th>
        </tr>
      </thead>
      <tbody>
        {agents.map(a => (
            <tr>
            <td title={a.firstName}>{a.firstName}</td>
            <td title={a.lastName}>{a.lastName}</td>
            <td title={a.email}>{a.email}</td>
            <td title={roleText(a.role)}>{roleText(a.role)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
  );
}



Agents.propTypes = {
    agents: PropTypes.object.isRequired
  };

export default observer(Agents);
  