import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import App from "./App";
import CacheBuster from "./shared/helpers/CacheBuster";

const render = () => {
  ReactDOM.render(<CacheBuster><App /></CacheBuster>, document.querySelector("#root"));
};

registerServiceWorker();

// Render once
render();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./App", () => {
    render();
  });
}
