import React from 'react';
import List from './List';

const Transfers = () => (
  <div style={{ marginTop: 20 }}>
    <List />
  </div>
);

export default Transfers;
