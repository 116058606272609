import clone from 'lodash.clone';
import { decorate, action, computed, observable, toJS } from 'mobx';
import API from '../shared/api';


const loadAgents = async () => {
    try {
      const agents = await API.post(
        process.env.REACT_APP_API,
        `/campaign/money/agents`,
        { body: {} }
      );
      return agents;
    } catch (error) {
      return [];
    }
  };

  const saveAgents = async ({ agents }) => {
    const response = await API.post(
      process.env.REACT_APP_API,
      `/campaign/money/agents/credits/updates`,
      { body: agents }
    );
    const nextAgentTransfers = {};
  
    return {
      agents: response
        .map(ra => {
          const previous = agents.find(a => a.agentID === ra.agentID);
  
          if (previous === undefined) {
            // We received an agent back that we did not ask for
            return undefined;
          }
  
          if (ra.transferSuccess === false) {
            nextAgentTransfers[ra.agentID] = previous.transfer;
  
            return Object.assign({}, previous, {
              failedLastTransfer: true,
            });
          }
          nextAgentTransfers[ra.agentID] = 0;
  
          return Object.assign({}, previous, {
            contributedCredit: previous.contributedCredit + previous.transfer,
            failedLastTransfer: false,
            totalCredit: previous.totalCredit + previous.transfer,
          });
        })
        .filter(a => a !== undefined),
      agentTransfers: nextAgentTransfers,
    };
  };

class AgentStore {
    constructor(notificationStore) {
        this.notificationStore = notificationStore;
    }

    agents = [];

    agentTransfers = {};

    initialAgents = [];

    loading = true;

    isTransferringFunds = false;

    searchTerm = '';

    filterStatus = null;

    statusTransfer = null;

  init = async () => {

    const loadedAgents = await loadAgents();
    loadedAgents.pop();
    this.agents = loadedAgents;
    this.initialAgents = clone(loadedAgents);
    this.loading = false;
  };

  schedule = agents => {
    this.agents = agents;
  };

  reset = () => {
    this.agents = clone(this.initialAgents);
    this.agentTransfers = {};
  };

  updateTransfer = (id, amount) => {
    this.agentTransfers[id] = amount;
  };

  setFilterStatus = status => {
    this.filterStatus = status;
  }

  setStatusTransfer = status => {
    this.statusTransfer = status;
  }

  setSearchTerm = term => {
    this.searchTerm = term;
  }

  get totalTransferAmount() {
    const transfers = toJS(this.agentTransfers);

    return Object.keys(transfers)
      .map(key => transfers[key])
      .reduce((total, transfer) => total + transfer, 0);
  }

  transferredFunds = (agents, agentTransfers) => {
    this.agents = agents;
    this.agentTransfers = agentTransfers;
    this.initialAgents = clone(agents);
    this.isTransferringFunds = false;
  }

  transferFunds = async () => {
      this.isTransferringFunds = true;


      let nextAgents;
      let nextAgentTransfers;
      try {
        const response = await saveAgents({
          agents: this.agents.map(a =>
            Object.assign({}, a, {
              transfer: this.agentTransfers[a.agentID] || 0,
            })
          ),
        });
        nextAgents = response.agents;
        nextAgentTransfers = response.agentTransfers;

        if (
          nextAgents.filter(a => a.failedLastTransfer === true).length === 0
        ) {
          this.notificationStore.create(
            'success',
            'Agent settings have been saved.'
          );
        } else {
            this.notificationStore.create(
            'danger',
            'One or more transfers were unable to be processed at this time. Please try again later.'
          );
        }
      } catch {
        nextAgents = this.agents;
        nextAgentTransfers = this.agentTransfers;

        this.notificationStore.create(
          'danger',
          'Failed to save settings due to an internal server error. Please try again later.'
        );
      }

      this.transferredFunds(nextAgents, nextAgentTransfers)
    };
}

decorate(AgentStore,{
    init:action,
    setStatusTransfer: action,
    setSearchTerm: action,
    setFilterStatus:action,
    reset: action,
    schedule: action,
    transferFunds: action,
    totalTransferAmount: computed,
    updateTransfer: action,
    agents: observable,
    initialAgents: observable,
    agentTransfers: observable,
    loading: observable,
    isTransferringFunds: observable,
    searchTerm: observable,
    filterStatus:observable,
    statusTransfer: observable
})

export default AgentStore;