import React from 'react';
import PropTypes from 'prop-types';
import { usePagination } from '@availity/pagination';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const CursorControls = ({ totalItems, itemsPerPage, ...props }) => {
  const { currentPage, setPage } = usePagination();
  
  return (
    <Pagination {...props}>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          type="button"
          onClick={() => (currentPage === 1 ? null : setPage(currentPage - 1))}
        >
          Previous
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={currentPage > Math.max(1, Math.floor(totalItems / itemsPerPage))}>
        <PaginationLink type="button" onClick={() => setPage(currentPage + 1)}>
          Next
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

CursorControls.propTypes = {
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
};

export default CursorControls;
