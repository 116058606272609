import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { action, computed, decorate, observable } from 'mobx';

import API from '../shared/api';
import { open as openSocket } from '../socket';

import {
  AUTO_FUND_MINIMUM_AMOUNT,
  AUTO_FUND_MINIMUM_THRESHOLD,
} from '../config';

const ASSUMED_USER_ID = 'assumedUserID';
const AUTHENTICATED_USER_ID = 'authenticatedUserID';

class UserStore {
  authenticatedUser = null;

  loading = false;

  user = {};

  userLoaded = false;

  get approved() {
    return [true, 'true'].includes(this.user.agentApproved);
  }

  get autoFundSettings() {
    if (this.user === undefined || this.user.autoFundSettings === undefined) {
      return {
        amount: AUTO_FUND_MINIMUM_AMOUNT,
        enabled: false,
        threshold: AUTO_FUND_MINIMUM_THRESHOLD,
      };
    }
    return this.user.autoFundSettings;
  }

  get firstName() {
    return this.user.firstName || '';
  }

  get legacySync() {
    return this.user.legacySync || false;
  }

  get email() {
    return this.user.email || '';
  }

  get lastName() {
    return this.user.lastName || '';
  }

  get marketplace() {
    return this.user.marketplace || 'usha';
  }

  get network() {
    return this.user.network || {};
  }

  get phone() {
    return this.user.phone || '';
  }

  get role() {
    return this.user.role || 'user';
  }

  get userID() {
    return this.user.userID || '';
  }

  get subID() {
    return this.user.subID || '';
  }

  get networkID() {
    return this.user.networkID || '';
  }

  get companyID() {
    return this.user.companyID || '';
  }

  get corpID() {
    return this.user.corpID || '';
  }

  get flags() {
    return this.user.flags || {};
  }

  setOwnRole = async () => {
    if (this.userLoaded) {
      return;
    }
    this.loading = true;

    const {
      attributes: {
        'custom:buyer': buyerID,
        'custom:company': companyID,
        'custom:corp': corpID,
        'custom:marketplace': marketplace,
        'custom:merchant': customerID,
        'custom:network': networkID,
        email,
        family_name: lastName,
        given_name: firstName,
        phone_number: phone,
        sub: subID,
      },
      id: userID,
    } = await Auth.currentUserInfo();

    sessionStorage.setItem(AUTHENTICATED_USER_ID, userID);

    if (FS) {
      FS.identify(userID, {
        displayName: `${firstName} ${lastName}`,
        email,
      });
    }

    const assumedUserID = sessionStorage.getItem(ASSUMED_USER_ID);

    openSocket(userID);

    const { success } = await this.updateAgent({
      agentID: userID,
      buyerID,
      companyID,
      corpID,
      customerID,
      email,
      firstName,
      lastLogin: dayjs().format(),
      lastName,
      marketplace: marketplace || 'usha',
      networkID,
      phone,
      subID,
      userID,
    });

    if (success) {
      if (assumedUserID) {
        await this.assumeRole(assumedUserID);
      }
      this.userLoaded = true;
    }
    this.loading = false;

    await this.loadNetworkAgents(marketplace);
  };

  signOut = async () => {
    this.loading = true;
    this.networkAgentsLoaded = false;
    this.userLoaded = false;

    sessionStorage.removeItem(ASSUMED_USER_ID);
    sessionStorage.removeItem(AUTHENTICATED_USER_ID);

    await Auth.signOut({ global: true });

    this.loading = false;
  };

  syncAgent = async payload => {
    this.user = { ...this.user, ...payload };
  };

  updateAgent = async body => {
    const assumedUserID = sessionStorage.getItem(ASSUMED_USER_ID);
    const { agentID } = body;

    const { data, success } = await API.post(
      process.env.REACT_APP_API,
      '/campaign/user',
      { body }
    );

    if (success) {
      if (!assumedUserID || assumedUserID === agentID) {
        this.authenticatedUser = data;
      }
      this.user = data;
    }
    return {
      data,
      success,
    };
  };

  setLegacySync() {
    this.user.legacySync = true;
  }

  updateUser = async body => {
    this.loading = true;
    console.log('updateUser');
    const { data, success } = await API.post(
      process.env.REACT_APP_API,
      '/campaign/admin/user',
      { body }
    );

    this.loading = false;

    return {
      data,
      success,
    };
  };

  assumeOtherRole = async agentID => {
    await this.assumeRole(agentID);
    this.loading = false;
  };

  assumeRole = async agentID => {
    if (agentID !== this.user.agentID) {
      this.loading = true;

      if (agentID === this.authenticatedUser.agentID) {
        if (this.user.agentID) this.user = this.authenticatedUser;
        sessionStorage.removeItem(ASSUMED_USER_ID);
      } else {
        const user = await this.loadUser(agentID);

        this.user = user;
        // openSocket(agentID); // TODO: Implement sockets for assumed roles
        sessionStorage.setItem(ASSUMED_USER_ID, agentID);
      }
    }
  };

  returnToOwnRole = async () => {
    await this.assumeRole(sessionStorage.getItem(AUTHENTICATED_USER_ID));

    this.loading = false;
  };

  get hasAssumedRole() {
    const assumedUserID = sessionStorage.getItem(ASSUMED_USER_ID);

    return (
      assumedUserID !== null &&
      sessionStorage.getItem(ASSUMED_USER_ID) !==
        sessionStorage.getItem(AUTHENTICATED_USER_ID)
    );
  }

  loadNetworkAgents = async marketplace => {
    const body = {
      marketplace: marketplace || 'usha',
      size: 3000,
      count: 0,
      filters: [],
      sortOrder: 'asc',
      sortKey: 'firstName',
      fields: ['agentID', 'role', 'firstName', 'lastName', 'email'],
    };

    const { data, message, success } = await API.post(
      process.env.REACT_APP_API,
      '/network/agents',
      { body }
    );

    if (!success) {
      console.log(message);
      return Promise.resolve();
    }

    const agents = data.data.hits.hits.map(item => item._source);

    this.networkAgents = agents;

    return Promise.resolve();
  };

  loadUser = async agentID => {
    const { data: user, message, success } = await API.post(
      process.env.REACT_APP_API,
      '/user/load',
      { body: { agentID } }
    );

    if (!success) {
      if (message === 'Agent not found') {
        return false;
      }
      throw new Error('Invalid response');
    }
    return user;
  };
}

decorate(UserStore, {
  assumeRole: action,
  authenticatedUser: observable,
  networkAgents: observable,
  firstName: computed,
  hasAssumedRole: computed,
  lastName: computed,
  loading: observable,
  loadUser: action,
  loadNetworkAgents: action,
  network: computed,
  returnToOwnRole: action,
  role: computed,
  updateAgent: action,
  setLegacySync: action,
  updateUser: action,
  syncAgent: action,
  user: observable,
});

export default UserStore;
