import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';

const RefundPolicy = () => (
  <Card>
    <CardHeader>
      Refund Policy
    </CardHeader>
    <CardBody>
      <span>
        Request for refund of remaining account credit by emailing <a href="mailto:support@ushamarketplace.com">support@ushamarketplace.com</a>. Only remaining account credits are available to be refunded. Lead sales and other products purchased using account credits are not eligible for a refund.
      </span>
    </CardBody>
  </Card>
);

export default RefundPolicy;