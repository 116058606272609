import React from "react";
import PropTypes from "prop-types";
import { SignUp } from "aws-amplify-react";
import { Button, Card, CardTitle, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import LoadingButton from "@gopro16/button";
import BlockUI from "react-block-ui";
import { Auth } from "aws-amplify";
import { CenterComponent } from "../shared/helpers/helpers";

class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);

    this._validAuthStates = ["signUp", "signedOut"];
    this.signUpFields = ["given_name", "family_name", "phone_number", "email"];

    this.state = {
      loading: false
    };
  }

  showComponent() {


    const domain = window.location.href;
    let userNamePlaceHolder = 'Email'
    if(domain.includes('sonicleads')){

    }else{
      userNamePlaceHolder = 'Your USHA Company Email (lowercase only)'
    }

    return (
      <CenterComponent>
        <Card body style={{ maxWidth: 500 }}>
          <CardTitle
            tag="h4"
            className="text-center pr-3 pl-3 border-bottom border-danger text-primary pb-2"
          >
            Create Your Account
          </CardTitle>
          <AvForm
            onSubmit={() => this.setState({ loading: true })}
            onInvalidSubmit={() => this.setState({ loading: false })}
            onValidSubmit={async (event, values) => {
              try{
                if(values.companyRegion.includes('Select')){
                  this.error('Company Region Not Selected!');
                  return
                }
                const company = `usha_${values.companyRegion.toLowerCase()}`
                const data = await Auth.signUp({
                  username: values.email.toLowerCase(),
                  password: values.password,
                  attributes: {
                    email: values.email.toLowerCase(),
                    phone_number: `+1${values.phone}`,
                    given_name: values.firstName,
                    family_name: values.lastName,
                    'custom:company':company
                  }
                });
                this.changeState("signIn", data.user.username);
              }catch(error) {
                this.error(error);
              }finally {
                this.setState({ loading: false });
              }
            }}
          >
            <BlockUI blocking={this.state.loading}>
              <Row>
                <Col xs={12} md={6}>
                  <AvField
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    errorMessage="Please enter your first name"
                    required
                  />
                </Col>
                <Col xs={12} md={6}>
                  <AvField
                    name="lastName"
                    placeholder="last Name"
                    label="Last Name"
                    errorMessage="Please enter your last name"
                    required
                  />
                </Col>
              </Row>
              <AvField type="select" name="companyRegion" label="Region" helpMessage="Your current USHA Region">
              <option>Select Company Region</option>
              <option>Dorne</option>
              <option>Greif</option>
              <option>Still</option>
              <option>Yecina</option>
            </AvField>
              <AvField
                name="phone"
                type="phone"
                placeholder="Phone"
                label="Phone"
                errorMessage="Please provide a valid phone number"
                validate={{
                  tel: true,
                  required: {
                    value: true,
                    errorMessage: "Please enter a phone number"
                  }
                }}
              />
              <AvField
                name="email"
                type="email"
                placeholder={userNamePlaceHolder}
                label="Email"
                errorMessage="Please provide a valid email"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter an email"
                  }
                }}
              />
              <AvField
                name="password"
                type="password"
                placeholder="Password"
                label="Password"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter a Password"
                  },
                  minLength: {
                    value: 6,
                    errorMessage: "Your password must be more than 6 characters"
                  }
                }}
              />
         
             
          
            </BlockUI>
            <LoadingButton
              color="primary"
              size="lg"
              loading={this.state.loading}
              disabled={this.state.loading}
              block
            >
              Create Account
            </LoadingButton>
          </AvForm>
          <div className="d-flex flex-column pt-2">
            <span>
              Have an Account?{" "}
              <Button color="link" onClick={() => this.changeState("signIn")} >
                Sign In
              </Button>
            </span>
          </div>
          <Button
          color="link"
          onClick={() => window.open('https://sonic.helpscoutdocs.com/', "_blank") }
        >
          Help Guides
        </Button>
        </Card>
      </CenterComponent>
    );
  }
}

CustomSignUp.propTypes = {
  onValidSubmit: PropTypes.func,
  onInvalidSubmit: PropTypes.func
};

export default CustomSignUp;
