import PropTypes from 'prop-types';
import React from 'react';
import {
  CustomInput,
  FormGroup,
  FormText,
} from 'reactstrap';

import StoreContext from '../../../../../stores';

import PriceBadge from '../../../../../shared/components/PriceBadge';

const StepQuality = ({
  leadQualityTier,
  setLeadQualityTier,
}) => {
  const {
    networkStore,
  } = React.useContext(StoreContext);

  return (
    <>
      <h5>Improve the quality of your leads</h5>
      <FormGroup>
        <CustomInput
          checked={leadQualityTier === 'no_improvement'}
          id="noImprovement"
          label="No quality improvement"
          onChange={() => setLeadQualityTier('no_improvement')}
          type="radio"
        />
        <CustomInput
          checked={leadQualityTier === 'check_phone'}
          id="checkPhoneNumber"
          label={
            <>
              Check phone number <PriceBadge
                price={parseInt((networkStore.network || {}).blockDisconnected || '', 10)}
                style={{ marginLeft: '5px' }}
              />
              <FormText>Connection status, landline or mobile, carrier type, and more </FormText>{/* TODO: Add read more */}
            </>
          }
          onChange={() => setLeadQualityTier('check_phone')}
          type="radio"
        />
        <CustomInput
          checked={leadQualityTier === 'enhance_profile_check_phone'}
          id="enhanceProfileAndCheckPhoneNumber"
          label={
            <>
              Enhance profile + Check phone number<PriceBadge
                price={parseInt((networkStore.network || {}).enhanceProfile || '', 10) + parseInt((networkStore.network || {}).blockDisconnected || '', 10)}
                style={{ marginLeft: '5px' }}
              />
              <FormText>Enhance the lead profile using 3rd party data services (includes &quot;Check Phone Number&quot; services)</FormText>{/* TODO: Add read more */}
            </>
          }
          onChange={() => setLeadQualityTier('enhance_profile_check_phone')}
          type="radio"
        />
      </FormGroup>
    </>
  );
};

StepQuality.propTypes = {
  leadQualityTier: PropTypes.oneOf(['check_phone', 'enhance_profile_check_phone', 'no_improvement']).isRequired,
  setLeadQualityTier: PropTypes.func.isRequired,
};

export default StepQuality;