import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyBlock = ({
  copyText,
  displayText,
  onCopy,
  ...otherProps,
}) => {
  const style = Object.assign({
    backgroundColor: '#999',
    borderRadius: '3px',
    color: '#111111',
    cursor: 'pointer',
    display: 'inline',
    fontFamily: 'Monaco, monospace',
    fontSize: '90%',
    margin: '0 2px',
    padding: '0 4px',
  }, otherProps.style || {});

  delete otherProps.style;

  if (displayText === null) {
    displayText = copyText;
  }
  return (
    <CopyToClipboard
      onCopy={onCopy}
      text={copyText}
    >
      <span style={style}>
        {displayText}
        <i className="far fa-copy" style={{ marginLeft: '3px' }} />
      </span>
    </CopyToClipboard>
  );
};

CopyBlock.defaultProps = {
  displayText: null,
  onCopy: () => {},
};
CopyBlock.propTypes = {
  copyText: PropTypes.string.isRequired,
  displayText: PropTypes.string,
  onCopy: PropTypes.func,
};

export default CopyBlock;