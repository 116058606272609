import deepEqual from 'deep-equal';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Table,
} from 'reactstrap';
import { useDrop } from 'react-dnd';

import { formatCurrencyForDisplay } from '../../../MyAgents/Utils';
import { LoadingComponent } from '../../../../shared/helpers/helpers';
import StoreContext from '../../../../stores';

import EditTierModal from '../../modals/PriceTierModal';
import TierRow from './TierRow';
import VendorCampaignBox from './VendorCampaignBox';

const COL_1_WIDTH = '58px';
const COL_2_WIDTH = '131px';
const COL_3_WIDTH = '131px';
const COL_5_WIDTH = '200px';

const PriceTiersTab = ({
  openSubmenu,
  setOpenSubmenu: setRawOpenSubmenu,
}) => {
  const {
    priceTierStore,
    notificationStore,
    userStore: {
      marketplace,
    },
    vendorCampaignStore,
  } = React.useContext(StoreContext);

  const [editTierName, setRawEditTierName] = React.useState('');
  const [editTierPrice, setEditTierPrice] = React.useState(0);
  const [editTierFallbackMinutes, setEditTierFallbackMinutes] = React.useState(10);
  const [editTierFallbackIncrement, setEditTierFallbackIncrement] = React.useState('minutes');
  const [editTierFallbackTierName, setEditTierFallbackTierName] = React.useState('Overflow');
  const [editTierDescription, setEditTierDescription] = React.useState('');
  const [mutableVendorCampaigns, setMutableVendorCampaigns] = React.useState([]);

  const { campaignsList = [] } = vendorCampaignStore

 

  const normalizedVendorCampaigns = campaignsList.map(
    baseVendorCampaign => Object.assign({}, baseVendorCampaign, mutableVendorCampaigns.find(
      mutableVendorCampaign => mutableVendorCampaign.id === baseVendorCampaign.id,
    ) || {}),
  );

  console.log(normalizedVendorCampaigns)

  const vendorCampaignsHaveChanges = !deepEqual(vendorCampaignStore.campaignsList, normalizedVendorCampaigns);

  const assignVendorCampaignToTier = (vendorCampaignName, tierName) => {
    const nextMutableVendorCampaigns = JSON.parse(JSON.stringify(normalizedVendorCampaigns));
    const indexOfExistingVendorCampaign = nextMutableVendorCampaigns.findIndex(
      mutableVendorCampaign => mutableVendorCampaign.campaignName === vendorCampaignName, 
    );

    if (indexOfExistingVendorCampaign > -1) {
      if (tierName === 'Overflow') {
        nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames = ['Overflow'];
        setMutableVendorCampaigns(nextMutableVendorCampaigns);
      } else if (!nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames.includes(tierName)) {
        if (nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames.includes('Overflow')) {
          nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames = [tierName];
        } else {
          nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames.push(tierName);
        }
        setMutableVendorCampaigns(nextMutableVendorCampaigns);
      }
    }
  };

  const removeVendorCampaignFromTier = (vendorCampaignName, tierName) => {
    const nextMutableVendorCampaigns = JSON.parse(JSON.stringify(normalizedVendorCampaigns));
    const indexOfExistingVendorCampaign = nextMutableVendorCampaigns.findIndex(
      mutableVendorCampaign => mutableVendorCampaign.campaignName === vendorCampaignName, 
    );

    if (indexOfExistingVendorCampaign > -1) {
      const indexOfExistingTierWithinCampaign = nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames.findIndex(
        existingTierName => existingTierName === tierName,
      );

      if (
        tierName !== 'Overflow' &&
        indexOfExistingTierWithinCampaign > -1
      ) {
        if (nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames.length === 1) {
          nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames = ['Overflow'];
        } else {
          nextMutableVendorCampaigns[indexOfExistingVendorCampaign].tierNames.splice(indexOfExistingTierWithinCampaign, 1);
        }
        setMutableVendorCampaigns(nextMutableVendorCampaigns);
      }
    }
  };

  const setOpenSubmenu = submenu => {
    if (openSubmenu.startsWith('editTierModal')) {
      setRawEditTierName('');
      setEditTierPrice(0);
      setEditTierFallbackIncrement('minutes');
      setEditTierFallbackTierName('Overflow');
      setEditTierFallbackMinutes(10);
      setEditTierDescription('');
    }

    if (submenu === openSubmenu) {
      setRawOpenSubmenu('');
    } else if (submenu.startsWith('editTierModal')) {
      const currentTier = priceTierStore.list.find(
        priceTier => priceTier.id === submenu.split(':').slice(1).join(':'),
      );

      if (currentTier) {
        setRawOpenSubmenu(submenu);
        setRawEditTierName(currentTier.name);
        setEditTierPrice(currentTier.price);
        setEditTierFallbackIncrement(currentTier.fallbackIncrement || 'minutes');
        setEditTierFallbackTierName(currentTier.fallbackTierName);
        setEditTierFallbackMinutes(currentTier.fallbackMinutes);
        setEditTierDescription(currentTier.description || '');
      }
    } else {
      setRawOpenSubmenu(submenu);
    }
  };

  const createOrUpdateTier = async body => {
    body.marketplace = marketplace;
    await priceTierStore.save(body);
    notificationStore.create('success', `Tier successfully ${body.id ? 'upda' : 'crea'}ted!`);
    setOpenSubmenu(openSubmenu);
  };

  const discardVendorCampaignChanges = () => {
    setMutableVendorCampaigns([]);
  };

  const openEditTierModal = tierID => {
    setOpenSubmenu(`editTierModal:${tierID}`);
  };

  const saveVendorCampaignChanges = async () => {
    await vendorCampaignStore.save(normalizedVendorCampaigns, marketplace);
    setMutableVendorCampaigns([]);
    notificationStore.create('success', 'Vendor campaign assignments successfully saved!');
  };

  const [{ isOver }, drop] = useDrop({
    accept: 'VENDOR_BADGE_REMOVABLE',
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
    drop: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        removeVendorCampaignFromTier(item.vendorName, item.tierName);
      }
    },
  });

  if (
    !priceTierStore.synced ||
    !vendorCampaignStore.synced
  ) {
    return <LoadingComponent />;
  }

  const overflowTier = priceTierStore.list.find(
    priceTier => priceTier.name === 'Overflow',
  );

  return (
    <div ref={drop}>
      <div style={{
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1,
      }}>
        {vendorCampaignsHaveChanges && (
          <Alert color="warning">
            Your changes have not yet been saved.
          </Alert>
        )}
        {isOver && (
          <Alert color="info">
            Drag campaign names outside of the assigned campaign boxes to remove them.
          </Alert>
        )}
      </div>
      <EditTierModal  
        baseName={editTierName}
        basePrice={editTierPrice}
        baseFallbackMinutes={editTierFallbackMinutes}
        baseFallbackIncrement={editTierFallbackIncrement}
        baseFallbackTierName={editTierFallbackTierName}
        baseDescription={editTierDescription}
        dismiss={() => setOpenSubmenu(openSubmenu)}
        editMode={openSubmenu.startsWith('editTierModal')}
        id={openSubmenu === 'newTierModal' ? undefined : openSubmenu.split(':').slice(1).join(':')}
        isOpen={openSubmenu === 'newTierModal' || openSubmenu.startsWith('editTierModal')}
        save={createOrUpdateTier}
        tiers={priceTierStore.list}
      />
      <br />
      <h4>Price Tiers</h4>
      <br />
      <Button
        color="success"
        onClick={() => setOpenSubmenu('newTierModal')}
      >
        Create Price Tier
      </Button>
      <Table hover>
        <thead>
          <tr style={{ height: '64px' }}>
            <th style={{
              border: 'none',
              width: COL_1_WIDTH,
            }} />
            <th style={{
              border: 'none',
              width: COL_2_WIDTH,
            }} />
            <th style={{
              border: 'none',
              width: COL_3_WIDTH,
            }} />
            <th style={{ border: 'none' }}>
              <div className="float-right">
                {vendorCampaignsHaveChanges && (
                  <ButtonGroup>
                    <Button
                      color="success"
                      onClick={saveVendorCampaignChanges}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={discardVendorCampaignChanges}
                    >
                      Discard Changes
                    </Button>
                  </ButtonGroup>
                )}
              </div>
            </th>
            <th style={{
              border: 'none',
              width: COL_5_WIDTH,
            }} />
          </tr>
          <tr style={{ height: '60px' }}>
            <th style={{ borderTop: 'none' }} />
            <th style={{ borderTop: 'none' }}>Name</th>
            <th style={{ borderTop: 'none' }}>Price</th>
            <th style={{ borderTop: 'none' }}>Assigned Vendor Campaigns</th>
            <th style={{ borderTop: 'none' }}>Fallback</th>
          </tr>
        </thead>
        <tbody>
          {priceTierStore.list.filter(
            priceTier => priceTier.name !== 'Overflow',
          ).map(priceTier => (
            <TierRow
              assignVendorCampaignToTier={assignVendorCampaignToTier}
              fallbackMinutes={priceTier.fallbackMinutes}
              fallbackIncrement={priceTier.fallbackIncrement || 'minutes'}
              fallbackTierName={priceTier.fallbackTierName}
              id={priceTier.id}
              key={priceTier.name}
              name={priceTier.name}
              openEditTierModal={openEditTierModal}
              price={priceTier.price}
              removeVendorCampaignFromTier={removeVendorCampaignFromTier}
              vendorCampaigns={normalizedVendorCampaigns.filter(
                vendorCampaign => vendorCampaign.tierNames.includes(priceTier.name),
              ).map(
                vendorCampaign => ({
                  name: vendorCampaign.campaignName,
                  valid: vendorCampaign.vscIsValid,
                }),
              )}
            />
          ))}
        </tbody>
      </Table>
      <Table hover>
        <thead>
          <tr style={{ height: '0' }}>
            <th style={{
              border: 'none',
              width: COL_1_WIDTH,
            }} />
            <th style={{
              border: 'none',
              width: COL_2_WIDTH,
            }} />
            <th style={{
              border: 'none',
              width: COL_3_WIDTH,
            }} />
            <th style={{ border: 'none' }} />
          </tr>
        </thead>
        <tbody>
          <tr>
          <td style={{ border: 'none' }}>
            <Button
              onClick={() => openEditTierModal(overflowTier.id)}
              size="sm"
              style={{ border: '1px solid lightgray' }}
            >
              <i className="fa fa-edit" />
            </Button>
          </td>
            <td style={{ border: 'none' }}>
              Overflow
            </td>
            <td style={{ border: 'none' }}>
              <h4>{formatCurrencyForDisplay(overflowTier.price)}</h4>
            </td>
            <VendorCampaignBox
              assignVendorCampaignToTier={assignVendorCampaignToTier}
              removeVendorCampaignFromTier={removeVendorCampaignFromTier}
              tierName={overflowTier.name}
              vendorCampaigns={normalizedVendorCampaigns.filter(
                vendor => vendor.tierNames.includes(overflowTier.name),
              ).map(
                vendorCampaign => ({
                  name: vendorCampaign.campaignName,
                  valid: vendorCampaign.vscIsValid,
                }),
              )}
            />
          </tr>
        </tbody>
      </Table>
      <br />
      Drag vendor campaigns between tiers to assign them.
    </div>
  );
};

PriceTiersTab.propTypes = {
  openSubmenu: PropTypes.string.isRequired,
  setOpenSubmenu: PropTypes.func.isRequired,
};

export default observer(PriceTiersTab);