import React from "react";
import { Card, CardTitle } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import LoadingButton from "@gopro16/button";
import BlockUI from "react-block-ui";
import { Auth } from "aws-amplify";
import { ConfirmSignUp } from "aws-amplify-react";
import { CenterComponent } from "../shared/helpers/helpers";

export default class CustomConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);

    this._validateState = ["confirmSignUp"];

    this.state = {
      loading: false
    };
  }

  showComponent() {
    return (
      <CenterComponent>
        <Card body style={{ maxWidth: 500 }}>
          <CardTitle
            tag="h4"
            className="text-center pr-3 pl-3 border-bottom border-danger text-primary pb-2"
          >
            Confirm Your Account
          </CardTitle>
          <AvForm
            onSubmit={() => this.setState({ loading: true })}
            onInvalidSubmit={() => this.setState({ loading: false })}
            onValidSubmit={async (event, values) => {
              try {
                const res = await Auth.confirmSignUp(
                  values.username,
                  values.code
                );

                this.setState({ loading: true });
                if (res === "SUCCESS") {
                  this.changeState("signIn");
                }
              } catch (error) {
                console.log("Error", error);
              }
            }}
          >
            <BlockUI blocking={this.state.loading}>
              <AvField
                id="username"
                key="username"
                name="username"
                type="email"
                placeholder="Email"
                label="Email"
                errorMessage="Please provide a valid email"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter an email"
                  }
                }}
              />
              <AvField
                name="code"
                placeholder="Code"
                label="Code"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter your code"
                  }
                }}
              />
            </BlockUI>
            <LoadingButton
              loading={this.state.loading}
              disabled={this.state.loading}
              color="primary"
              size="lg"
              block
            >
              Confirm Account
            </LoadingButton>
          </AvForm>
        </Card>
      </CenterComponent>
    );
  }
}
