import PropTypes from 'prop-types';
import React from 'react';
import {
  CustomInput,
  FormGroup,
} from 'reactstrap';

const StepReject = ({
  acceptsTermsAndConditions,
  rejectDisconnected,
  rejectDuplicates,
  toggleAcceptsTermsAndConditions,
  toggleRejectDisconnected,
  toggleRejectDuplicates,
  vendorAllowsDisconnectedRejections,
  vendorAllowsDuplicateRejections,
  vendorName,
}) => {
  return (
    <>
      <h5>Reject leads</h5>
      <p className="text-muted">
        Once a lead has been rejected it can no longer be delivered to you. Reporting on rejected leads can be found in the marketplace.
      </p>
      <FormGroup>
        <CustomInput
          checked={rejectDisconnected}
          disabled={!vendorAllowsDisconnectedRejections}
          id="rejectDisconnected"
          label={
            <>
              <i
                className={`fas fa-${vendorAllowsDisconnectedRejections ? 'info-circle text-warning' : 'ban text-danger'}`}
                style={{ marginRight: '5px' }}
              />
              {vendorName} {vendorAllowsDisconnectedRejections ? 'may' : 'does not'} allow lead rejection based on the phone connection status.
            </>
          }
          onClick={toggleRejectDisconnected}
          type="checkbox"
        />
        <CustomInput
          checked={rejectDuplicates}
          disabled={!vendorAllowsDuplicateRejections}
          id="rejectDuplicates"
          label={
            <>
              <i
                className={`fas fa-${vendorAllowsDuplicateRejections ? 'info-circle text-warning' : 'ban text-danger'}`}
                style={{ marginRight: '5px' }}
              />
              {vendorName} {vendorAllowsDuplicateRejections ? 'may' : 'does not'} allow lead rejection based on the phone duplicate status.
            </>
          }
          onClick={toggleRejectDuplicates}
          type="checkbox"
        />
      </FormGroup>
      <FormGroup>
        <CustomInput
          checked={acceptsTermsAndConditions}
          id="termsAndConditions"
          label={
            <>
              By turning on lead rejection, you are instructing the system to send an HTTP 400 status code with an error message back to your vendor. Somne vendors refer to this as "Cancel Logic". The vendor will recieve a response with the message "duplicate" for duplicate rejects and "disconnected - no append" for disconnected rejects.
            </>
          }
          onClick={toggleAcceptsTermsAndConditions}
          type="checkbox"
        />
      </FormGroup>
    </>
  );
};

StepReject.propTypes = {
  acceptsTermsAndConditions: PropTypes.bool.isRequired,
  rejectDisconnected: PropTypes.bool.isRequired,
  rejectDuplicates: PropTypes.bool.isRequired,
  toggleAcceptsTermsAndConditions: PropTypes.func.isRequired,
  toggleRejectDisconnected: PropTypes.func.isRequired,
  toggleRejectDuplicates: PropTypes.func.isRequired,
  vendorAllowsDisconnectedRejections: PropTypes.bool.isRequired,
  vendorAllowsDuplicateRejections: PropTypes.bool.isRequired,
  vendorName: PropTypes.string.isRequired,
};

export default StepReject;