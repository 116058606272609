import { AvForm } from 'availity-reactstrap-validation';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  FormGroup,
  FormText,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from 'reactstrap';
import Switch from 'react-switch';
import { FaLock, FaSync } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';

import ChargeRow from './ChargeRow';

import { formatCurrencyForDisplay } from '../MyAgents/Utils';
import { LoadingComponent } from "../../shared/helpers/helpers";
import MoneyInput from '../../shared/components/MoneyInput';
import Payment from '../../components/Payment';
import StoreContext from '../../stores';

import {
  AUTO_FUND_MINIMUM_AMOUNT,
  AUTO_FUND_MINIMUM_THRESHOLD,
} from '../../config';

const Account = observer(() => {
  const {
    creditStore: {
      credit,
      syncCredit,
    },
    notificationStore,
    userStore: {
      marketplace,
      updateAgent,
      userID,
    },
    accountStore: {
      init,
      creditModal,
      cardModal: modal,
      loading: viewLoading,
      card,
      charges,
      errorMessage,
      addCustomerCard,
      addCustomCredit,
      resetAutoFundSettings,
      toggleCardModal: toggle,
      toggleCreditModal: toggleCredit
    },
    userStore,
  } = useContext(StoreContext);
  const [creditToAdd, setCreditToAdd] = useState(0);
  const [updating,setUpdating] = useState(false);
  const [autoFundAmount, setAutoFundAmount] = useState(userStore.autoFundSettings.amount);
  const [autoFundEnabled, setAutoFundEnabled] = useState(userStore.autoFundSettings.enabled);
  const [autoFundThreshold, setAutoFundThreshold] = useState(userStore.autoFundSettings.threshold);

  const autoFundUpdated = autoFundAmount !== userStore.autoFundSettings.amount ||
    autoFundEnabled !== userStore.autoFundSettings.enabled ||
    autoFundThreshold !== userStore.autoFundSettings.threshold;
  
  const saveAutoFundSettings = async () => {
    setUpdating(true);

    try {
      const { success } = await updateAgent({
        agentID: userID,
        autoFundSettings: {
          amount: autoFundAmount,
          enabled: autoFundEnabled,
          threshold: autoFundThreshold,
        },
      });

      if (success) {
        notificationStore.create('success', 'Auto-fund settings saved.');
      } else {
        notificationStore.create('danger', 'Failed to save auto-fund settings. Please try again later.');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdating(false);
    }
  };

 
  const addCard = async body => {
    setUpdating(true);
    await addCustomerCard(body);

    if (errorMessage) {
      notificationStore.create('danger', errorMessage);
    }
    setUpdating(false);
  };

  const addCredit = async () => {
      setUpdating(true);
      await addCustomCredit(creditToAdd);

      if (errorMessage) {
        notificationStore.create('danger', errorMessage);
      }
      setUpdating(false);
      setCreditToAdd(0);
  };

  const makeCreditForm = () => (
    <Container>
      <AvForm onValidSubmit={(event, values) => addCredit(event, values)}>
        <Label for="addCredit">Add New Credit</Label>
        <FormText>{`Your current account credit is $${((credit || 0) / 100).toFixed(
          2
        )}`}</FormText>
        <InputGroup>
          <InputGroupAddon addonType="prepend">$</InputGroupAddon>
          <MoneyInput
            name="credit"
            setValue={setCreditToAdd}
            value={creditToAdd}
          />
          <InputGroupAddon addonType="append">USD</InputGroupAddon>
        </InputGroup>
        <br/>
        <FormGroup>
          {updating ? (
            <LoadingComponent />
          ) : (
            <Button 
              color="success"
            >
              <span style={{ marginRight: '10px' }}>Add Credit</span>
              <FaLock />
            </Button>
          )}
        </FormGroup>
      </AvForm>
    </Container>
  );

  useEffect(() => {
    syncCredit();
    if(viewLoading) {
      init();
    }
  }, []);

  if (viewLoading) {
    return <LoadingComponent />;
  }
  return (
    <div>
      <div style={{ marginTop: 20 }}>
        {credit < 1 ? (
          <h2>{`Don't`} forget to add credit to your account!</h2>
        ) : null}
        <h4>{`Your current account credit is: $${((credit || 0) / 100).toFixed(
          2
        )}`}</h4>
        <h5 style={{ padding: '24px 0' }}>Current Card on File</h5>
        <div>
        {card ?
          <table>
            <thead>
              <tr>
                <td style={{ width: 64 }} />
                <td style={{ width: 64 }} />
                <td style={{ width: 64 }} />
                <td style={{ width: 500 }} />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: 12 }}>
                  <img
                    alt={card.brand}
                    src={`https://s3.amazonaws.com/files.sonicwebdev.com/images/cards/${card.brand.trim( 
                      ' ', 
                      '' 
                    ).replace(' ', '')}.png`}
                    style={{ height: 40, maxWidth: 64 }}
                  />
                </td>
                <td style={{ padding: 12 }}>
                  <span style={{ fontSize: 14 }}>last 4</span>
                  <h6 style={{ margin: 0 }}>{card.last4}</h6>
                </td>
                <td style={{ padding: 12 }}>
                  <span style={{ fontSize: 14 }}>expires</span>
                  <h6 style={{ margin: 0 }}>{card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month}/{card.exp_year}</h6>
                </td>
                <td style={{ padding: 12 }}>
                  <ButtonGroup>
                    <Button
                      color="primary" 
                      onClick={toggle} 
                    > 
                      {card ? 'Update Card' : 'Add Card'} 
                    </Button>
                    <Button
                      color="success"
                      onClick={toggleCredit}
                    >
                      Add Credit
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            </tbody>
          </table> :
          <table>
            <thead>
              <tr>
                <td style={{ width: 240 }} />
                <td style={{ width: 500 }} />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: 12 }}>
                  No card on file
                </td>
                <td style={{ padding: 12 }}>
                  <Button
                    color="primary"
                    onClick={toggle}
                  >
                    Add Card
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>}
        </div>
        {card && (
          <>
            <h5 style={{ padding: '24px 0' }}>Auto-Fund Settings</h5> 
            <table>
              <thead>
                <tr>
                  <td style={{ width: 240 }} />
                  <td style={{ width: 500 }} />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: 12 }}>
                    <Switch
                      checked={autoFundEnabled}
                      disabled={viewLoading}
                      onChange={() => setAutoFundEnabled(!autoFundEnabled)}
                    />
                  </td>
                  <td style={{ padding: 12 }}>
                    Enable automatic funding through your card on file.
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 12 }}>
                    <FormGroup style={{ margin: 0 }}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                        <MoneyInput
                          disabled={!autoFundEnabled || viewLoading}
                          minimum={AUTO_FUND_MINIMUM_AMOUNT}
                          setValue={amount => setAutoFundAmount(amount)}
                          value={autoFundAmount}
                        />
                        <InputGroupAddon addonType="append">USD</InputGroupAddon>
                      </InputGroup>
                      <FormText
                        color="danger"
                        style={{
                          height: '19px',
                          position: 'relative',
                        }}
                      >
                        <span style={{
                          position: 'absolute',
                          right: 0,
                          whiteSpace: 'nowrap',
                        }}>
                          {autoFundAmount < AUTO_FUND_MINIMUM_AMOUNT && `The minimum amount is ${formatCurrencyForDisplay(AUTO_FUND_MINIMUM_AMOUNT)}.`}
                        </span>
                      </FormText>
                    </FormGroup>
                  </td>
                  <td style={{ padding: 12 }}>
                    <i>This amount will be added to your credit when an automatic funding transaction occurs. The minimum amount is {formatCurrencyForDisplay(AUTO_FUND_MINIMUM_AMOUNT)}</i>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: 12 }}>
                    <FormGroup style={{ margin: 0 }}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                        <MoneyInput
                          disabled={!autoFundEnabled || viewLoading}
                          minimum={AUTO_FUND_MINIMUM_THRESHOLD}
                          setValue={threshold => setAutoFundThreshold(threshold)}
                          value={autoFundThreshold}
                        />
                        <InputGroupAddon addonType="append">USD</InputGroupAddon>
                      </InputGroup>
                      <FormText
                        color="danger"
                        style={{
                          height: '19px',
                          position: 'relative',
                        }}
                      >
                        <span style={{
                          position: 'absolute',
                          right: 0,
                          whiteSpace: 'nowrap',
                        }}>
                          {autoFundThreshold < AUTO_FUND_MINIMUM_THRESHOLD && `The minimum threshold is ${formatCurrencyForDisplay(AUTO_FUND_MINIMUM_THRESHOLD)}.`}
                        </span>
                      </FormText>
                    </FormGroup>
                  </td>
                  <td style={{ padding: 12 }}>
                    <i>When your credit falls below this amount, an automatic funding transaction will be triggered.</i>
                  </td>
                </tr>
                <tr>
                  {updating ? (
                    <FaSync
                      className="loading-sync"
                      size={24}
                    />
                  ) : (
                    <td style={{ padding: 12 }}>
                      {autoFundUpdated && (
                        <ButtonGroup>
                          <Button
                            disabled={viewLoading}
                            onClick={resetAutoFundSettings}
                          >
                            Reset
                          </Button>
                          {autoFundAmount >= AUTO_FUND_MINIMUM_AMOUNT && autoFundThreshold >= AUTO_FUND_MINIMUM_THRESHOLD && (
                            <Button
                              color="success"
                              disabled={viewLoading}
                              onClick={saveAutoFundSettings}
                            >
                              Save
                            </Button>
                          )}
                        </ButtonGroup>
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
      <h5 style={{ padding: '24px 0' }}>Billing History</h5>
      <Table
        className="text-right"
        hover
        striped
      >
        <thead>
          <tr>
            <th style={{ width: '16px' }} />
            <th style={{ width: '160px' }}>Status</th>
            <th style={{ width: '160px' }}>Date</th>
            <th style={{ width: '160px' }}>Time</th>
            <th>Amount</th>
            <th style={{ width: '80px' }}>Currency</th>
            <th style={{ width: '200px' }}>Purchasing CC #</th>
          </tr>
        </thead>
        <tbody>
          {charges.map(c => (
            <ChargeRow
              amount={c.amount}
              chargeStatus={c.status}
              currency="USD"
              key={c.id}
              last4={c.source.last4}
              timestamp={c.created * 1000}
            />
          ))}
        </tbody>
      </Table>
      <Modal isOpen={creditModal} toggle={toggleCredit}>
        <ModalHeader toggle={toggleCredit}>Add Credit</ModalHeader>
        <ModalBody>
          {makeCreditForm()}
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleCredit}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add New Card</ModalHeader>
        <ModalBody>
          <Payment
            addCard={addCard}
            loading={updating}
            marketplace={marketplace}
          />
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </ModalBody>
        <ModalFooter>
          <Container>
            <Row className="mb-3">
              <Col>
                <Button
                  className="float-right"
                  color="secondary"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <span className="small text-muted">This business and website are subject to, and comply with, the PCI standards. Your information is secure. You can review our privacy policy <a
                href="https://app.termly.io/document/privacy-policy/e106eeca-5e2c-414d-8ec6-48a7a8536592"
                rel="noopener noreferrer"
                target="_blank"
                >here</a>.</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="float-right small text-muted">
                  Sonic Development & Leads<br />
                  100 E Pine St<br />
                  Suite 110<br />
                  Orlando FL 32801<br />
                  United States
                </span>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    </div>
  );
});

export default Account;