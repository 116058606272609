import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonGroup,
} from 'reactstrap';

const StepTabs = ({
  activeStepNumber,
  setStepNumber,
  steps,
}) => (
  <ButtonGroup>
    {steps.map(
      (step, stepIdx) => {
        let stepColor;
        let stepIcon;

        if (!step.valid) {
          stepColor = 'danger';
          stepIcon = <i className="far fa-times-circle" />;
        } else if (step.complete) {
          stepColor = 'success';
          stepIcon = <i className="far fa-check-circle" />;
        } else {
          stepColor = 'warning';
          stepIcon = null;
        }
        return (
          <Button
            active={activeStepNumber === stepIdx + 1}
            color={stepColor}
            key={step.title}
            onClick={() => setStepNumber(stepIdx + 1)}
            outline={activeStepNumber !== stepIdx + 1}
          >
            {stepIdx + 1}: {step.title} {stepIcon}
          </Button>
        );
      },
    )}
  </ButtonGroup>
);

StepTabs.propTypes = {
  activeStepNumber: PropTypes.number.isRequired,
  setStepNumber: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    complete: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
  })).isRequired,
};

export default StepTabs;