import PropTypes from 'prop-types';
import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

const ConfirmModal = ({
    closeModal,
    confirm,
    isOpen,
    text,
}) => (
    <Modal
        isOpen={isOpen}
        toggle={closeModal}
    >
        <ModalHeader>Confirmation</ModalHeader>
        <ModalBody>{text}</ModalBody>
        <ModalFooter>
            <Button
                color="success"
                onClick={() => {
                    confirm();
                    closeModal();
                }}
            >
                Confirm
            </Button>
            <Button
                onClick={closeModal}
            >
                Cancel
            </Button>
        </ModalFooter>
    </Modal>
);
ConfirmModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
};

export default ConfirmModal;